import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, Grid, Stack } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import ValidateButton from "../ValidateButton";
import { useTheme } from '@mui/material/styles';

const Problem = () => {
  const theme = useTheme();
  const [userAnswer, setUserAnswer] = useState('');
  const [validate, setValidate] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const { exerciceId } = useParams();
  const [data, setData] = useState(false);
  const location = useLocation();
  const exerciseId = location.state?.exerciseId;
  const exerciseParam = location.state?.exercise;
  const fromCreate = location.state?.fromCreate;
  const exerciseType = "probleme";
  const schoolSubject = location.state?.schoolSubject;


  useEffect(() => {
    setData(exerciseParam)
  }, [exerciceId, exerciseParam]);

  const handleValidate = () => {
    setValidate(true);
    setIsCorrect(userAnswer === data.reponse_du_probleme);
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h4" color={theme.palette.primary.main}  gutterBottom>
        {`${exerciseParam.exerciseName || "Problème de mathématiques"}`}
      </Typography>
      <Card sx={{ overflowY: "auto", maxHeight: '90vh' }}>
        {data &&
          <CardContent>
            <Stack spacing={4}>
              <Typography variant="h5" component="div">
                {data.enonce_du_probleme}
              </Typography>
              <Stack>
                <TextField
                  label="Ta réponse"
                  variant="outlined"
                  value={userAnswer}
                  onChange={(e) => setUserAnswer(e.target.value)}
                  sx={{ marginY: 2 }}
                />
              </Stack>
              {validate && (
                <Grid container>
                  <Stack spacing={4}>
                    <Typography variant="h5">
                      La bonne réponse est: {data.reponse_du_probleme}
                    </Typography>
                  </Stack>
                </Grid>
              )}
            </Stack>
          </CardContent>
        }

      </Card>
      {validate && (
        <Card>
          <CardContent>
            <Typography variant="h5">
              Explication:
            </Typography>
            <Typography variant="h7" sx={{mt:2}} >
              {data.explication_de_la_solution}
            </Typography>
          </CardContent>
        </Card>
      )}
      {validate && (
        <Card>
          <CardContent>
          <Typography variant="h5">
              Conseils:
            </Typography>
            <Typography>
            {data.conseils_sur_le_sujet.map((conseil, index) => (
              <Grid item key={index} xs={12}>
                <Typography variant="h7">
                  - {conseil}
                </Typography>
              </Grid>
            ))}
             </Typography>
          </CardContent>
        </Card>
      )}
      <ValidateButton exerciseType={exerciseType} schoolSubject={schoolSubject} exerciseJson={exerciseParam} validate={validate} isCorrect={isCorrect} fromCreate={fromCreate} exerciseId={exerciseId} handleValidate={handleValidate} />
    </Stack>
  );
};

export default Problem;