import React, { useState, useEffect } from 'react';
import { Stack, Grid, Typography } from '@mui/material';
import { useLocation } from "react-router-dom";
import AdditionComponent from "./AdditionComponent";
import SoustractionComponent from "./SoustractionComponent";
import ValidateButton from '../ValidateButton';

const CalculPose = ({ type }) => {
    const exerciseType = "calculationSheet";
    const location = useLocation();
    const exerciseJson = location.state?.exercise;
    const operations = exerciseJson?.operations || [];
    const exerciseId = location.state?.exerciseId;
    const fromCreate = location.state?.fromCreate;
    const schoolSubject = location.state?.schoolSubject;
    const [validationResults, setValidationResults] = useState(Array(operations.length).fill(false));
    const [triggerCorrection, setTriggerCorrection] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);

    const handleValidation = (index, isValid) => {
        setValidationResults(prevResults => {
            const newResults = [...prevResults];
            newResults[index] = isValid;
            return newResults;
        });
    };

    const handleCorrection = () => {
        setTriggerCorrection(true);
    };

    useEffect(() => {
        if (validationResults) {
            const correctAnswersCount = validationResults.filter(result => result === true).length;
            setIsCorrect(operations.length === correctAnswersCount);
        }
    }, [operations, validationResults]);

    return (
        <Stack spacing={2}>
            <Typography variant="h4" textAlign={"center"}>{exerciseJson.exerciseName}</Typography>
            <Grid container spacing={2}>
                {operations.map((op, index) => (
                    <Grid item md={4} xs={12} key={index}>
                        {op.type === "addition" && (<AdditionComponent
                            numberA={op.a}
                            numberB={op.b}
                            index={index}
                            onValidated={(isValid) => handleValidation(index, isValid)}
                            triggerCorrection={triggerCorrection}
                        />)}
                         {op.type === "soustraction" && (<SoustractionComponent
                            numberA={op.a}
                            numberB={op.b}
                            index={index}
                            onValidated={(isValid) => handleValidation(index, isValid)}
                            triggerCorrection={triggerCorrection}
                        />)}
                    </Grid>
                ))}
            </Grid>
            <ValidateButton exerciseType={exerciseType} exerciseJson={exerciseJson} validate={triggerCorrection} schoolSubject={schoolSubject} isCorrect={isCorrect} fromCreate={fromCreate} handleValidate={handleCorrection} exerciseId={exerciseId} />
        </Stack>
    );
};

export default CalculPose;
