import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import SharedExerciseService from './features/sharedExercise/SharedExerciseService';
import { Box, CircularProgress, Typography } from '@mui/material';

export default function ExerciseRoute({ redirectPath = '/error' }) {
  const [loading, setLoading] = useState(true);
  const [exercise, setExercise] = useState(null);
  const { exerciseId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExercise = async () => {
      try {
        const response = await SharedExerciseService.getList({ exerciseId });
        const data = await response.json();
        if (data && data.length > 0) {
          const sharedExercise = data[0];
          setExercise(sharedExercise);
        } else {
          navigate(redirectPath);
        }
      } catch (error) {
        console.error('Failed to fetch exercise:', error);
        navigate(redirectPath);
      } finally {
        setLoading(false);
      }
    };

    fetchExercise();
  }, [exerciseId, navigate]);

  useEffect(() => {
    if (exercise) {
      const path = `/${exercise.exerciseType}`;
      navigate(path, { state: { exercise: exercise.exerciseJson, exerciseId: exercise.exerciseId } });
    }
  }, [exercise, navigate]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>Chargement...</Typography>
      </Box>
    );
  }

  if (exerciseId === null) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <></>
  );
}
