import { fetchAuthSession } from 'aws-amplify/auth';

class ApiService {
    // Méthode pour obtenir le token actuel ou rafraîchi
    static async getToken() {
      try {
        const session = await fetchAuthSession();
        return session.tokens?.idToken?.toString();
      } catch (error) {
        console.error("Erreur lors de la récupération de la session :", error);
        throw error;
      }
    }
  
    // Méthode générique pour faire des appels fetch avec authentification
    static async fetchWithAuth(apiEndpoint, options = {}) {
        const url = `https://roin2cakj2.execute-api.eu-west-3.amazonaws.com${apiEndpoint}`;
      const jwtToken = await this.getToken();
      // Configurez les headers pour inclure le token d'authentification
      const headers = {
        ...options.headers,
        Authorization: `Bearer ${jwtToken}`
      };
  
      return fetch(url, { ...options, headers });
    }
  }
  
  export default ApiService;