import { setActiveProfile } from "../authentication/userInfos";
import APIService from "../../ApiService";

const QuestService = {
  markQuestAsComplete: async function (
    questObj,
    activeProfile,
    dispatch
  ) {
    const { tipId, weeklyQuestId } = questObj;

    let newProfile = {
      ...activeProfile,
    };

    if (tipId) {
      newProfile["lastQuest"] = {
        questId: tipId,
        status: "complete",
      };
    }

    if (weeklyQuestId) {
      let weeklyQuests = Array.isArray(activeProfile.weeklyQuests)
        ? [...activeProfile.weeklyQuests]
        : [];
      let foundWeeklyIndex = weeklyQuests.findIndex(
        (weekly) => weekly.id === weeklyQuestId
      );
      const now = new Date();
      if (foundWeeklyIndex !== -1) {
        let foundWeekly = weeklyQuests[foundWeeklyIndex];
        const lastCompletionDate = new Date(foundWeekly.date);
        const differenceInMilliseconds =
          now.getTime() - lastCompletionDate.getTime();
        const differenceInDays =
          differenceInMilliseconds / (1000 * 60 * 60 * 24);
        // Vérifier si la différence est inférieure à 3 jours
        if (differenceInDays < 3) {
          return;
        }
        let updatedWeekly = {
          ...foundWeekly,
          status: "complete",
          date: now.toISOString(),
        };
        weeklyQuests[foundWeeklyIndex] = updatedWeekly;
      } else {
        weeklyQuests.push({
          id: weeklyQuestId,
          status: "complete",
          date: now.toISOString(),
        });
      }
      newProfile["weeklyQuests"] = weeklyQuests;
    }

    const response = await APIService.fetchWithAuth(`/profiles/update`,
      {
        method: "POST",
        body: JSON.stringify(newProfile),
      }
    );
    if (!response.ok) {
      console.error("Cannot edit profile");
    } else {
      dispatch(setActiveProfile(newProfile));
    }
  },

  markQuestAsDone: async function (
    questObj,
    cards,
    activeProfile,
    dispatch
  ) {
    const { tipId, weeklyQuestId } = questObj;
    let newProfile = {
      ...activeProfile,
      cards: cards,
    };

    if (tipId) {
      newProfile["lastQuest"] = {
        questId: tipId,
        status: "done",
      };
    }

    if (weeklyQuestId) {
      let weeklyQuests = Array.isArray(activeProfile.weeklyQuests)
        ? [...activeProfile.weeklyQuests]
        : [];
      let foundWeeklyIndex = weeklyQuests.findIndex(
        (weekly) => weekly.id === weeklyQuestId
      );
      let foundWeekly = weeklyQuests[foundWeeklyIndex];
      let updatedWeekly = {
        ...foundWeekly,
        status: "done",
      };
      weeklyQuests[foundWeeklyIndex] = updatedWeekly;
      newProfile["weeklyQuests"] = weeklyQuests;
    }

    const response = await APIService.fetchWithAuth(`/profiles/update`,
      {
        method: "POST",
        body: JSON.stringify(newProfile),
      }
    );
    if (!response.ok) {
      console.error("Cannot edit profile");
    } else {
      dispatch(setActiveProfile(newProfile));
    }
  },
};

export default QuestService;
