import APIService from '../../ApiService'

const LadderService = {
  getList: async function (
    seasonId
  ) {
    return await APIService.fetchWithAuth(`/ladder/${seasonId}`,
      {
        method: "GET"
      }
    );
  },
};

export default LadderService;
