import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardActions, Grid, Stack, Typography, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Box, IconButton, Avatar, Tabs, Tab } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AcceptIcon from '@mui/icons-material/Done';
import RejectIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { refreshProfile } from '../authentication/userInfos';
import { Link } from "react-router-dom";
import ApiService from '../../ApiService';
import ReactGA from 'react-ga4';

const FriendList = () => {

    useEffect(() => {
        document.title = 'Liste des amis';
      }, []);

    const theme = useTheme();
    const dispatch = useDispatch();

    const [friends, setFriends] = useState([]);
    const [open, setOpen] = useState(false);
    const [newFriend, setNewFriend] = useState('');
    const [error, setError] = useState(null);
    const [friendRequestReceived, setFriendRequestReceived] = useState([]);
    const [friendRequestSent, setFriendRequestSent] = useState([]);
    const [needRefresh, setNeedRefresh] = useState(true);
    const [tabIndex, setTabIndex] = useState(0);

    const handleDeleteFriend = (friendId) => {
        async function fetchDeleteDelete() {
            await ApiService.fetchWithAuth(`/profiles/deleteFriend/${activeProfile.profileId}/${friendId}`, {
                method: "DELETE"
            });
            setNeedRefresh(true);
            dispatch(refreshProfile());
        }
        fetchDeleteDelete();
    };

    const handleDeleteFriendRequest = (senderId, receiverId) => {
        async function fetchDeleteInvitation() {
            await ApiService.fetchWithAuth(`/profiles/friendRequestList/${senderId}/${receiverId}`, {
                method: "DELETE"
            });
            setNeedRefresh(true);
        }
        fetchDeleteInvitation();
    };

    const handleAcceptFriendRequest = (senderId) => {
        async function fetchAcceptInvitation() {
            await ApiService.fetchWithAuth(`/profiles/friendRequestList/${senderId}/${activeProfile.profileId}`, {
                method: "PUT"
            });
            setNeedRefresh(true);
            dispatch(refreshProfile());
        }
        fetchAcceptInvitation();
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const activeProfile = useSelector((state) => state.userInfos.activeProfile);
    useEffect(() => {
        if (activeProfile && needRefresh) {
            async function fetchFriends() {
                if (activeProfile.friendList && activeProfile.friendList.length) {
                    const friendsResponse = await ApiService.fetchWithAuth(`/profiles/publicInfos`, {
                        method: "POST",
                        body: JSON.stringify({
                            profileIds: activeProfile.friendList,
                        })
                    });
                    const friendList = await friendsResponse.json();
                    setFriends(friendList);
                }

                const response = await ApiService.fetchWithAuth(`/profiles/friendRequestList/${activeProfile.profileId}`);
                const pendingRequest = await response.json();
                setFriendRequestSent(pendingRequest.myRequest);
                setFriendRequestReceived(pendingRequest.friendRequest);
                setNeedRefresh(false);
            }
            fetchFriends();
        }
    }, [activeProfile, needRefresh]);

    const handleAddFriend = () => {
        async function addFriendCall() {
            try {
                const response = await ApiService.fetchWithAuth(`/profiles/addFriend`, {
                    method: "POST",
                    body: JSON.stringify({
                        myPseudo: activeProfile?.profileId,
                        friendPseudo: newFriend
                    })
                });
                if (!response.ok) {
                    if (response.status === 404) {
                        setError("Cette personne n'existe pas");
                    } else {
                        throw new Error(response);
                    }
                } else {
                    ReactGA.event("friend_add",{
                        label:window.location.pathname,
                        category: `friend`
                      });
                    setNeedRefresh(true);
                    setNewFriend('');
                    handleClose();
                    setError(null);
                }
            } catch (e) {
                console.error("Cannot add friend", e);
            }
        }
        addFriendCall()
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Stack spacing={2}>
            <Typography variant="h4" color={theme.palette.primary.main} sx={{ fontFamily: "Softers" }} textAlign={"center"}>
                {`Liste d'amis`}
            </Typography>
            <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                <Tab label={friends.length > 0 ? `Mes amis (${friends.length})` : "Mes amis"} />
                <Tab label={friendRequestSent.length > 0 ? `Mes demandes d'amis (${friendRequestSent.length})` : "Mes demandes d'amis"} />
                <Tab label={friendRequestReceived.length > 0 ? `Demandes d'amis reçues (${friendRequestReceived.length})` : "Demandes d'amis reçues"} />
            </Tabs>
            <Grid container spacing={2}>
                {tabIndex === 0 && friends.map((friend, index) => (
                    <Grid item xs={12} key={index}>
                        <Card elevation={3} sx={{ mb: 1, borderRadius: 5 }}>
                            <Stack direction={{ md: "row", xs: "column" }} sx={{ justifyContent: "space-between", mr: 2 }}>
                            <CardContent>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Avatar alt="Friend Avatar" src={`${friend?.image}`} sx={{ mr: 2 }} />
                                    <Typography variant="h6">{friend.pseudo}</Typography>
                                    <Typography variant="body2" sx={{ ml: 2 }}>{`Score: ${friend.score || 0}`}</Typography>
                                </Box>
                            </CardContent>
                            <CardActions>
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteFriend(friend)} sx={{ color: theme.palette.error.main }}>
                                    <DeleteIcon />
                                </IconButton>
                            </CardActions>
                            </Stack>
                        </Card>
                    </Grid>
                ))}
                {tabIndex === 1 && friendRequestSent.map((friend, index) => (
                    <Grid item xs={12} key={index}>
                        <Card elevation={3} sx={{ mb: 1, borderRadius: 5 }}>
                            <Stack direction={{ md: "row", xs: "column" }} sx={{ justifyContent: "space-between", mr: 2 }}>
                            <CardContent>
                                <Typography variant="h6">{friend}</Typography>
                                <Typography variant="body2" sx={{ color: "#000000" }}>Invitation envoyée</Typography>
                            </CardContent>
                            <CardActions>
                                <IconButton edge="end" aria-label="reject" onClick={() => handleDeleteFriendRequest(activeProfile.profileId, friend)} sx={{ color: theme.palette.error.main }}>
                                    <RejectIcon />
                                </IconButton>
                            </CardActions>
                            </Stack>
                        </Card>
                    </Grid>
                ))}
                {tabIndex === 2 && friendRequestReceived.map((friend, index) => (
                    <Grid item xs={12} key={index}>
                        <Card elevation={3} sx={{ mb: 1, borderRadius: 5 }}>
                            <Stack direction={{ md: "row", xs: "column" }} sx={{ justifyContent: "space-between", mr: 2 }}>
                            <CardContent>
                                <Typography variant="h6">{friend}</Typography>
                                <Typography variant="body2" sx={{ color: "#000000" }}>Invitation reçue</Typography>
                            </CardContent>
                            <CardActions>
                                <IconButton edge="end" aria-label="accept" onClick={() => handleAcceptFriendRequest(friend)} sx={{ color: theme.palette.success.main }}>
                                    <AcceptIcon />
                                </IconButton>
                                <IconButton edge="end" aria-label="reject" onClick={() => handleDeleteFriendRequest(friend, activeProfile.profileId)} sx={{ color: theme.palette.error.main }}>
                                    <RejectIcon />
                                </IconButton>
                            </CardActions>
                            </Stack>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Box display="flex" justifyContent="center" my={2}>
                {activeProfile ? (
                    <Button sx={{ marginLeft: "auto", marginRight: "auto" }} variant="contained" startIcon={<AddIcon />} onClick={handleClickOpen}>
                        Ajouter un ami
                    </Button>
                ) : (
                    <Button sx={{ marginLeft: "auto", marginRight: "auto" }} variant="contained" startIcon={<AddIcon />} component={Link} to={`/profile/list`} >
                        Créer un profil pour ajouter un ami
                    </Button>
                )}
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Ajouter un Nouvel Ami</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Pseudo"
                        fullWidth
                        variant="standard"
                        value={newFriend}
                        onChange={(e) => setNewFriend(e.target.value)}
                        error={!!error}
                        helperText={error ? error : ""}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button onClick={handleAddFriend}>Ajouter</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
};

export default FriendList;
