import React, { useState } from "react";
import { Grid, Card, CardContent, CardMedia, Typography, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText, Divider } from "@mui/material";
import AlbumData from "./AlbumData";
import ApiService from "../../ApiService";
import { setActiveProfile } from "../authentication/userInfos";
import { useDispatch } from "react-redux";

const SharedCards = ({ receivedCards, friends }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const getAlbumByCardId = (cardId) => {
    return AlbumData.find((album) => album.cards.some((card) => card.id === cardId));
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setOpenModal(true);
  };

  const handleConfirmShare = async () => {
    const response = await ApiService.fetchWithAuth(`/cards/accept`, {
      method: "POST",
      body: JSON.stringify({
        cardTransactionId: selectedCard.cardTransactionId,
      }),
    });

    setSelectedCard(null);
    setOpenModal(false);

    if (response.ok) {
      const updatedProfile = await response.json();
      dispatch(setActiveProfile(updatedProfile));
    } else {
      console.error("Erreur lors du partage de la carte");
    }
  };

  return (
    <>
      <Grid container spacing={2} justifyContent={{ xs: "center", md: "left" }}>
        {receivedCards.map((card) => {
          const album = getAlbumByCardId(card.cardId);
          if (!album) return null;

          const albumCard = album.cards.find((albumCard) => albumCard.id === card.cardId);
          const properties = album.properties;

          return (
            <Grid item onClick={() => handleCardClick(card)} key={card.cardTransactionId}>
              <Card
                sx={{
                  width: { xs: "227px", sm: "345px" },
                  height: { xs: "396px", sm: "600px" },
                  m: 2,
                  bgcolor: album.cardColor,
                  p: 2,
                  borderRadius: "16px",
                  boxShadow: "2px 01px 2px 2px rgba(0, 0, 0, .9)",
                  border: "4px solid #D6C3AE",
                  color: "#D6C3AE",
                }}
              >
                <CardContent
                  sx={{
                    bgcolor: "#D6C3AE",
                    borderRadius: "16px",
                    height: { xs: "47px", sm: "72px" },
                    color: album.cardColor,
                    mb: 1,
                    boxShadow: "2px 01px 2px 2px rgba(0, 0, 0, .9)",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      padding: "0px",
                      fontWeight: "bold",
                      position: "relative",
                    }}
                  >
                    {albumCard.name.toUpperCase()}
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      bgcolor: album.cardColor,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                      color: "#D6C3AE",
                      borderRadius: "30px",
                      width: "auto",
                      height: "auto",
                      fontSize: "16px",
                      border: "2px solid #D6C3AE",
                      padding: "5px 10px"
                    }}
                  >
                    {card.senderPseudo}
                  </Box>
                </CardContent>

                <CardMedia
                  component="img"
                  image={albumCard.imageUrl}
                  alt={albumCard.name}
                  sx={{
                    height: { xs: "185px", sm: "305px" },
                    width: { xs: "185px", sm: "305px" },
                    objectFit: "contain",
                    borderRadius: "16px",
                    border: "4px solid #D6C3AE",
                    mb: 2,
                    boxShadow: "2px 01px 2px 2px rgba(0, 0, 0, .9)",
                  }}
                />
                <CardContent
                  sx={{
                    bgcolor: "#D6C3AE",
                    borderRadius: "16px",
                    color: album.cardColor,
                    overflowY: "auto",
                    height: { xs: "100px", sm: "150px" },
                    boxShadow: "2px 01px 2px 2px rgba(0, 0, 0, .9)",
                  }}
                >
                  {properties.map((property, index) => (
                    <Box key={albumCard.name + property.key}>
                      <Box
                        sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
                      >
                        <Typography
                          variant="body1"
                          color={album.cardColor}
                          sx={{ fontWeight: "700", mr: 1 }}
                        >
                          {property.value}
                        </Typography>
                        <Typography variant="body1" color={album.cardColor}>
                          {albumCard[property.key]}
                        </Typography>
                      </Box>
                      {index < properties.length - 1 && (
                        <Divider size="small" color={album.cardColor}></Divider>
                      )}
                    </Box>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Nouvelle carte reçue"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Pensez à remercier {selectedCard ? (friends.find((friend) => friend.profileId === selectedCard.senderId)?.pseudo|| selectedCard.senderId) : ""} pour cette nouvelle carte.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmShare} color="primary" autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>


    </>
  );
};

export default SharedCards;