import clovisImg from "./img/clovis.jpg";
import charlemagneImg from "./img/charlemagne.jpg";
import louisIXImg from "./img/louisIX.jpg";
import louisXIVImg from "./img/louisXIV.jpg";
import louisXVIImg from "./img/louisXVI.jpg";
import napoleonImg from "./img/napoleon.jpg";

const KingData = [
  {
    id: "king-france-clovis",
    number: 1,
    name: "Clovis I",
    reign: "Roi des Francs",
    dynasty: "Mérovingienne",
    description: "Premier roi des Francs à unir toutes les tribus sous un seul souverain, converti au christianisme.",
    imageUrl: clovisImg,
  },
  {
    id: "king-france-charlemagne",
    number: 2,
    name: "Charlemagne",
    reign: "Empereur",
    dynasty: "Carolingienne",
    description: "Roi des Francs et Lombards, couronné Empereur en 800, il est considéré comme le père de l'Europe.",
    imageUrl: charlemagneImg,
  },
  {
    id: "king-france-louisIX",
    number: 3,
    name: "Louis IX",
    reign: "Roi",
    dynasty: "Capétienne",
    description: "Canonisé comme Saint Louis, réputé pour sa piété et sa justice, il a mené deux croisades.",
    imageUrl: louisIXImg,
  },
  {
    id: "king-france-louisXIV",
    number: 4,
    name: "Louis XIV",
    reign: "Roi",
    dynasty: "Bourbon",
    description: "Le Roi-Soleil, son règne de 72 ans est le plus long de l'histoire de France, marqué par l'absolutisme royal.",
    imageUrl: louisXIVImg,
  },
  {
    id: "king-france-louisXVI",
    number: 5,
    name: "Louis XVI",
    reign: "Roi",
    dynasty: "Bourbon",
    description: "Roi lors de la Révolution française, il a été exécuté par guillotine. Son règne a vu l'effondrement de la monarchie française.",
    imageUrl: louisXVIImg,
  },
  {
    id: "king-france-napoleon",
    number: 6,
    name: "Napoléon Bonaparte",
    reign: "Empereur",
    dynasty: "Bonaparte",
    description: "Militaire et dirigeant politique, il s'est élevé au pouvoir pendant la Révolution française et a régné sur la France, puis sur une grande partie de l'Europe.",
    imageUrl: napoleonImg,
  }
];

export default KingData;