import dinausaurAlbumImg from "./dinausaur/img/dinausaur_album.jpg"
import wonderAlbumImg from "./wonder/img/wonder_album.png"
import planetAlbumImg from "./planet/img/planet_album.png"
import kingAlbumImg from "./king/img/king_album.jpg"
import capitalAlbumImg from "./capital/img/capital_album.jpg"
import DinausaurData from "./dinausaur/DinausaurData"
import KingData from "./king/KingData"
import WonderData from "./wonder/WonderData"
import PlanetData from "./planet/PlanetData"
import CapitalData from "./capital/CapitalData"



const AlbumData = [
    {
        id: "dinausaur",
        name: "Les dinosaures",
        imageUrl: dinausaurAlbumImg, 
        cardColor:"#002730",
        route:"/album/dinausaur",
        cards: DinausaurData,
        properties: [{"value":"La période" ,"key":"period"}, {"value":"La taille" ,"key":"size"}, {"value":"La nourriture" ,"key":"diet"}]
      },
      {
        id: "planet",
        name: "Le système solaire",
        imageUrl: planetAlbumImg, 
        cardColor:"#3F48CC",
        route:"/album/planet",
        cards: PlanetData,
        properties: [{"value":"Le diamètre" ,"key":"diameter"}, {"value":"Type d'astre" ,"key":"status"}, {"value":"Type" ,"key":"type"}, {"value":"Distance du soleil" ,"key":"distanceFromSun"}, {"value":"Description" ,"key":"description"}]
      },
      {
        id: "wonder",
        name: "Les merveilles",
        imageUrl: wonderAlbumImg, 
        cardColor:"#6E6C3B",
        route:"/album/wonder",
        cards: WonderData,
        properties: [{"value":"Le pays" ,"key":"area"}, {"value":"Monde" ,"key":"status"}, {"value":"Description" ,"key":"description"}]
      },
      {
        id: "king",
        name: "Les rois de France",
        imageUrl: kingAlbumImg, 
        cardColor:"#3A5FCD",
        route:"/album/king",
        cards: KingData,
        properties: [{"value":"Le règne" ,"key":"reign"}, {"value":"La dynastie" ,"key":"dynasty"}, {"value":"Description" ,"key":"description"}]
      },
      {
        id: "capital",
        name: "Les capitales",
        imageUrl: capitalAlbumImg,
        cardColor: "#579C87",
        route: "/album/capital",
        cards: CapitalData,
        properties: [
          // Ici, définissez les propriétés pertinentes pour les capitales
          { value: "Le pays", key: "country" },
          { value: "Continent", key: "continent" },
          { value: "Population", key: "population" },
          { value: "Description", key: "description" }
        ]
      }
  ]
export default AlbumData;