import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import APIService from "../ApiService.js";
import Sprite from "./Sprite.js";
import paramsExercise from "../data/paramsExercise.js";
import { useTheme } from "@mui/material/styles";
import ReactGA from 'react-ga4';

const DialogCreatorComponent = ({ selectedTemplate, onClose }) => {
  const theme = useTheme();
  const paramsData = paramsExercise;
  const navigate = useNavigate();
  const activeProfile = useSelector(
    (state) => state?.userInfos?.activeProfile,
    shallowEqual
  );

  const [manualModes, setManualModes] = useState({});
  const [showSprite, setShowSprite] = useState(false);
  const [items, setItems] = useState([]);
  
  const [paramsState, setParamsState] = useState({});

  useEffect(() => {
    if (selectedTemplate) {
      const newItems = selectedTemplate.templateParams.map((paramId) => paramsData.find((paramData) => paramData.id === paramId));
      setItems(newItems);

      const initialParamsState = {};
      newItems.forEach((item) => {
        if(activeProfile && activeProfile.classe && item.id ==="level"){
          initialParamsState[item.id] = activeProfile.classe;
        }else if(activeProfile && activeProfile.defaultTheme && item.id ==="theme"){
          initialParamsState[item.id] = activeProfile.defaultTheme;
        }else if (item.values && item.values.length > 0) {
          initialParamsState[item.id] = item.values[0];
        } else if (item.example) {
          initialParamsState[item.id] = item.example;
        } else {
          initialParamsState[item.id] = "";
        }
      });
      setParamsState(initialParamsState);
    }

  }, [selectedTemplate, paramsData]);

  const handleChangeProperty = (paramId, value) => {
    setParamsState((prevState) => ({
      ...prevState,
      [paramId]: value,
    }));
  };

  const handleManualModeChange = (paramId, value) => {
    setManualModes((prevState) => ({
      ...prevState,
      [paramId]: value,
    }));
  };

  const onValidate = async () => {
    const callApiBuild = async () => {
      setShowSprite(true);

      let prompt = selectedTemplate.templateDescription;
      Object.keys(paramsState).forEach((id) => {
        prompt = prompt.replace(`{${id}}`, paramsState[id]);
      });
      const template = {
        prompt: prompt,
        json: selectedTemplate.templateJson,
        iaEngine: selectedTemplate.iaEngine,
      };

      const response = await APIService.fetchWithAuth("/ai/ask", {
        method: "POST",
        body: JSON.stringify(template),
      });
      setShowSprite(false);
      return await response.json();
    };

    ReactGA.event("exercise_created",{
      label:window.location.pathname,
      category: `exercise`
    });

    if (selectedTemplate.hookAiAtStart) {
      let prompt = selectedTemplate.templateDescriptionAtEnd;
      const exerciseContent = await callApiBuild();
      const path = `/${selectedTemplate.templateName}`;
      navigate(path, {
        state: {
          exercise: exerciseContent,
          template: { ...selectedTemplate, prompt },
          fromCreate: true,
        },
      });
    } else {
      let prompt = selectedTemplate.templateDescriptionAtEnd;
      if (prompt) {
        Object.keys(paramsState).forEach((id) => {
          prompt = prompt.replace(`{${id}}`, paramsState[id]);
        });
      }
      const path = `/${selectedTemplate.templateName}`;
      const exerciseContent = {}

      if(selectedTemplate.templateName === "calculationSheet"){
        if(paramsState["calculCount"]){
          exerciseContent.operations = [];
          exerciseContent.exerciseName = `Mes ${paramsState["sheetType"]}s - Les nombres inférieurs à ${paramsState["maxNumber"]}`;
  
          for(let i = 0 ; i < parseInt(paramsState["calculCount"],10); i++){
            const randomFirstNumber = Math.floor(Math.random() * paramsState["maxNumber"]);
            const randomSecondNumber = paramsState["sheetType"] === "soustraction" ? Math.floor(Math.random() * randomFirstNumber) : Math.floor(Math.random() * paramsState["maxNumber"]);
            exerciseContent.operations.push({
              a: randomFirstNumber,
              b: randomSecondNumber,
              type: paramsState["sheetType"]})
          }
        }
      }else if(selectedTemplate.templateName === "multiplicationTable"){
        const newQuestions = [];
        for (let i = 0; i < parseInt(paramsState["calculCount"],10); i++) {
            const num1 = Math.floor(Math.random() * 9) + 1;
            const num2 = Math.floor(Math.random() * 9) + 1;
            newQuestions.push({ num1, num2, answer: num1 * num2 });
        }
        exerciseContent.exerciseName = `Revoir mes tables de multiplications en ${paramsState["calculCount"]} questions`;
        exerciseContent.questions = (newQuestions);
      }
     

      navigate(path, {
        state: {
          exercise: exerciseContent,
          template: { ...selectedTemplate, prompt },
          fromCreate: true,
        },
      });
    }

  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Box>

      <Dialog open={!!selectedTemplate && !showSprite} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle sx={{ color: `${theme.palette.primary.main}` }}>{selectedTemplate && selectedTemplate.templateTitle}</DialogTitle>
        <DialogContent>
          {items.map((item, index) => (

            <Box key={index} sx={{ mb: 2 }}>
              <Divider sx={{ mb: 2, borderBottomWidth: 2, bgcolor: `${theme.palette.primary.main}` }}></Divider>
              <Typography variant="h7" sx={{ mb: 1 }}>
                {item.name}
              </Typography>
              {item.values && item.values.length > 0 && item.example && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={manualModes[item.id] || false}
                        onChange={(e) => handleManualModeChange(item.id, e.target.checked)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Mode manuel"
                  />
                </FormGroup>
              )}
              {item.values && item.values.length > 0 && !(manualModes[item.id] || false) && (
                <FormControl fullWidth margin="normal">
                  <InputLabel id={`select-label-${item.id}`}>Ton choix</InputLabel>
                  <Select
                    labelId={`select-label-${item.id}`}
                    id={`select-${item.id}`}
                    value={paramsState[item.id]}
                    label="Ton choix"
                    onChange={(e) => handleChangeProperty(item.id, e.target.value)}
                  >
                    {item.values.map((value, idx) => (
                      <MenuItem key={idx} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {(!item.values || item.values.length === 0 || manualModes[item.id]) && (
                <FormControl fullWidth margin="normal">
                  <TextField
                    id={`textfield-${item.id}`}
                    key={`param-${item.id}`}
                    label=""
                    defaultValue={paramsState[item.id]}
                    variant="outlined"
                    onChange={(e) => handleChangeProperty(item.id, e.target.value)}
                  />
                  <FormHelperText>Exemple: {item.example}</FormHelperText>
                </FormControl>
              )}
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={onValidate} color="success" variant="contained">
            Créer
          </Button>
        </DialogActions>
      </Dialog>
      {showSprite && <Sprite />}
    </Box>
  );
};

export default DialogCreatorComponent;
