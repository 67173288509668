import React, { useState, useEffect } from "react";
import { Grid, TextField, Typography, Stack, Divider, IconButton, Card, CardContent, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const SoustractionComponent = ({ numberA, numberB, index = 0, onValidated, triggerCorrection }) => {

    useEffect(() => {
        document.title = "Feuille d'opérations: soustractions";
      }, []);

    const nbDigits = Math.max(numberA.toString().length, numberB.toString().length);
    const retenueColor = ["red","blue","green", "pink", "purple"]

    const [result, setResult] = useState({
        result: Array(nbDigits).fill(''),
        retenue: Array(nbDigits).fill('')
    });

    const [digitNumberA, setDigitNumberA] = useState({
        normal: (numberA + "").split(''),
        widthRetenue: (numberA + "").split(''),
    });
    const [digitNumberB, setDigitNumberB] = useState({
        normal: (numberB + "").split(''),
        widthRetenue: (numberB + "").split(''),
    });


    const [correction, setCorrection] = useState(null);
    const [showRetenue, setShowRetenue] = useState(true);

    const handleChange = (digitIndex, value, field) => {
        const newResult = { ...result };
        newResult[field][digitIndex] = value;
        setResult(newResult);
    };

    const handleAddRetenue = (digitIndex) => {

        const newResult = { ...result };
        newResult.retenue[digitIndex] = (parseInt(newResult.retenue[digitIndex] || '0') + 1).toString();
        const prevNumberA = { ...digitNumberA };
        prevNumberA.widthRetenue[digitIndex] = (parseInt(prevNumberA.widthRetenue[digitIndex], 10) + 10).toString();
        const prevNumberB = { ...digitNumberB };
        const diffNbNumber =  (numberA+'').length - (numberB+'').length
        prevNumberB.widthRetenue[digitIndex - 1 - diffNbNumber] = (parseInt(prevNumberB.widthRetenue[digitIndex - 1 - diffNbNumber], 10) + 1).toString();
        setDigitNumberA(prevNumberA);
        setDigitNumberB(prevNumberB);
        setResult(newResult);
    };

    const handleRemoveRetenue = (digitIndex) => {
        const newResult = { ...result };
        newResult.retenue[digitIndex] = "";
        setResult(newResult);
        const prevNumberA = { ...digitNumberA };
        prevNumberA.widthRetenue[digitIndex] = (parseInt(prevNumberA.widthRetenue[digitIndex], 10) - 10).toString();
        const prevNumberB = { ...digitNumberB };
        const diffNbNumber =  (numberA+'').length - (numberB+'').length
        prevNumberB.widthRetenue[digitIndex - 1 - diffNbNumber] = (parseInt(prevNumberB.widthRetenue[digitIndex - 1 - diffNbNumber], 10) - 1).toString();
        setDigitNumberA(prevNumberA);
        setDigitNumberB(prevNumberB);
    };

    const handleCorrection = () => {
        const correctResult = `${numberA - numberB}`;
        const userResult = result.result.join('');
        const isCorrect = Number(userResult) === Number(correctResult);
        setCorrection({
            correct: isCorrect,
            expected: correctResult,
        });
        onValidated(isCorrect);
    };

    useEffect(() => {
        if (triggerCorrection) {
            handleCorrection();
        }
    }, [triggerCorrection]);

    const renderRetenueFields = () => (
        result.retenue.map((retenue, i) => (
            <Stack key={`retenue-${index}-${i}`} direction="column" spacing={1} alignItems="center">
                {!retenue && (i > 0) && <IconButton size="small" onClick={() => handleAddRetenue(i)}>
                    <AddIcon fontSize="small" />
                </IconButton>}
                {retenue && <IconButton size="small" onClick={() => handleRemoveRetenue(i)}>
                    <RemoveIcon fontSize="small" />
                </IconButton>}
                <Typography
                    textAlign={"center"}
                    style={{ lineHeight: "32px", width: '50px' }}
                >

                </Typography>
            </Stack>
        ))
    );

    return (
        <Card elevation={5} display="flex">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Stack direction="row" spacing={1} justifyContent="right">
                            <Tooltip title={showRetenue ? "Masquer les retenues" : "Afficher les retenues"}>
                                <IconButton size="small" onClick={() => setShowRetenue(!showRetenue)}>
                                    {showRetenue ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                                </IconButton>
                            </Tooltip>
                            {showRetenue && renderRetenueFields()}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Stack direction="row" spacing={1} justifyContent="right">
                            {showRetenue && digitNumberA.widthRetenue.map((digit, i) => {
                                const isModify = result.retenue[i];
                                return (
                                    <TextField
                                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                        key={`calcul${index}-numberA-${i}`}
                                        variant="outlined"
                                        size="small"
                                        value={digit}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ width: '50px', input: { color: isModify ? retenueColor[digitNumberA.widthRetenue.length - i - 1] : 'black' } }}
                                    />
                                )
                            })}

                            {!showRetenue && digitNumberA.normal.map((digit, i) => {
                                return (
                                    <TextField
                                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                        key={`calcul${index}-numberA-${i}`}
                                        variant="outlined"
                                        size="small"
                                        value={digit}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ width: '50px' }}
                                    />
                                )
                            })}

                        </Stack>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Stack direction="row" spacing={1} justifyContent="right">
                            <Typography variant="h6">-</Typography>

                            {showRetenue && digitNumberB.widthRetenue.map((digit, i) => {
                                const diffNbNumber =  (numberA+'').length - (numberB+'').length
                                const isModify = result.retenue[i + 1 + diffNbNumber];
                                return (
                                    <TextField
                                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                        key={`calcul${index}-numberB-${i}`}
                                        variant="outlined"
                                        size="small"
                                        value={digit}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ width: '50px', input: { color: isModify ? retenueColor[digitNumberA.widthRetenue.length - i - 2 - diffNbNumber] : 'black' } }}
                                    />
                                )
                            })}

                            {!showRetenue && digitNumberB.normal.map((digit, i) => {
                                return (
                                    <TextField
                                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                        key={`calcul${index}-numberB-${i}`}
                                        variant="outlined"
                                        size="small"
                                        value={digit}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ width: '50px' }}
                                    />
                                )
                            })}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Divider sx={{ borderWidth: 1, borderColor: "#3e3e3e" }} />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Stack direction="row" spacing={1} justifyContent="right">
                            {result.result.map((digit, i) => (
                                <TextField
                                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                    key={`calcul${index}-res-${i}`}
                                    autoComplete="off"
                                    variant="outlined"
                                    size="small"
                                    value={digit}
                                    onChange={(e) => handleChange(i, e.target.value, 'result')}
                                    style={{ width: '50px' }}
                                />
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
                {correction && (
                    <Typography sx={{ mt: 2 }} textAlign={"right"} color={correction.correct ? 'green' : 'red'}>
                        {correction.correct ? 'Correct' : `Incorrect, la réponse correcte est ${correction.expected}`}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default SoustractionComponent;
