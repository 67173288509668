import parisImg from "./img/paris.jpg";
import tokyoImg from "./img/tokyo.jpg";
import washingtondcImg from "./img/washingtondc.jpg";
import londonImg from "./img/london.jpg";
import brasiliaImg from "./img/brasilia.jpg";
import ottawaImg from "./img/ottawa.jpg";
import cairoImg from "./img/cairo.jpg";
import canberraImg from "./img/canberra.jpg";

const CapitalData = [
  {
    id: "capital-france-paris",
    number: 1,
    name: "Paris",
    country: "France",
    continent: "Europe",
    population: "2,161,000",
    description: "La capitale de la France, célèbre pour la Tour Eiffel, le Louvre et sa richesse culturelle.",
    imageUrl: parisImg
  },
  {
    id: "capital-japan-tokyo",
    number: 2,
    name: "Tokyo",
    country: "Japon",
    continent: "Asie",
    population: "13,515,000",
    description: "Une métropole trépidante, Tokyo mélange les traditions ancestrales avec une modernité futuriste.",
    imageUrl: tokyoImg
  },
  {
    number: 3,
    id: "capital-usa-washingtondc",
    name: "Washington",
    country: "États-Unis",
    continent: "Amérique du Nord",
    population: "705,749",
    description: "Washington D.C. est le cœur politique des États-Unis, abritant la Maison Blanche et le Capitole.",
    imageUrl: washingtondcImg
  },
  {
    number: 4,
    id: "capital-uk-london",
    name: "Londres",
    country: "Royaume-Uni",
    continent: "Europe",
    population: "8,982,000",
    description: "Londres est connue pour son histoire, de Big Ben à Buckingham Palace, et son dynamisme culturel.",
    imageUrl: londonImg
  },
  {
    number: 5,
    id: "capital-brazil-brasilia",
    name: "Brasilia",
    country: "Brésil",
    continent: "Amérique du Sud",
    population: "3,055,149",
    description: "Conçue par l'architecte Oscar Niemeyer, Brasilia est célèbre pour son architecture moderne et ses bâtiments gouvernementaux.",
    imageUrl: brasiliaImg
  },
  {
    number: 6,
    id: "capital-canada-ottawa",
    name: "Ottawa",
    country: "Canada",
    continent: "Amérique du Nord",
    population: "1,393,000",
    description: "Ottawa, riche en musées et institutions culturelles, est le centre politique du Canada.",
    imageUrl: ottawaImg
  },
  {
    number: 7,
    id: "capital-egypt-cairo",
    name: "Le Caire",
    country: "Égypte",
    continent: "Afrique",
    population: "9,500,000",
    description: "Le Caire, porte d'entrée des pyramides de Gizeh, est un mélange fascinant d'histoire ancienne et de vie urbaine moderne.",
    imageUrl: cairoImg
  },
  {
    number: 8,
    id: "capital-australia-canberra",
    name: "Canberra",
    country: "Australie",
    continent: "Océanie",
    population: "426,704",
    description: "Canberra, conçue avec une vision pour le futur, est le centre politique de l'Australie avec des espaces verts étendus.",
    imageUrl: canberraImg
  }
];

export default CapitalData;