import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  Grid,
  Alert,
  LinearProgress,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import APIService from "../../ApiService";

// Simulons une liste de catégories pour l'exemple.
const categories = [
  { id: "sport", description: "Un sport" },
  { id: "pays-ville", description: "Un pays ou une ville" },
  { id: "animal", description: "Un animal" },
  { id: "couleur", description: "Une couleur" },
  { id: "fruit-legume", description: "Un fruit ou un légume" },
  // Ajoute d'autres catégories selon les besoins.
];

const getRandomLetter = () => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return alphabet[Math.floor(Math.random() * alphabet.length)];
};

const getRandomCategories = () => {
  let shuffled = categories.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, 5);
};

const BacGame = () => {

  useEffect(() => {
    document.title = 'Petit Bac';
  }, []);

  const location = useLocation();
  const template = location.state?.template;

  const [responses, setResponses] = useState({});
  const [resultData, setResultData] = useState(null);
  const [progress, setProgress] = useState(0); // Nouveau: pour gérer la progression de la barre
  const [timeLeft, setTimeLeft] = useState(120); // 120 secondes pour 2 minutes
  const [timerActive, setTimerActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [letter, setLetter] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    setLetter(getRandomLetter());
    setSelectedCategories(getRandomCategories());
  }, []);

  const handleResponseChange = (categoryId, value) => {
    setResponses({
      ...responses,
      [categoryId]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setTimerActive(false); // Arrête le timer lors de la soumission manuelle
    const formattedResponses = {
      letter: `La lettre utilisée pour le petit bac est la lettre ${letter}`,
      responses: Object.keys(responses).map((categoryId) => ({
        categoryId,
        categoryDescription: categories.find((c) => c.id === categoryId)
          .description,
        responseValue: responses[categoryId],
      })),
    };
    console.log(formattedResponses);

    const prompForValidation = `Voici les réponses fournies par l'enfant en format JSON pour le tour avec la lettre ${letter} : ${JSON.stringify(
      formattedResponses
    )}`;
    const templateForValidation = {
      prompt: template.prompt.replace("{userResponseJson}", prompForValidation),
      json: template.templateJsonForValidation,
      iaEngine: template.iaEngine,
    };

    try {
      const response = await APIService.fetchWithAuth("/ai/ask", {
        method: "POST",
        body: JSON.stringify(templateForValidation),
      });
      if (response.ok) {
        const validationResponse = await response.json();
        const correctResponsesCount = validationResponse.result.filter(
          (r) => r.isCorrect === true || r.isCorrect === "true"
        ).length;

        // Met à jour l'état avec la réponse de l'API et le nombre de bonnes réponses
        setResultData({
          ...validationResponse,
          GoodResponseCount: correctResponsesCount,
        });
      } else {
        console.error("Une erreur est survenue lors de l'appel à l'API");
      }
    } catch (error) {
      console.error("Erreur lors de l'appel à l'API:", error);
    } finally {
      setIsLoading(false); // Arrête le chargement
    }
  };

  useEffect(() => {
    let timer;
    if (timerActive) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
        setProgress((prevProgress) => prevProgress + 100 / 120);
      }, 1000);
    }

    // Arrêt du compte à rebours si le temps est écoulé ou si l'utilisateur a soumis le formulaire
    if (timeLeft <= 0 || !timerActive) {
      clearInterval(timer);
      if (timeLeft <= 0) handleSubmit(); // Valide automatiquement si le temps s'est écoulé
    }

    return () => clearInterval(timer); // Nettoie l'intervalle lors du démontage du composant
    // eslint-disable-next-line
  }, [timeLeft, timerActive]);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Jeu du Petit Bac
      </Typography>
      <Typography variant="h5">Lettre: {letter}</Typography>
      <Paper style={{ padding: "20px", marginTop: "20px" }}>
        <Grid container spacing={2}>
          {selectedCategories.map((category) => (
            <Grid item xs={12} sm={6} key={category.id}>
              <TextField
                fullWidth
                label={category.description}
                variant="outlined"
                value={responses[category.id]}
                onChange={(e) =>
                  handleResponseChange(category.id, e.target.value.trim())
                }
              />
            </Grid>
          ))}
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ marginTop: "20px" }}
        >
          Valider les réponses
        </Button>
      </Paper>

      {/* Ajoute l'indicateur de chargement ici */}
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {/* Barre de progression */}
      <LinearProgress variant="determinate" value={progress} />

      {resultData && (
        <div>
          <Typography variant="h6" gutterBottom>
            Résultats
          </Typography>
          <Typography>
            Nombre de bonnes réponses : {resultData.GoodResponseCount}
          </Typography>
          {resultData.result.map((res) => (
            <Alert
              key={res.categoryId}
              severity={res.isCorrect ? "success" : "error"}
            >
              {res.explanationReason}
            </Alert>
          ))}
        </div>
      )}
    </Container>
  );
};

export default BacGame;
