import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Button, Stack } from '@mui/material';
import { Link } from "react-router-dom";

function ExerciseTemplateListAdmin() {

    const [exerciseTemplateList, setExerciseTemplateList] = useState([]);

    useEffect(() => {
        async function fetchData() {
          const response = await fetch('https://roin2cakj2.execute-api.eu-west-3.amazonaws.com/backoffice/getExerciseTemplateList')
          const subjectsData = await response.json();
          setExerciseTemplateList(subjectsData);
        }
        fetchData() 
    }, []);

    const handleDeleteTemplate = ((template) => {
        async function fetchData() {
            const response = await fetch(`https://roin2cakj2.execute-api.eu-west-3.amazonaws.com/backoffice/deleteTemplateByName/${template.templateName}`,{
                method: "DELETE"
            });
            console.log('Supprimer l\'exercice: ', response);
        };
        fetchData() 
    });

    return (
        <Stack spacing={4}>
            <List>
                {exerciseTemplateList.map((template, index) => (
                    <ListItem key={template.templateName} divider>
                        <ListItemText primary={template.templateName} />
                        <Button
                            variant="outlined"
                            color="primary"
                            // eslint-disable-next-line
                            component={Link} to='/admin/exercices' state={{ template }}
                        >
                        Éditer
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleDeleteTemplate(template)}
                        >
                        Supprimer
                        </Button>
                    </ListItem>
                ))}
            </List>
            <Button color="success" variant="contained" component={Link} to={{ pathname: '/admin/exercices'}}>Nouveau Template</Button>
        </Stack>
    );
}

 
export default ExerciseTemplateListAdmin;