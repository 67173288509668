import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import MascotImage from "../../img/logo.png";

import TipsData from "./TipsData";
import AlbumList from "../collections/AlbumList";
import { useNavigate } from "react-router-dom";
import Album from "../collections/AlbumData";
import AlbumCard from "../collections/AlbumCard";
import { useDispatch } from "react-redux";
import QuestService from "./QuestService";
import ReactGA from 'react-ga4';
import { Avatar, DialogActions } from "@mui/material";

function MascotModal({
  lastQuest,
  weeklyQuestRewared,
  activeProfile,
  isAuthenticated
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const quests = TipsData;

  const [reward, setReward] = useState(false);
  const [cardRewarded, setCardRewarded] = useState(null);
  const [currentQuest, setCurrentQuest] = useState(null);
  const [albumSelected, setAlbumSelected] = useState(null);
  const [exercilioText, setExercilioText] = useState("");

  const onRewardClicked = async (album) => {
    ReactGA.event("card_received", {
      label: window.location.pathname,
      category: `card`,
    });
    setAlbumSelected(album);
    const cards = Album.find((collection) => collection.id === album.id).cards;
    let randomCard = cards[Math.floor(Math.random() * cards.length)];
    setCardRewarded(randomCard);
    let currentCards = Array.isArray(activeProfile.cards)
      ? [...activeProfile.cards]
      : [];
    let foundCardIndex = currentCards.findIndex(
      (card) => card.id === randomCard.id
    );

    if (foundCardIndex !== -1) {
      // Si la carte existe déjà, créez un nouvel objet avec le count augmenté
      let foundCard = currentCards[foundCardIndex];
      let updatedCard = { ...foundCard, count: foundCard.count + 1 };
      currentCards[foundCardIndex] = updatedCard;
    } else {
      // Si la carte n'existe pas, ajoutez-la avec count initialisé à 1
      currentCards.push({ id: randomCard.id, count: 1 });
    }

    if (weeklyQuestRewared) {
      QuestService.markQuestAsDone(
        { weeklyQuestId: weeklyQuestRewared },
        currentCards,
        activeProfile,
        dispatch
      );
    } else {
      QuestService.markQuestAsDone(
        { tipId: currentQuest.id },
        currentCards,
        activeProfile,
        dispatch
      );
    }
    setReward(false);
  };

  const handleQuestAccepted = async () => {
    if (currentQuest.route) {
      if (currentQuest.autoValidate) {
        QuestService.markQuestAsDone(
          { tipId: currentQuest.id },
          activeProfile.cards,
          activeProfile,
          dispatch
        );
      }
      navigate(currentQuest.route);
    }
    setCurrentQuest(null);
  };

  useEffect(() => {
    if (!weeklyQuestRewared) {
      let currentQuest = null;
      if (!lastQuest || !lastQuest.questId) {
        currentQuest = quests[0];
        setReward(false);
      } else if (lastQuest.status === "completed") {
        currentQuest =
          quests[quests.findIndex((quest) => lastQuest.questId === quest.id)];
        setReward(true);
      } else {
        setReward(false);
        if (lastQuest.status === "done") {
          currentQuest =
            quests[
            quests.findIndex((quest) => lastQuest.questId === quest.id) + 1
            ];
        } else {
          currentQuest =
            quests[quests.findIndex((quest) => lastQuest.questId === quest.id)];
        }
      }
      setCurrentQuest(currentQuest);
      if (cardRewarded) {
        setExercilioText("Bravo tu as gagné cette carte");
      } else if (currentQuest) {
        if (reward) {
          setExercilioText(currentQuest.completedText);
        } else {
          setExercilioText(currentQuest.text);
        }
      }
    }
  }, [quests, lastQuest, cardRewarded, reward, weeklyQuestRewared]);

  useEffect(() => {
    if (weeklyQuestRewared) {
      setExercilioText("Choisi le paquet de carte à ouvrir");
      setReward(true);
    }
  }, [weeklyQuestRewared]);

  return (
    <>
    {isAuthenticated && (<Dialog
      open={(!!currentQuest || !!reward || !!cardRewarded)}
    >
      {!reward && !cardRewarded && (
        <>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar src={MascotImage} sx={{ width: 56, height: 56 }}></Avatar>
            <Typography margin={2}>{exercilioText}</Typography>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={() => {
                handleQuestAccepted();
              }}
            >
              {currentQuest?.button}
            </Button>
          </DialogActions>
        </>
      )}
      {reward && !cardRewarded && (
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar src={MascotImage} sx={{ width: 56, height: 56 }}></Avatar>
          <Typography margin={2}>{exercilioText}</Typography>
          <AlbumList
            isReward={true}
            onRewardClicked={(album) => onRewardClicked(album)}
          />
        </DialogContent>
      )}
      {cardRewarded && albumSelected && (
        <>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar src={MascotImage} sx={{ width: 56, height: 56 }}></Avatar>
            <Typography margin={2}>{exercilioText}</Typography>
            <AlbumCard
              margin="auto"
              card={cardRewarded}
              album={albumSelected}
            />
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={() => {
                setCurrentQuest(null);
                setCardRewarded(null);
              }}
            >
              Merci
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>)}</>
  );
}

export default MascotModal;
