import React, { useState, useEffect } from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  getStructure,
  addExercice,
  deleteExercice,
  saveExercice,
} from "../features/backoffice/structure";
import { v4 as uuidv4 } from "uuid";

import { TextField, Button, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxWidth: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StructureAdminForm = () => {
  const dispatch = useDispatch();

  const [openSubject, setOpenSubject] = useState(false);

  const [openExercice, setOpenExercice] = useState(false);
  const handleExerciceClose = () => setOpenExercice(false);
  const [selectedLevelForTheme, setSelectedLevelForTheme] = useState(false);
  const [selectedSubjectForTheme, setSelectedSubjectForTheme] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(false);
  const [lessonType, setLessonType] = useState("");

  const [editorLessonType, setEditorLessonType] = useState("");
  const [editorLessonName, setEditorLessonName] = useState("");

  function handleAddExercice() {
    setSelectedLesson(editorLessonName);
    setLessonType(editorLessonType);
    dispatch(
      addExercice({
        level: selectedLevelForTheme,
        subject: selectedSubjectForTheme,
        exerciceName: editorLessonName,
        lessonType: editorLessonType,
      })
    );
    handleExerciceClose();
  }

  function handleDeleteExercice() {
    dispatch(
      deleteExercice({
        level: selectedLevelForTheme,
        subject: selectedSubjectForTheme,
        exerciceName: selectedLesson,
      })
    );
  }

  useEffect(() => {
    // Update the document title using the browser API
    dispatch(getStructure());
  }, [dispatch]);

  const selectSubjectsByLevel = (state, level) => {
    return Object.keys(state.structure.value[level] || {});
  };

  const exercicesToSave = useSelector((state) => {
    let exerciceList = null;
    if (selectedLevelForTheme && selectedSubjectForTheme) {
      exerciceList = {
        schoolSubject: selectedSubjectForTheme,
        schoolLevel: selectedLevelForTheme,
        exercices:
          state.structure.value[selectedLevelForTheme][selectedSubjectForTheme],
      };
    }
    return exerciceList;
  }, shallowEqual);

  const levels = useSelector(
    (state) => Object.keys(state.structure.value),
    shallowEqual
  );

  const subjectsByLevel = useSelector((state) => {
    if (!selectedLevelForTheme) {
      return [];
    }
    let subjects = selectSubjectsByLevel(state, selectedLevelForTheme);
    return subjects;
  }, shallowEqual);

  const selectExercicesByLevel = (state, level, subject) => {
    return state.structure.value[level] && state.structure.value[level][subject]
      ? state.structure.value[level][subject]
      : [];
  };
  const exercicesBySubject = useSelector((state) => {
    if (!selectedSubjectForTheme) {
      return [];
    }
    let exercices = selectExercicesByLevel(
      state,
      selectedLevelForTheme,
      selectedSubjectForTheme
    );
    return exercices;
  }, shallowEqual);

  function validateMainStructure(event) {
    event.preventDefault();
    if (exercicesToSave) {
      dispatch(saveExercice(exercicesToSave));
    }
  }

  return (
    <React.Fragment>
      <form onSubmit={validateMainStructure}>
        <Stack spacing={4}>
          <h2>Ajouter une leçon</h2>
          <Stack spacing={2} direction="row">
            <FormControl fullWidth margin="normal">
              <InputLabel id="subject-level-for-theme-label">
                Niveau scolaire
              </InputLabel>
              <Select
                labelId="subject-level-for-theme-label"
                id="subject-level-for-theme"
                value={selectedLevelForTheme || ""}
                label="Niveau scolaire"
                onChange={(e) => setSelectedLevelForTheme(e.target.value)}
              >
                {levels.map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          {selectedLevelForTheme && (
            <Stack spacing={2} direction="row">
              <FormControl fullWidth margin="normal">
                <InputLabel id="subject-selector-for-theme-label">
                  Nouvelle matière
                </InputLabel>
                <Select
                  labelId="subject-selector-for-theme-label"
                  id="subject-selector-for-theme"
                  value={selectedSubjectForTheme || ""}
                  label="Nouvelle matière"
                  onChange={(e) => setSelectedSubjectForTheme(e.target.value)}
                >
                  {subjectsByLevel.map((subject) => (
                    <MenuItem key={subject} value={subject}>
                      {subject}
                    </MenuItem>
                  ))}
                </Select>

                <Button
                  disabled={!selectedLevelForTheme}
                  onClick={() => setOpenSubject(!openSubject)}
                >
                  Ajouter une matière
                </Button>
              </FormControl>
            </Stack>
          )}
          {selectedSubjectForTheme && (
            <Stack spacing={2} direction="row">
              <FormControl fullWidth margin="normal">
                <Stack direction="row">
                  <InputLabel id="lesson-selector-label">Leçon</InputLabel>
                  <Select
                    sx={{ width: "60%" }}
                    labelId="lesson-selector-label"
                    disabled={!selectedSubjectForTheme}
                    id="lesson-selector"
                    value={`${selectedLesson}|${lessonType}` || ""}
                    label="Leçon"
                    onChange={(e) => {
                      const [exerciceName, lessonType] =
                        e.target.value.split("|");
                      setSelectedLesson(exerciceName);
                      setLessonType(lessonType);
                    }}
                  >
                    {exercicesBySubject.map((exercice) => (
                      <MenuItem
                        key={uuidv4()}
                        value={`${exercice.exerciceName}|${exercice.lessonType}`}
                      >
                        {exercice.exerciceName}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={!selectedLesson}
                    variant="outlined"
                    value={lessonType}
                  ></TextField>
                </Stack>
                <Button
                  disabled={!selectedLevelForTheme}
                  onClick={() => setOpenExercice(!openSubject)}
                >
                  Ajouter une leçon
                </Button>
                <Modal
                  open={openExercice}
                  onClose={handleExerciceClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <TextField
                      id="newLessonName"
                      disabled={!selectedSubjectForTheme}
                      type="text"
                      variant="outlined"
                      color="secondary"
                      label="nom de la leçon"
                      fullWidth
                      required
                      sx={{ mb: 4 }}
                      value={editorLessonName}
                      onChange={(e) => setEditorLessonName(e.target.value)}
                    />
                    <TextField
                      id="newLessonType"
                      disabled={!selectedSubjectForTheme}
                      type="text"
                      variant="outlined"
                      color="secondary"
                      label="Type de la leçon (optionel)"
                      fullWidth
                      required
                      sx={{ mb: 4 }}
                      value={editorLessonType}
                      onChange={(e) => setEditorLessonType(e.target.value)}
                    />
                    <Button onClick={handleAddExercice}>Ajouter</Button>
                  </Box>
                </Modal>
              </FormControl>
              <Button onClick={handleDeleteExercice}>Supprimer</Button>
            </Stack>
          )}
          <Button variant="outlined" color="secondary" type="submit">
            Sauvegarder les exercices pour cette matière
          </Button>
        </Stack>
      </form>
    </React.Fragment>
  );
};

export default StructureAdminForm;
