import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import ValidateButton from "../ValidateButton";
import { useTheme } from "@emotion/react";

const WordMatch = () => {

  useEffect(() => {
    document.title = 'Relis la description';
  }, []);

  const location = useLocation();
  const theme = useTheme();
  const exerciseParam = location.state?.exercise;
  const fromCreate = location.state?.fromCreate;
  const exerciseId = location.state?.exerciseId;
  const exerciseType = "wordMatch";
  const schoolSubject = location.state?.schoolSubject;

  const [wordsAndDescriptions, setWordsAndDescriptions] = useState([]);
  const [shuffledDescriptions, setShuffledDescriptions] = useState([]);

  useEffect(() => {
    if (exerciseParam && exerciseParam.wordList) {
      // Fonction pour mélanger un tableau
      const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]]; // Échange
        }
      };

      // Mélanger wordList et sélectionner les 5 premiers éléments
      const shuffledWordList = [...exerciseParam.wordList];
      shuffleArray(shuffledWordList);
      const selectedWords = shuffledWordList.slice(0, 5);

      // Créer initialData avec les éléments sélectionnés
      const initialData = selectedWords.map((item, index) => ({
        ...item,
        id: index, // Attribuer un ID unique
        wordPoint: { x: 20, y: 50 + index * 70 }, // Position du mot
        descriptionPoint: { x: 150, y: 50 + index * 70 }, // Position initiale de la description
      }));

      // Mélanger uniquement les descriptions
      const shuffled = [...initialData].sort(() => 0.5 - Math.random());
      const shuffledDescriptions = shuffled.map((item, index) => ({
        ...item,
        descriptionPoint: { x: 150, y: 50 + index * 70 }, // Mise à jour avec la nouvelle position
      }));

      setWordsAndDescriptions(initialData);
      setShuffledDescriptions(shuffledDescriptions); // Nouvel état pour l'affichage des descriptions
      setPoints(initialData); // Les points contiennent maintenant les positions correctes des mots et descriptions mélangées
    }
  }, [exerciseParam]);

  const [points, setPoints] = useState([]);
  const [lines, setLines] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [validate, setValidate] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  const handlePointClick = (index, type) => {
    if(validate){
      return;
    }
    // Vérifier si le point sélectionné est déjà connecté
    const pointIsConnected = points[index].isConnected;

    if (pointIsConnected) {
      // Si le point est déjà connecté, nous devons supprimer la ligne existante
      const updatedLines = lines.filter((line) => {
        const fromMatch =
          line.from.x === points[index][type + "Point"].x &&
          line.from.y === points[index][type + "Point"].y;
        const toMatch =
          line.to.x === points[index][type + "Point"].x &&
          line.to.y === points[index][type + "Point"].y;
        return !fromMatch && !toMatch;
      });

      // Réinitialiser l'état isConnected pour les points liés à cette ligne
      const updatedPoints = points.map((point, pIndex) => {
        if (pIndex === index) {
          return { ...point, isConnected: false };
        } else {
          const fromMatch = lines.some(
            (line) =>
              (line.from.x === point[type + "Point"].x &&
                line.from.y === point[type + "Point"].y) ||
              (line.to.x === point[type + "Point"].x &&
                line.to.y === point[type + "Point"].y)
          );

          if (fromMatch) {
            return { ...point, isConnected: false };
          }
        }

        return point;
      });

      setLines(updatedLines);
      setPoints(updatedPoints);
      setSelectedPoint(null); // Réinitialiser le point sélectionné si nécessaire
    } else if (!selectedPoint) {
      // Si aucun point n'est actuellement sélectionné, définir ce point comme le point de départ
      setSelectedPoint({ index, type });
    } else {
      // Si un autre point est déjà sélectionné, tenter de tracer une ligne entre les deux points
      if (type !== selectedPoint.type) {
        const newLine = {
          from:
            selectedPoint.type === "word"
              ? points[selectedPoint.index].wordPoint
              : points[selectedPoint.index].descriptionPoint,
          to:
            type === "word"
              ? points[index].wordPoint
              : points[index].descriptionPoint,
          wordId:
            selectedPoint.type === "word"
              ? points[selectedPoint.index].id
              : points[index].id, // ID du mot
          descriptionId:
            selectedPoint.type === "word"
              ? points[index].id
              : points[selectedPoint.index].id, // ID de la description
          isValid: undefined,
        };
        setLines([...lines, newLine]);
        // Marquer les points comme connectés
        let updatedPoints = points.map((point, pIndex) =>
          pIndex === index || pIndex === selectedPoint.index
            ? { ...point, isConnected: true }
            : point
        );
        setPoints(updatedPoints);
        setSelectedPoint(null); // Réinitialiser le point de départ après la connexion
      }
    }
  };

  const handleCheckAnswers = () => {
    setValidate(true);
    let updatedLines = lines.map((line) => {
      const word = wordsAndDescriptions[line.wordId];
      const description = shuffledDescriptions[line.descriptionId];

      if (word && description && word.id === description.id) {
        return { ...line, isValid: true };
      } else {
        return null;
      }
    });

    updatedLines = updatedLines.filter((line) => line !== null);

    // Marquer les lignes manquantes comme incorrectes
    const allWordIds = wordsAndDescriptions.map((word) => word.id);
    const connectedWordIds = updatedLines.map((line) => line.wordId);
    const missingWordIds = allWordIds.filter(
      (id) => !connectedWordIds.includes(id)
    );

    const updatedLinesWithMissing = [
      ...updatedLines,
      ...missingWordIds.map((id) => {
        const wordPoint = wordsAndDescriptions[id].wordPoint;
        const correctDescription = shuffledDescriptions.find(
          (item) => item.id === id
        );
        return {
          from: wordPoint,
          to: correctDescription.descriptionPoint,
          isValid: false,
        };
      }),
    ];

    setLines(updatedLinesWithMissing);

    const correctAnswers = updatedLines.filter((line) => line.isValid).length;

    if (correctAnswers === wordsAndDescriptions.length) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  };

  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <Stack spacing={2}>
        <Typography variant="h4" align="center">
          Relie le mot à sa description
        </Typography>
        <Typography variant="h5" align="center">
          Clique sur le rond pour choisir le début ou la fin d'un trait.
        </Typography>

        <Grid
          container
          spacing={2}
          style={{ marginTop: "20px", flexWrap: "nowrap" }}
        >
          <Grid
            item
            xs="3"
            style={{
              minWidth: "100px",
              textAlign: "right",
              position: "relative",
            }}
          >
            {wordsAndDescriptions.map((item, index) => (
              <Typography
                key={`word-text-${item.id}`}
                style={{
                  minWidth: "100px",
                  position: "absolute",
                  top: index * 70 + 55,
                  right: 10,
                }}
              >
                {item.word}
              </Typography>
            ))}
          </Grid>
          <Grid
            item
            xs="3"
            style={{
              minWidth: "200px",
              display: "flex",
              justifyContent: "center",
              overflowX: "auto",
            }}
          >
            <svg
              width="200px"
              height={
                Math.max(
                  wordsAndDescriptions.length,
                  shuffledDescriptions.length
                ) *
                  60 +
                100
              }
              style={{
                display: "block",
                margin: "auto",
              }}
            >
              {wordsAndDescriptions.map((item, index) => (
                <React.Fragment key={`word-${item.id}`}>
                  <circle
                    cx={item.wordPoint.x}
                    cy={item.wordPoint.y}
                    r="15"
                    fill={
                      selectedPoint &&
                      selectedPoint.index === index &&
                      selectedPoint.type === "word"
                        ? "green"
                        : "blue"
                    }
                    onClick={() => handlePointClick(index, "word")}
                    style={{ cursor: "pointer" }}
                  />
                </React.Fragment>
              ))}
              {shuffledDescriptions.map((item, index) => (
                <React.Fragment key={`desc-${item.id}`}>
                  <circle
                    cx={item.descriptionPoint.x}
                    cy={item.descriptionPoint.y}
                    r="15"
                    fill={
                      selectedPoint &&
                      selectedPoint.index === index &&
                      selectedPoint.type === "description"
                        ? theme.palette.success.main
                        : "blue"
                    }
                    onClick={() => handlePointClick(index, "description")}
                    style={{ cursor: "pointer" }}
                  />
                </React.Fragment>
              ))}
              {/* Lignes reliant les points */}
              {lines.map((line, index) => (
                <line
                  key={index}
                  x1={line.from.x}
                  y1={line.from.y}
                  x2={line.to.x}
                  y2={line.to.y}
                  stroke={
                    line.isValid === undefined
                      ? "black"
                      : line.isValid
                      ? theme.palette.success.main
                      : theme.palette.error.main
                  } // Utilise noir par défaut, vert pour valide, rouge pour invalide
                  strokeWidth="4"
                />
              ))}
            </svg>
          </Grid>
          <Grid item xs={6} style={{ minWidth: "200px", position: "relative" }}>
            {shuffledDescriptions.map((item, index) => (
              <Typography
                key={`desc-text-${item.id}`}
                style={{
                  minWidth: "200px",
                  position: "absolute",
                  height: "50px",
                  overflowY: "auto",
                  top: index * 70 + 50,
                  left: 10,
                }}
              >
                {item.description}
              </Typography>
            ))}
          </Grid>
        </Grid>
        <ValidateButton exerciseType={exerciseType} exerciseJson={exerciseParam} schoolSubject={schoolSubject} validate={validate} isCorrect={isCorrect} fromCreate={fromCreate} handleValidate={handleCheckAnswers} exerciseId={exerciseId} />
        <br></br>
      </Stack>
    </Box>
  );
};

export default WordMatch;
