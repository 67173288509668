import React,{ forwardRef } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

import topLeft from "../img/topLeft.png";
import topCenter from "../img/topCenter.png";
import topRight from "../img/topRight.png";
import centerLeft from "../img/centerLeft.png";
import center from "../img/center.png";
import centerRight from "../img/centerRight.png";
import bottomLeft from "../img/bottomLeft.png";
import bottomCenter from "../img/bottomCenter.png";
import bottomRight from "../img/bottomRight.png";

const SkinBox = forwardRef((props, ref ) => {
  const BackgroundSegments = styled("div")({
    display: "grid",
    gridTemplateColumns: "34px auto 34px",
    gridTemplateRows: "34px auto 34px",
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  });

  const BackgroundSegment = styled("div")(({ imageUrl }) => ({
    backgroundImage: imageUrl,
    backgroundSize: "contain",
    backgroundRepeat: "repeat",
  }));

  const backgroundImageUrls = {
    segmentOne: "url(" + topLeft + ")",
    segmentTwo: "url(" + topCenter + ")",
    segmentThree: "url(" + topRight + ")",
    segmentFour: "url(" + centerLeft + ")",
    segmentFive: "url(" + center + ")",
    segmentSix: "url(" + centerRight + ")",
    segmentSeven: "url(" + bottomLeft + ")",
    segmentEight: "url(" + bottomCenter + ")",
    segmentNine: "url(" + bottomRight + ")",
  };

  return (
    <Box ref={ref} {...props} style={{ borderRadius:"10px", overflow:"hidden",position:"relative", ...props.style }}>
      <BackgroundSegments>
        <BackgroundSegment imageUrl={backgroundImageUrls.segmentOne} />
        <BackgroundSegment
          imageUrl={backgroundImageUrls.segmentTwo}
          style={{ gridColumn: "2", gridRow: "1" }}
        />
        <BackgroundSegment
          imageUrl={backgroundImageUrls.segmentThree}
          style={{ gridColumn: "3", gridRow: "1" }}
        />
        <BackgroundSegment
          imageUrl={backgroundImageUrls.segmentFour}
          style={{ gridColumn: "1", gridRow: "2" }}
        />
        <BackgroundSegment
          imageUrl={backgroundImageUrls.segmentFive}
          style={{ gridColumn: "2", gridRow: "2" }}
        />
        <BackgroundSegment
          imageUrl={backgroundImageUrls.segmentSix}
          style={{ gridColumn: "3", gridRow: "2" }}
        />
        <BackgroundSegment
          imageUrl={backgroundImageUrls.segmentSeven}
          style={{ gridColumn: "1", gridRow: "3" }}
        />
        <BackgroundSegment
          imageUrl={backgroundImageUrls.segmentEight}
          style={{ gridColumn: "2", gridRow: "3" }}
        />
        <BackgroundSegment
          imageUrl={backgroundImageUrls.segmentNine}
          style={{ gridColumn: "3", gridRow: "3" }}
        />
      </BackgroundSegments>

      <Box
        style={{
          position: "relative",
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
});

export default SkinBox;
