import React, { useState } from 'react';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, Stack } from '@mui/material';
import AvatarGenerator from './AvatarGenerator';
import GradeLevel from '../../data/GradeLevel';
import ThemeList from '../../data/ThemeList';
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch } from 'react-redux';
import { setActiveProfile } from '../authentication/userInfos';
import ApiService from '../../ApiService';

const ProfileEditor = () => {


  const location = useLocation();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
    
  let initialProfile = null;
  const { state } = location;
  if(location && state){
      initialProfile = state.initialProfile;
  }

  let navigate = useNavigate();

  const [profile, setProfile] = useState(initialProfile || { pseudo: '', image: '', classe: '' });
  const defaultAvatar = initialProfile ? initialProfile.image : '';

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value.trimEnd() });
  };

  const setAvatarUrl = (url) => {
    setProfile({ ...profile, "image": url});
  };

  async function handleDeleteProfile (profile) {
      await ApiService.fetchWithAuth(`/profiles/${profile.profileId}`,{
        method: "DELETE"
      });
      navigate('/profile/list');
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logique de soumission ici, par exemple, envoyer à une API
    async function addProfile(profile) {
      const response = await ApiService.fetchWithAuth(`/profiles/${initialProfile?"update":"add"}`,{
        method: "POST",
        body: JSON.stringify(profile),
      });
      if (!response.ok) {
        if (response.status === 409) {
            setError("Ce pseudo n'est pas disponible");
        }else{
          setError("Erreur inconnue");
        }
      }else{
        dispatch(setActiveProfile(profile));
        navigate('/profile/list');
      }
    } 
    addProfile(profile);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Button
        variant="outlined"
        color="secondary"
        style={{marginBottom:"20px"}}
        startIcon={<ArrowBackIosIcon />}
        onClick={()=>{navigate('/profile/list');}}
      >
        Retour
      </Button>
      <AvatarGenerator defaultImage={defaultAvatar} onAvatarUrlChange={setAvatarUrl} />
      <TextField
        label="Pseudo"
        name="pseudo"
        disabled={!!initialProfile}
        value={profile.pseudo}
        onChange={handleChange}
        margin="normal"
        fullWidth
        error={!!error}
        helperText={error ? error : ""}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Classe</InputLabel>
        <Select
          name="classe"
          value={profile.classe}
          onChange={handleChange}
          label="Classe"
        >
        {
          GradeLevel.map((grade)=>(<MenuItem key={`classe-${grade}`}value={grade}>{grade}</MenuItem>)
        )}
        </Select>
        <FormHelperText>Choisissez la classe de l'enfant</FormHelperText>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Le thème</InputLabel>
        <Select
          name="defaultTheme"
          value={profile.defaultTheme || ""}
          onChange={handleChange}
          label="Le thème"
        >
        {
          ThemeList.map((theme)=>(<MenuItem key={`defaultTheme-${theme}`}value={theme}>{theme}</MenuItem>)
        )}
        </Select>
        <FormHelperText>La passion ou un thème qu'aime l'enfant</FormHelperText>
      </FormControl>
      <Stack direction={"row"} spacing={2}>
        <Button disabled={!profile.pseudo || !profile.classe} type="submit" variant="contained" color="primary" margin="normal">
          {initialProfile ? 'Valider la modification' : 'Créer le Profil'}
        </Button>
        {initialProfile && <Button variant="contained" color="error" margin="normal" onClick={()=>{handleDeleteProfile(initialProfile)}}>Supprimer</Button>}
      </Stack>
    </form>
  );
};

export default ProfileEditor;