import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, Button, Grid, Stack } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';

const Calculator = () => {
  const [userAnswer, setUserAnswer] = useState('');
  const [showSteps, setShowSteps] = useState(false);
  const { exerciceId } = useParams();
  const [data, setData] = useState(false);
  const location = useLocation();
  const exerciseParam = location.state?.exercise;


  useEffect(() => {
    setData(exerciseParam)
  }, [exerciceId, exerciseParam]);

  const handleCheckAnswer = () => {
    if (userAnswer === data.bonne_reponse) {
      setShowSteps(false);
    } else {
      setShowSteps(true);
    }
  };

  return (
    <Card>
       {data &&
      <CardContent>
        <Stack spacing={4}>
        <Typography variant="h5" component="div">
          {data.question}
        </Typography>
        <Stack>
          <TextField
          label="Ta réponse"
          variant="outlined"
          value={userAnswer}
          onChange={(e) => setUserAnswer(e.target.value)}
          sx={{ marginY: 2 }}
        />
      
          <Button variant="contained" onClick={handleCheckAnswer}>
            Vérifier
          </Button>
        </Stack>
        {showSteps && (
          <Grid container>
            <Stack spacing={4}>
            <Typography variant="h5" component="div">
              {data.bonne_reponse}
            </Typography>
            <Typography variant="h5" component="div">
              <u>Explication:</u>
            </Typography>
            <Typography variant="h6" component="div">
              {data.explication}
            </Typography>
            <Typography variant="h5" component="div">
                  <u>Conseils:</u>
             </Typography>
            {data.conseils.map((conseil, index) => (
              <Grid item key={index} xs={12}>
                <Typography variant="h6" component="div">
                  - {conseil}
                </Typography>
              </Grid>
            ))}
            </Stack>
          </Grid>
        )}
        </Stack>
      </CardContent>
      }
    </Card>
  );
};

export default Calculator;