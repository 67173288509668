import React, { useState, useEffect } from "react";
import {
    Button,
    Typography,
    Box,
    Card,
    CardContent,
    TextField,
    Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MicIcon from '@mui/icons-material/Mic';
import ValidateButton from "../ValidateButton";

const MultiplicationTable = () => {

    useEffect(() => {
        document.title = 'Table de multiplications';
      }, []);

    const theme = useTheme();
    const exerciseType = "multiplicationTable"
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [endTime, setEndTime] = useState(null);
    const [errors, setErrors] = useState([]);
    const [answerCorrect, setAnswerCorrect] = useState(null);
    const [userAnswer, setUserAnswer] = useState("");
    

    const location = useLocation();
    const exerciseJson = location?.state?.exercise;
    const exerciseId = location.state?.exerciseId;
    const fromCreate = location?.state?.fromCreate;
    const schoolSubject = location.state?.schoolSubject;

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    const speak = (text) => {
        const speech = new SpeechSynthesisUtterance(text);
        speech.pitch = 1;
        let voices = speechSynthesis.getVoices();

        let defaultVoice = voices.find((voice) => voice.default === true);
        let frenchVoice = voices.find((voice) => voice.lang === "fr-FR");
        if (!defaultVoice || defaultVoice.lang !== "fr-FR") {
            if (frenchVoice) {
                speech.voice = frenchVoice;
            }
        }
        window.speechSynthesis.speak(speech);
    };

    useEffect(() => {
        setQuestions(exerciseJson.questions);
    }, [exerciseJson]);

    useEffect(() => {
        if (!listening && transcript) {
            handleVoiceAnswer(transcript);
        }
    }, [listening, transcript]);

    useEffect(() => {
        if (questions.length > 0) {
            speakQuestion(questions[currentQuestion]);
        }
    }, [questions, currentQuestion]);

    const speakQuestion = (question) => {
        const text = `${question.num1} multiplié par ${question.num2}`;
        speak(text);
    };

    const handleVoiceAnswer = (transcript) => {
        const userAnswer = processTranscript(transcript);
        const isCorrect = userAnswer === String(questions[currentQuestion].answer);
        setAnswerCorrect(isCorrect);

        if (isCorrect) {
            speak("Bravo");
        } else {
            const error = {
                question: `${questions[currentQuestion].num1} x ${questions[currentQuestion].num2}`,
                correctAnswer: questions[currentQuestion].answer,
                userAnswer,
            };
            setErrors([...errors, error]);
            speak(`Mauvaise réponse. La bonne réponse est ${questions[currentQuestion].answer}`);
        }

        if (currentQuestion >= questions.length - 1) {
            setEndTime(new Date());
        }
    };

    const processTranscript = (transcript) => {
        // Suppression des mots non numériques
        const wordsToNumbers = {
          'zéro': '0', 'un': '1', 'deux': '2', 'trois': '3', 'quatre': '4',
          'cinq': '5', 'six': '6', 'sept': '7', 'huit': '8', 'neuf': '9',
          'dix': '10', 'onze': '11', 'douze': '12', 'treize': '13', 'quatorze': '14',
          'quinze': '15', 'seize': '16', 'dix-sept': '17', 'dix-huit': '18', 'dix-neuf': '19',
          'vingt': '20', 'trente': '30', 'quarante': '40', 'cinquante': '50', 'soixante': '60',
          'soixante-dix': '70', 'quatre-vingt': '80', 'quatre-vingt-dix': '90'
        };
    
        let cleanedTranscript = transcript.toLowerCase().split(" ").map(word => wordsToNumbers[word] || word).join("");
        return cleanedTranscript.replace(/[^0-9]/g, '');
      };

    const handleSubmit = (event) => {
        event.preventDefault();
        const isCorrect = userAnswer === String(questions[currentQuestion].answer);
        setAnswerCorrect(isCorrect);

        if (isCorrect) {
            speak("Bravo");
        } else {
            const error = {
                question: `${questions[currentQuestion].num1} x ${questions[currentQuestion].num2}`,
                correctAnswer: questions[currentQuestion].answer,
                userAnswer,
            };
            setErrors([...errors, error]);
            speak(`Mauvaise réponse. La bonne réponse est ${questions[currentQuestion].answer}`);
        }

        if (currentQuestion < questions.length - 1) {
            setUserAnswer("");
        } else {
            setEndTime(new Date());
        }
    };

    const handleNextQuestion = () => {
        window.speechSynthesis.cancel();
        setAnswerCorrect(null);
        resetTranscript();
        setCurrentQuestion(currentQuestion + 1);
    };

    const renderErrors = () => (
        <Stack sx={{mt:2}} spacing={2}>
            {errors.map((error) => (
                <Stack direction={"row"} spacing={2}><Typography  variant="h6" sx={{color: `${theme.palette.primary.main}`}}>{error.question} = {error.correctAnswer} </Typography><Typography variant="h6"> et non</Typography><Typography variant="h6" sx={{color: `${theme.palette.error.main}`}}>{error.userAnswer}</Typography></Stack>
            ))}
        </Stack>
    );

    if (!browserSupportsSpeechRecognition) {
        return <span>Le navigateur ne supporte pas la reconnaissance vocale.</span>;
    }

    if (endTime) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                <Stack spacing={2}>
            <Card>
                <CardContent>
                {errors.length > 0 ? (
                    <Box>
                        <Typography variant="h6">Voici tes erreurs :</Typography>
                        {renderErrors()}

                    </Box>
                ) : (
                    <Box>
                        <Typography variant="h6">
                            Bien joué!
                        </Typography>
                    
                    </Box>
                )}
            </CardContent>
            </Card>
            <ValidateButton exerciseType={exerciseType} schoolSubject={schoolSubject} exerciseJson={exerciseJson} validate={endTime} isCorrect={(errors.length === 0)} fromCreate={fromCreate} handleValidate={()=>{}} exerciseId={exerciseId} />
            </Stack>
        </Box>
        );
    }

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
            <Card>
                <CardContent>
                    <Typography
                        variant="h1"
                        mb={2}
                        sx={{ color: `${theme.palette.primary.main}`, textAlign: 'center' }}
                    >
                        {questions[currentQuestion]?.num1} x {questions[currentQuestion]?.num2}
                    </Typography>
                    {answerCorrect !== null && (
                        <Box mb={2} textAlign="center">
                            {answerCorrect ? (
                                <Box>
                                    <CheckCircleIcon style={{ fontSize: 50, color: 'green' }} />
                                    <Typography variant="h6">Bravo!</Typography>
                                </Box>
                            ) : (
                                <Box>
                                    <CancelIcon style={{ fontSize: 50, color: 'red' }} />
                                    <Typography variant="h6">
                                        Mauvaise réponse. La bonne réponse est {questions[currentQuestion]?.answer}.
                                    </Typography>
                                </Box>
                            )}
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{ marginTop: "10px" }}
                                onClick={handleNextQuestion}
                            >
                                Question suivante
                            </Button>
                        </Box>
                    )}
                    {answerCorrect === null && (
                        <>
                            <Box display="flex" justifyContent="center" mb={2}>
                                <Button
                                    variant="contained"
                                    onClick={SpeechRecognition.startListening}
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        borderRadius: '50%',
                                        minWidth: 'auto',
                                        padding: 0,
                                    }}
                                >
                                    <MicIcon style={{ fontSize: 40 }} />
                                </Button>
                            </Box>
                            <Typography variant="h6" mt={2} textAlign="center">
                                {listening ? "Parlez maintenant..." : "Cliquez sur le bouton pour commencer à parler"}
                            </Typography>
                            {listening && (
                                <Stack spacing={2}>
                                <Typography variant="h6" mt={2} textAlign="center">
                                    Vous avez dit: 
                                </Typography>
                                <Typography variant="h3" sx={{color: `${theme.palette.primary.main}`}} mt={2} textAlign="center">
                                    {transcript}
                                </Typography>
                                </Stack>
                            )}
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Réponse"
                                    variant="outlined"
                                    type="number"
                                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                    value={userAnswer}
                                    onChange={(e) => setUserAnswer(e.target.value)}
                                    fullWidth
                                    mb={2}
                                />
                                <Button
                                    variant="contained"
                                    type="submit"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                >
                                    Suivant
                                </Button>
                            </form>
                        </>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default MultiplicationTable;
