import React, { useState, useEffect } from 'react';
import {  Paper, Typography, Grid, Button, Stack } from '@mui/material';
import {  useTheme } from '@mui/material/styles';

import newProfilImg from "../../img/newProfilImg.png"
import { Link, useNavigate } from "react-router-dom";
import { setActiveProfile } from '../authentication/userInfos';
import { useDispatch } from 'react-redux';
import ApiService from '../../ApiService';

const tileSize = 150;

const ProfileList = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [profilList, setProfilList] = useState([]);

  const handleSelectProfile = (profile) => {
    dispatch(setActiveProfile(profile));
    navigate('/');
  }

  useEffect(() => {
      async function fetchData() {
        const response = await ApiService.fetchWithAuth(`/profiles`);
        setProfilList(await response.json());
      }
      fetchData() 
    
  }, []);
  
  return (
    <Stack spacing={3}>
      <Typography variant="h4" color={theme.palette.primary.main} sx={{fontFamily:"Softers"}} textAlign={"center"}>
        {`Mes profils`}
      </Typography>
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          {profilList.map((profile, index) => (
            <Stack key={`profil-block-${index}`}  spacing={1} sx={{padding:3}}>
              <Paper 
                onClick={()=>handleSelectProfile(profile)}
                key={`profil-img-${index}`} 
                style={{
                  width:`${tileSize}px`,
                  height:`${tileSize}px`,
                  display: 'flex',
                  margin:'auto',
                  borderRadius: (tileSize/2),
                  backgroundSize: 'contain',
                  backgroundPosition: "center",
                  backgroundRepeat:"no-repeat",
                  backgroundImage: `url(${profile.image})`
                }}
              >
              </Paper>
              <Typography variant="h6" style={{textAlign:"center" }}>
                {profile.pseudo}
              </Typography>
              <Button type="submit" variant="contained" color="primary" margin="normal" component={Link} to="/profile/edit" state={{ initialProfile:profile }}>
                Editer
              </Button>
            </Stack>
          ))}
            <Grid item key={`tail-profil-new`} style={{textAlign: 'center', justifyContent:"center"}}>
              <Stack spacing={1} sx={{padding:3}}>
              <Paper 
                component={Link} to="/profile/edit"
                key="profil-img-new"
                style={{
                  width:`${tileSize}px`,
                  height:`${tileSize}px`,
                  display: 'flex',
                  margin:'auto',
                  justifyContent: 'end',
                  flexDirection: 'column',
                  backgroundSize: 'cover',
                  backgroundPosition: "center",
                  backgroundRepeat:"no-repeat",
                  backgroundImage: `url(${newProfilImg})`,
                  borderRadius:"45px"
                }}
              >
              </Paper>
              <Typography variant="h6" style={{textAlign:"center" }}>
                Créer un profil
              </Typography>
              </Stack>
            </Grid>
            
      </Grid>
    </Stack>
  );
};

export default ProfileList;