import * as React from "react";
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import Divider from "@mui/material/Divider";

const AlbumCard = ({ card, album, margin }) => {
  const { name, imageUrl } = card;
  const properties = album.properties;

  return (
    <Card
      sx={{
        width: { xs: "227px", sm: "345px" },
        height: { xs: "396px", sm: "600px" },
        m: margin || 2,
        bgcolor: album.cardColor,
        p: 2,
        borderRadius: "16px",
        boxShadow: "2px 01px 2px 2px rgba(0, 0, 0, .9)",
        border: "4px solid #D6C3AE",
        color: "#D6C3AE",
      }}
    >
      <CardContent
        sx={{
          bgcolor: "#D6C3AE",
          borderRadius: "16px",
          height: { xs: "47px", sm: "72px" },
          color: album.cardColor,
          mb: 1,
          boxShadow: "2px 01px 2px 2px rgba(0, 0, 0, .9)",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            padding: "0px",
            fontWeight: "bold",
            position: "relative",
          }}
        >
          {name.toUpperCase()}
        </Box>
      </CardContent>

      <CardMedia
        component="img"
        image={imageUrl}
        alt={name}
        sx={{
          height: { xs: "185px", sm: "305px" },
          width: { xs: "185px", sm: "305px" },
          objectFit: "contain",
          borderRadius: "16px",
          border: "4px solid #D6C3AE",
          mb: 2,
          boxShadow: "2px 01px 2px 2px rgba(0, 0, 0, .9)",
        }}
      />
      <CardContent
        sx={{
          bgcolor: "#D6C3AE",
          borderRadius: "16px",
          color: album.cardColor,
          overflowY: "auto",
          height: { xs: "100px", sm: "150px" },
          boxShadow: "2px 01px 2px 2px rgba(0, 0, 0, .9)",
        }}
      >
        {properties.map((property, index) => (
          <Box key={card.name + property.key}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
            >
              <Typography
                variant="body1"
                color={album.cardColor}
                sx={{ fontWeight: "700", mr: 1 }}
              >
                {property.value}
              </Typography>
              <Typography variant="body1" color={album.cardColor}>
                {card[property.key]}
              </Typography>
            </Box>
            {index < properties.length - 1 && (
              <Divider size="small" color={album.cardColor}></Divider>
            )}
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

export default AlbumCard;
