import React, { useState, useEffect } from "react";
import { Grid, Tabs, Tab, Stack, Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ApiService from "../../ApiService";
import AlbumData from "./AlbumData";
import DoubleCards from "./DoubleCards";
import ReceivedCards from "./SharedCards";
import { useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";

const AlbumList = ({ isReward, onRewardClicked }) => {

  useEffect(() => {
    document.title = 'Mes albums';
  }, []);

  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);
  const currentCards = useSelector((state) => state?.userInfos?.activeProfile?.cards || [], shallowEqual);
  const activeProfile = useSelector((state) => state?.userInfos?.activeProfile, shallowEqual);
  const [receivedCards, setReceivedCards] = useState([]);
  const [friends, setFriends] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (activeProfile) {
      async function fetchFriends() {
        if (activeProfile.friendList && activeProfile.friendList.length) {
          const friendsResponse = await ApiService.fetchWithAuth(`/profiles/publicInfos`, {
            method: "POST",
            body: JSON.stringify({
              profileIds: activeProfile.friendList,
            }),
          });
          const friendList = await friendsResponse.json();
          setFriends(friendList);
        }
      }
      fetchFriends();
    }
  }, [activeProfile]);

  useEffect(() => {
    if (activeProfile) {
      async function fetchReceivedCards() {
        const response = await ApiService.fetchWithAuth(`/cards/received/${activeProfile.profileId}`);
        const cards = await response.json();
        setReceivedCards(cards);
      }
      fetchReceivedCards();
    }
  }, [activeProfile]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleClick = (album) => {
    if (isReward) {
      onRewardClicked(album);
    } else {
      navigate(album.route, { state: { album } });
    }
  };

  const getDoubleCards = () => {
    const doubleCards = currentCards.filter((card) => card.count > 1);
    return doubleCards.map((card) => {
      const album = AlbumData.find(album => album.cards.some(albumCard => albumCard.id === card.id));
      const albumCard = album.cards.find(albumCard => albumCard.id === card.id);
      return { ...card, name: albumCard.name, imageUrl: albumCard.imageUrl };
    });
  };

  return (
    <Stack spacing={2}>
      {!isReward && (<><Typography variant="h4" color={theme.palette.primary.main} sx={{fontFamily:"Softers"}} textAlign={"center"}>
        {`Ma collection`}
      </Typography>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="Mes albums" />
        <Tab label={getDoubleCards().length > 0 ? `Mes doubles (${getDoubleCards().length})` : "Mes doubles"} />
        <Tab label={receivedCards.length > 0 ? `Mes cartes reçues (${receivedCards.length})` : "Mes cartes reçues"} />

      </Tabs>
      </>)}
      {tabIndex === 0 && (
        <Grid container spacing={2} justifyContent="center">
          {AlbumData.map((album) => (
            <Grid item key={album.name}>
              <Card
                onClick={() => handleClick(album)}
                sx={{
                  maxWidth: isReward ? 200 : 345,
                  m: 2,
                  bgcolor: album.cardColor,
                  p: 2,
                  borderRadius: "16px",
                  boxShadow: "2px 01px 2px 2px rgba(0, 0, 0, .9)",
                  border: "4px solid #FCF7DF",
                  color: "#FCF7DF",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <CardMedia
                    component="img"
                    height="auto"
                    image={album.imageUrl}
                    alt="locked_card"
                    sx={{
                      my: "auto",
                      objectFit: "contain",
                      borderRadius: "40px",
                      border: "4px solid #FCF7DF",
                      mb: 2,
                    }}
                  />
                </Box>
                <CardContent
                  sx={{
                    bgcolor: "#FCF7DF",
                    height: "100%",
                    borderRadius: "16px",
                    color: album.cardColor,
                    mb: 1,
                    maxHeight: "80px",
                  }}
                >
                  <Box sx={{ color: album.cardColor, borderRadius: "4px" }}>
                    <Typography
                      variant={isReward ? "h7" : "h6"}
                      component="div"
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {album.name}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      {tabIndex === 1 && <DoubleCards doubleCards={getDoubleCards()} activeProfile={activeProfile} friends={friends} />}
      {tabIndex === 2 && <ReceivedCards receivedCards={receivedCards} friends={friends}/>}
    </Stack>
  );
};

export default AlbumList;
