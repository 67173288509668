import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Typography, Box } from '@mui/material';
import { useLocation } from "react-router-dom";
import ValidateButton from '../ValidateButton';

const HomophoneExercises = () => {

  useEffect(() => {
    document.title = 'Homophones';
  }, []);

  const location = useLocation();
  const exerciseParam = location.state?.exercise;
  const exerciseId = location.state?.exerciseId;
  const fromCreate = location.state?.fromCreate;
  const exerciseType = "homophoneExercise";
  const schoolSubject = location.state?.schoolSubject;


  const { homophoneFromPrompt, sentences } = exerciseParam;

  const shuffleArray = (array) => {
    let shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const [shuffledSentences, setShuffledSentences] = useState([]);
  const [choices, setChoices] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  useEffect(() => {
    const shuffled = shuffleArray(sentences);
    setShuffledSentences(shuffled);
    setChoices(shuffled.map(() => []));
  }, [sentences]);

  const handleChange = (sentenceIndex, wordIndex, value) => {
    const newChoices = [...choices];
    newChoices[sentenceIndex][wordIndex] = value;
    setChoices(newChoices);
  };

  const allSelected = () => {
    for (let sentenceIndex = 0; sentenceIndex < shuffledSentences.length; sentenceIndex++) {
      const words = shuffledSentences[sentenceIndex].split(' ');
      for (let wordIndex = 0; wordIndex < words.length; wordIndex++) {
        const cleanWord = words[wordIndex].replace(/[.,!?;:]/g, '');
        if (homophoneFromPrompt.includes(cleanWord.toLowerCase())) {
          if (!choices[sentenceIndex] || !choices[sentenceIndex][wordIndex]) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const handleValidation = () => {
    let allCorrect = true;
    for (let sentenceIndex = 0; sentenceIndex < shuffledSentences.length; sentenceIndex++) {
      const words = shuffledSentences[sentenceIndex].split(' ');
      for (let wordIndex = 0; wordIndex < words.length; wordIndex++) {
        const cleanWord = words[wordIndex].replace(/[.,!?;:]/g, ''); // remove punctuation
        const lowerCleanWord = cleanWord.toLowerCase(); // convert to lower case
        if (homophoneFromPrompt.includes(lowerCleanWord)) {
          const choice = choices[sentenceIndex]?.[wordIndex] || '';
          if (choice.toLowerCase() !== lowerCleanWord) {
            allCorrect = false;
            break;
          }
        }
      }
      if (!allCorrect) break;
    }
    setIsCorrect(allCorrect);
    setValidated(true);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        {`${exerciseParam.exerciseName || "Choisis le bon homophone."}`}
      </Typography>
      {shuffledSentences.map((sentence, sentenceIndex) => {
        const words = sentence.split(' ');
        return (
          <Box key={sentenceIndex} sx={{ marginBottom: 2 }}>
            {words.map((word, wordIndex) => {
              const cleanWord = word.replace(/[.,!?;:]/g, ''); // remove punctuation
              const lowerCleanWord = cleanWord.toLowerCase(); // convert to lower case
              if (homophoneFromPrompt.includes(lowerCleanWord)) {
                const choice = choices[sentenceIndex]?.[wordIndex] || '';
                const isChoiceCorrect = validated ? (choice.toLowerCase() === lowerCleanWord) : null;

                return (
                  <React.Fragment key={`${sentenceIndex}-${wordIndex}`}>
                    <Select
                      value={choice || ""}
                      onChange={(e) => handleChange(sentenceIndex, wordIndex, e.target.value)}
                      displayEmpty
                      sx={{
                        margin: '0 8px',
                        color: isChoiceCorrect === null ? 'inherit' : isChoiceCorrect ? 'green' : 'red'
                      }}
                    >
                      {homophoneFromPrompt.map((homophone, i) => (
                        <MenuItem
                          key={i}
                          value={homophone}
                          sx={{ color: validated && homophone.toLowerCase() === lowerCleanWord ? 'green' : (validated && choice.toLowerCase() === homophone.toLowerCase() ? 'red' : 'inherit') }}
                        >
                          {homophone}
                        </MenuItem>
                      ))}
                    </Select>
                    {word.replace(cleanWord, '')} {/* add back punctuation */}
                  </React.Fragment>
                );
              } else {
                return (
                  <Typography variant="body1" component="span" key={`${sentenceIndex}-${wordIndex}`}>
                    {word}{' '}
                  </Typography>
                );
              }
            })}
          </Box>
        );
      })}
      <ValidateButton exerciseType={exerciseType} schoolSubject={schoolSubject} exerciseJson={exerciseParam} enableValidate={allSelected()} validate={validated} isCorrect={isCorrect} fromCreate={fromCreate} handleValidate={handleValidation} exerciseId={exerciseId} />
    </Box>
  );
};
export default HomophoneExercises;