import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Rating,
  Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import QuestService from "../features/quest/QuestService";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import SharedExerciseService from "../features/sharedExercise/SharedExerciseService";
import SchoolSubject from '../data/SchoolSubject';
import structure from "../data/Structure";
import ReactGA from 'react-ga4';
const GradeLevel = structure.map(item => item.schoolLevel);


const ValidateButton = ({
  enableValidate = true,
  exerciseType,
  exerciseJson,
  validate,
  handleValidate,
  isCorrect,
  fromCreate,
  exerciseId,
  schoolSubject
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [showFireworks, setShowFireworks] = useState(false);
  const [openNewLessonModal, setOpenNewLessonModal] = useState(false);
  const [newLessonName, setNewLessonName] = useState("");
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [error, setError] = useState(null);
  const [ratingError, setRatingError] = useState(null);
  const [reportError, setReportError] = useState(null);
  const [openRatingModal, setOpenRatingModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [rating, setRating] = useState(0);

  useEffect(() => {
    if (validate) {
      setOpenDialog(true);
      if (isCorrect) {
        QuestService.markQuestAsComplete(
          { weeklyQuestId: `weekly-exercise-success-${schoolSubject}` },
          activeProfile,
          dispatch
        );
        // Affiche l'animation de feux d'artifice pendant un temps déterminé
        setShowFireworks(true);
      }
    }
  }, [validate, isCorrect]);

  useEffect(() => {
    if (exerciseJson && exerciseJson.exerciseName) {
      setNewLessonName(exerciseJson.exerciseName);
    } else if (exerciseJson && exerciseJson.title) {
      setNewLessonName(exerciseJson.title);
    }
  }, [exerciseJson]);

  useEffect(() => {
    if (!openDialog) {
      setShowFireworks(false);
    }
  }, [openDialog]);
  const navigate = useNavigate();
  const activeProfile = useSelector(
    (state) => state?.userInfos?.activeProfile,
    shallowEqual
  );
  const dispatch = useDispatch();

  const handleSelectGrade = (e) => {
    setSelectedGrade(e.target.value);
  };

  const handleSelectSubject = (e) => {
    setSelectedSubject(e.target.value);
  };

  const handleCloseNewLesson = () => {
    setOpenNewLessonModal(false);
    setError(null);
  }

  const handleReportExercise = async (exerciseId) => {
    try {
      const response = await SharedExerciseService.report(
        exerciseId,
        activeProfile.profileId,
        reportReason
      );
      if (response.ok) {
        ReactGA.event("exercise_reported", {
          label: window.location.pathname,
          category: `exercise`
        });
        setOpenReportModal(false);
      } else {
        if (response.status === 400) {
          setReportError(
            "Vous avez déjà signalé cet exercice"
          );
        } else {
          setReportError("Une erreur inconnue est survenue");
        }
      }
    } catch (error) {
      console.error('Error submitting rating:', error);
      setReportError("Une erreur inconnue est survenue");
    }
  }

  const handleSubmitRating = async () => {
    try {
      const response = await SharedExerciseService.rate(
        exerciseId,
        rating,
        activeProfile.profileId,
      );
      if (response.ok) {
        QuestService.markQuestAsComplete(
          { weeklyQuestId: `weekly-rate` },
          activeProfile,
          dispatch
        );
        ReactGA.event("exercise_noted", {
          label: window.location.pathname,
          category: `exercise`
        });
        setOpenRatingModal(false);
      } else {
        if (response.status === 400) {
          setRatingError(
            "Vous avez déjà noté cet exercice"
          );
        } else {
          setRatingError("Une erreur inconnue est survenue");
        }
      }
    } catch (error) {
      console.error('Error submitting rating:', error);
      setRatingError("Une erreur inconnue est survenue");
    }
  };

  const handleShareExercice = async (lesson) => {
    const response = await SharedExerciseService.add(
      {
        exerciseJson: exerciseJson,
        lesson: lesson,
        profileId: activeProfile.profileId,
      },
      exerciseType
    );
    if (!response.ok) {
      setError("Une erreur inconnue est survenue");
    } else {
      ReactGA.event("exercise_shared", {
        label: window.location.pathname,
        category: `exercise`
      });
      setOpenNewLessonModal(false);
      setError(null);
      QuestService.markQuestAsComplete(
        { weeklyQuestId: `weekly-create-${exerciseType}` },
        activeProfile,
        dispatch
      );
      navigate("/");
    }

  };

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      spacing={2}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!validate && handleValidate && (
        <Button disabled={!enableValidate} variant="contained" color="success" onClick={() => {
          ReactGA.event("exercise_validated", {
            label: window.location.pathname,
            category: `exercise`
          });
          handleValidate()
        }}
        >
          Valider
        </Button>
      )}

      {!validate && fromCreate && !handleValidate && (
        <Button variant="contained" disabled={!activeProfile} onClick={() => setOpenNewLessonModal(true)}>
          Partager
        </Button>
      )}
      {!validate && !fromCreate && !handleValidate && (
        <Button variant="contained" color="success" disabled={!activeProfile} onClick={() => setOpenRatingModal(true)}>
          Noter
        </Button>
      )}


      {validate && (
        <Box>
          <Dialog
            open={openDialog}
            onClose={() => {
              setOpenDialog(false);
              if (!fromCreate && activeProfile) {
                setOpenRatingModal(true);
              }
            }}
          >
            <DialogTitle
              sx={{ color: isCorrect ? "success.main" : "error.main" }}
            >
              {isCorrect ? "Bravo" : "Raté"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {isCorrect
                  ? "Bravo, tu as toutes les bonnes réponses."
                  : "Bien essayé, mais il y a des erreurs. Regarde bien la correction."}
                <br />
                {fromCreate ? "Si tu trouves cet exercice correct et pertinent, tu peux maintenant le partager avec tes amis ou la communauté via le bouton « Partager », situé en bas de l'exercice." : ""}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenDialog(false);
                  if (!fromCreate && activeProfile) {
                    setOpenRatingModal(true);
                  }
                }}
                color="primary"
                autoFocus
              >
                Fermer
              </Button>
            </DialogActions>
          </Dialog>
          {showFireworks && (
            <div className="firework-container">
              <div className="firework"></div>
              <div class="firework"></div>
              <div class="firework"></div>
            </div>
          )}
          {fromCreate && (
            <Button variant="contained" disabled={!activeProfile} onClick={() => setOpenNewLessonModal(true)}>
              Partager
            </Button>
          )}
        </Box>
      )}


      <Dialog
        open={openNewLessonModal}
        onClose={() => handleCloseNewLesson()}
      >
        <DialogTitle>
          Partage ton exercice:
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              margin="normal"
              id="newTheme"
              type="text"
              variant="outlined"
              color="secondary"
              label="Nom du sujet"
              fullWidth
              required
              value={newLessonName}
              onChange={(e) => setNewLessonName(e.target.value)}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="gradeLevel-label">Choisissez une classe</InputLabel>
              <Select
                labelId="gradeLevel-label"
                name="gradeLevel"
                value={selectedGrade || ""}
                label="Choisissez une classe"
                onChange={handleSelectGrade}
              >
                {GradeLevel.map(
                  (gradeLevel) => (<MenuItem key={`gradeLevelFilter-${gradeLevel}`} value={gradeLevel}>{gradeLevel}</MenuItem>))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 4 }} margin="normal">
              <InputLabel id="schoolSubject-label">Choisissez une matière</InputLabel>
              <Select
                labelId="schoolSubject-label"
                name="schoolSubject"
                label="Choisissez une matière"
                value={selectedSubject || ""}
                onChange={handleSelectSubject}
              >
                {SchoolSubject.map(
                  (schoolSubject) => (<MenuItem key={`schoolSubjectFilter-${schoolSubject.name}`} value={schoolSubject.name}>{schoolSubject.name}</MenuItem>))}
              </Select>
            </FormControl>

          </DialogContentText>
          {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={() => handleCloseNewLesson()}>
            Annuler
          </Button>
          <Button
            variant="contained" color="success"
            onClick={() => {
              if (!selectedGrade || !selectedSubject) {
                setError("Veuillez sélectionner une classe et une matière.");
                return;
              }
              handleShareExercice({
                gradeLevel: selectedGrade,
                schoolSubject: selectedSubject,
                lessonName: newLessonName,
              })
            }
            }
          >
            Ajouter
          </Button>
        </DialogActions>
        
      </Dialog>

      <Dialog
        open={openRatingModal}
        onClose={() => setOpenRatingModal(false)}
      >
        <DialogTitle>Notez cet exercice</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Rating
              name="exercise-rating"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
            />
            {ratingError && (
              <Typography variant="body2" color="error">
                {ratingError}
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button
            variant="contained"
            color="success"
            onClick={handleSubmitRating}
          >
            Soumettre
          </Button>
          <Button variant="contained" color="error" onClick={() => setOpenRatingModal(false)}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openReportModal}
        onClose={() => setOpenReportModal(false)}
      >
        <DialogTitle>Signaler cet exercice</DialogTitle>

        <DialogContent>
          <TextField
            margin="normal"
            label="Raison du signalement"
            variant="outlined"
            multiline
            rows={4}
            value={reportReason}
            onChange={(e) => setReportReason(e.target.value)}
            fullWidth
          />
          {reportError && (
            <Typography variant="body2" color="error">
              {reportError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpenReportModal(false)}>
            Annuler
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleReportExercise(exerciseId)}
          >
            Signaler
          </Button>
        </DialogActions>
      </Dialog>

      {!fromCreate && validate && (
        <Button variant="contained" color="success" disabled={!activeProfile} onClick={() => setOpenRatingModal(true)}>
          Noter
        </Button>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          navigate(-1);
        }}
      >
        Retour
      </Button>
      {!fromCreate && <Button
        variant="contained"
        color="error"
        disabled={!activeProfile}
        onClick={() => {
          setOpenReportModal(true)
        }}
      >
        Signaler
      </Button>
      }

    </Stack>
  );
};

export default ValidateButton;
