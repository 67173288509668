import trexImg from "./img/trex.jpg"
import velociraptorImg from "./img/velociraptor.jpg"
import triceratopsImg from "./img/triceratops.jpg"
import stegosaurusImg from "./img/stegosaurus.jpg"
import brachiosaurusImg from "./img/brachiosaurus.jpg"


const DinausaurData = [
    {
        id:"album-dinausaur-trex",
        number:1,
        name: "Tyrannosaurus Rex",
        period: "Crétacé supérieur, il y a environ 68 à 66 millions d'années",
        size: "Environ 12 mètres de long et 4 mètres de haut",
        diet: "Carnivore",
        imageUrl: trexImg, 
      },
      {
        id:"album-dinausaur-velociraptor",
        number:2,
        name: "Velociraptor",
        period: "Crétacé supérieur, il y a environ 75 à 71 millions d'années",
        size: "Environ 2 mètres de long et 0,5 mètres de haut",
        diet: "Carnivore",
        imageUrl: velociraptorImg, 
      },
      {
        id:"album-dinausaur-triceratops",
        number:3,
        name: "Triceratops",
        period: "Crétacé supérieur, il y a environ 68 à 66 millions d'années",
        size: "Environ 9 mètres de long et 3 mètres de haut",
        diet: "Herbivore",
        imageUrl: triceratopsImg, 
      },
      {
        id:"album-dinausaur-stegosaurus",
        number:4,
        name: "Stegosaurus",
        period: "Jurassique supérieur, il y a environ 155 à 150 millions d'années",
        size: "Environ 9 mètres de long et 4 mètres de haut",
        diet: "Herbivore",
        imageUrl: stegosaurusImg,
      },
      {
        id:"album-dinausaur-brachiosaurus",
        number:5,
        name: "Brachiosaurus",
        period: "Jurassique supérieur, il y a environ 154 à 150 millions d'années",
        size: "Environ 22 mètres de long et 12 mètres de haut",
        diet: "Herbivore",
        imageUrl: brachiosaurusImg,
      }
  ]
export default DinausaurData;