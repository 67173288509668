import React, { useState, useEffect } from "react";
import { Grid, TextField, Typography, Stack, Divider, IconButton, Card, CardContent, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const AdditionComponent = ({ numberA, numberB, index = 0, onValidated, triggerCorrection }) => {
    
    useEffect(() => {
        document.title = "Feuille d'opérations: additions";
      }, []);

    const nbDigits = Math.max(numberA.toString().length, numberB.toString().length);

    const [result, setResult] = useState({
        result: Array(nbDigits + 1).fill(''),
        retenue: Array(nbDigits + 1).fill('')
    });

    const [correction, setCorrection] = useState(null);
    const [showRetenue, setShowRetenue] = useState(true);

    const handleChange = (digitIndex, value, field) => {
        const newResult = { ...result };
        newResult[field][digitIndex] = value;
        setResult(newResult);
    };

    const handleAddRetenue = (digitIndex) => {
        const newResult = { ...result };
        newResult.retenue[digitIndex] = (parseInt(newResult.retenue[digitIndex] || '0') + 1).toString();
        setResult(newResult);
    };

    const handleRemoveRetenue = (digitIndex) => {
        const newResult = { ...result };
        const currentRetenue = parseInt(newResult.retenue[digitIndex] || '0');
        newResult.retenue[digitIndex] = "";
        setResult(newResult);
    };

    const handleCorrection = () => {
        const correctResult = `${numberA + numberB}`;
        const userResult = result.result.join('');
        const isCorrect = Number(userResult) === Number(correctResult);
        setCorrection({
            correct: isCorrect,
            expected: correctResult,
        });
        onValidated(isCorrect);
    };

    useEffect(() => {
        if (triggerCorrection) {
            handleCorrection();
        }
    }, [triggerCorrection]);

    return (
        <Card elevation={5} display="flex">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Stack direction="row" spacing={1} justifyContent="right">
                            <Tooltip title={showRetenue ? "Masquer les retenues" : "Afficher les retenues"}>
                                <IconButton size="small" onClick={() => setShowRetenue(!showRetenue)}>
                                    {showRetenue ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                                </IconButton>
                            </Tooltip>
                            {showRetenue && result.retenue.map((retenue, i) => (
                                <Stack key={`retenue-${index}-${i}`} direction="column" spacing={1} alignItems="center">
                                    {!retenue && (i<(result.retenue.length-1)) && <IconButton size="small" onClick={() => handleAddRetenue(i)}>
                                        <AddIcon fontSize="small" />
                                    </IconButton>}
                                    {retenue && <IconButton size="small" onClick={() => handleRemoveRetenue(i)}>
                                        <RemoveIcon fontSize="small" />
                                    </IconButton>}
                                    <Typography
                                    textAlign={"center"}
                                        style={{ lineHeight:"32px", width: '40px' }}
                                    >
                                        {retenue}
                                    </Typography>
                                    
                                </Stack>
                            ))}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Stack direction="row" spacing={1} justifyContent="right">
                            {numberA.toString().padStart(nbDigits, '0').split('').map((number, i) => (
                                <TextField
                                    key={`calcul${index}-a-${i}`}
                                    variant="outlined"
                                    size="small"
                                    value={number}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={{ width: '40px' }}
                                />
                            ))}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Stack direction="row" spacing={1} justifyContent="right">
                            <Typography variant="h6">+</Typography>
                            {numberB.toString().padStart(nbDigits, '0').split('').map((number, i) => (
                                <TextField
                                    key={`calcul${index}-b-${i}`}
                                    variant="outlined"
                                    size="small"
                                    value={number}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={{ width: '40px' }}
                                />
                            ))}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Divider sx={{ borderWidth: 1, borderColor: "#3e3e3e" }} />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Stack direction="row" spacing={1} justifyContent="right">
                            {result.result.map((digit, i) => (
                                <TextField
                                    autoComplete="off"
                                    key={`calcul${index}-res-${i}`}
                                    variant="outlined"
                                    size="small"
                                    value={digit}
                                    onChange={(e) => handleChange(i, e.target.value, 'result')}
                                    style={{ width: '40px' }}
                                />
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
                {correction && (
                    <Typography sx={{ mt: 2 }} textAlign={"right"} color={correction.correct ? 'green' : 'red'}>
                        {correction.correct ? 'Correct' : `Incorrect, la réponse correcte est ${correction.expected}`}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default AdditionComponent;
