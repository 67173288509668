import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Stack,
  Slider,
  Backdrop,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import ValidateButton from "../ValidateButton";
import ApiService from "../../ApiService";

const Dictee = () => {

  useEffect(() => {
    document.title = 'Dictée';
  }, []);

  const location = useLocation();
  const exerciseParam = location.state?.exercise;
  const template = location.state?.template;
  const exerciseId = location.state?.exerciseId;
  const fromCreate = false;
  const [responseText, setResponseText] = useState("");
  const [playbackRate, setPlaybackRate] = useState(1);
  const [speechSegments, setSpeechSegments] = useState([]);
  const [currentSpeechIndex, setCurrentSpeechIndex] = useState(0);
  const exerciseType = "dictee";
  const [validate, setValidate] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [corrections, setCorrections] = useState(null);

  const prepareSpeechSegments = (text) => {
    return text.match(/[^\.!\?]+[\.!\?]+/g) || [];
  };

  const speak = (segments, index) => {
    if (index < segments.length) {
      const speech = new SpeechSynthesisUtterance(segments[index]);
      speech.pitch = 1;
      speech.rate = playbackRate;
      let voices = window.speechSynthesis.getVoices();
      let frenchVoice = voices.find((voice) => voice.lang === "fr-FR");

      if (frenchVoice) {
        speech.voice = frenchVoice;
      }

      window.speechSynthesis.cancel(); // Stop any existing speech before starting new
      window.speechSynthesis.speak(speech);
    }
  };

  const handleStart = () => {
    const segments = prepareSpeechSegments(exerciseParam.dictee);
    setSpeechSegments(segments);
    setCurrentSpeechIndex(0);
    speak(segments, 0);
  };

  const handleRetry = () => {
    speak(speechSegments, currentSpeechIndex);
  };

  const nextSentence = () => {
    if (currentSpeechIndex < speechSegments.length - 1) {
      speak(speechSegments, currentSpeechIndex + 1);
      setCurrentSpeechIndex(currentSpeechIndex + 1);
    }
  };

  const handleChangePlaybackRate = (event, newValue) => {
    setPlaybackRate(newValue);
    speak(speechSegments, currentSpeechIndex); // Restart speaking with new rate
  };

  const validateResponse = async () => {
    try {
      setIsLoading(true);

      const prompForValidation = `Texte correct de la dictée: ${exerciseParam.dictee}. Texte de l'enfant:: ${responseText}.`;
      const templateForValidation = {
        prompt: template.prompt.replace(
          "{userResponseJson}",
          prompForValidation
        ),
        json: template.templateJsonForValidation,
        iaEngine: template.iaEngine,
      };

      const response = await ApiService.fetchWithAuth("/ai/ask", {
        method: "POST",
        body: JSON.stringify(templateForValidation),
      });
      if (response.ok) {
        const validationResponse = await response.json();
        setCorrections(validationResponse.corrections);
      } else {
        console.error("Une erreur est survenue lors de l'appel à l'API");
      }
    } catch (error) {
      console.error("Erreur lors de l'appel à l'API:", error);
    } finally {
      setIsLoading(false); // Arrête le chargement
    }
  };

  const formattedCorrection = () => {
    const dictWords = exerciseParam.dictee.split(/\s+/);
    const responseWords = responseText.split(/\s+/);
    const correctionsDict = new Map(
      corrections.map((c) => [c.correct.toLowerCase(), c])
    );

    let correctedText = dictWords
      .map((word, i) => {
        const correction = correctionsDict.get(
          word.replaceAll(".", "").replaceAll(",", "").toLowerCase()
        );
        const responseWord = responseWords[i];
        if (correction) {
          if (correction.correct !== responseWord) {
            // Mot incorrect, utilisez 'correct' pour le montrer en rouge
            return `<span style="color: red;">${word}</span>`;
          }
        }
        if (!responseWord) {
          // Mot manquant
          return `<span style="color: red;">${word}</span>`;
        }
        // Mot correct
        return `<span style="color: green;">${word}</span>`;
      })
      .join(" ");

    return { __html: correctedText };
  };

  return (
    <Stack spacing={3}>
      {/* Ajoute l'indicateur de chargement ici */}
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Stack spacing={2} direction="row">
        <Button variant="contained" color="primary" onClick={handleStart}>
          Écouter depuis la première phrase
        </Button>
        {speechSegments.length > 0 &&
          currentSpeechIndex < speechSegments.length - 1 && (
            <Button variant="contained" color="primary" onClick={nextSentence}>
              Phrase Suivante
            </Button>
          )}
        {currentSpeechIndex > 0 && (
          <Button variant="contained" color="secondary" onClick={handleRetry}>
            Réécouter la phrase
          </Button>
        )}
      </Stack>
      <Typography variant="h5" component="div">
        Vitesse de lecture:
      </Typography>
      <Slider
        min={0.5}
        max={2}
        step={0.1}
        value={playbackRate}
        onChange={handleChangePlaybackRate}
        aria-labelledby="playback-rate-slider"
        valueLabelDisplay="auto"
      />
      <TextField
        label="Ecrire la dictée"
        multiline
        rows={15}
        value={responseText}
        onChange={(e) => setResponseText(e.target.value)}
        variant="outlined"
        fullWidth
        InputProps={{
          autocorrect: "off",
          spellCheck: "false", // Désactive la vérification orthographique
          autoComplete: "off", // Désactive l'autocomplétion
        }}
      />
      {corrections && <div dangerouslySetInnerHTML={formattedCorrection()} />}
      {corrections && (
        <Typography variant="h6" component="div">
          Les erreurs:
          <List>
            {corrections.map((correction, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`Erreur: "${correction.incorrect}" | Correction: "${correction.correct}"`}
                  secondary={correction.explanation}
                />
              </ListItem>
            ))}
          </List>
        </Typography>
      )}
      <ValidateButton
        exerciseType={exerciseType}
        exerciseJson={exerciseParam}
        exerciseId={exerciseId}
        validate={validate}
        isCorrect={isCorrect}
        fromCreate={fromCreate}
        handleValidate={validateResponse}
      />
    </Stack>
  );
};

export default Dictee;
