
const TipsData = [
  {
    "id":"tip-welcome",
    "text": "Bonjour, je me présente, je m'appelle Exercilio. Mes amis m'appellent Exo. Pour te souhaiter la bienvenue, je vais t'offrir une carte à collectionner. Mais avant cela, j'ai besoin que tu commences par créer ton profil.",
    "button": "D'accord",
    "completedText": "Bravo tu as réussi ta première mission, tu peux choisir ton premier paquet de cartes à ouvrir",
    "route": "/profile/edit"
  },
  {
    "id":"tip-challenge",
    "text": "Tu peux maintenant gagner de nouvelles cartes en réalisant des challenges quotidiens.",
    "button": "D'accord",
    "route": "/quest/list",
    "autoValidate":true
  }
]

export default TipsData;