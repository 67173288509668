import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from "@mui/material";
import LadderService from "./LadderService";
import { useSelector, shallowEqual } from "react-redux";
import SkinBox from "../../components/SkinBox";

function Ladder() {

  useEffect(() => {
    document.title = 'Classement';
  }, []);

  const [ranking, setRanking] = useState([]);
  const [myRank, setMyRank] = useState(null);

  const activeProfile = useSelector(
    (state) => state?.userInfos?.activeProfile,
    shallowEqual
  );

  useEffect(() => {
    async function fetchData() {
      const response = await LadderService.getList("0");
      if(response.ok){
        const ranking = await response.json();
        setRanking(ranking);
      }
      
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (activeProfile) {
      setMyRank(ranking.findIndex((rank) => rank.profileId === activeProfile.profileId) + 1);
    }
  }, [activeProfile, ranking]);

  return (
    <SkinBox
      style={{
        width: "100%",
        float: "left",
        color: "#FFFFFF",
        backgroundColor: "#000000",
        display: "flex",
        flexDirection: "column", // Pour permettre aux enfants de s'aligner verticalement
        alignItems: "center", // Centre horizontalement le contenu dans le conteneur
        height: "90vh", // Ajustez selon le besoin, peut-être basé sur le viewport
        overflow: "hidden", // Empêche le débordement au niveau du conteneur principal
      }}
    >
      <Typography
        variant="h4"
        sx={{
          borderBottom: 1,
          borderColor: "highlight.main",
          mb: 2,
          display: "flex",
          alignItems: "center",
          py: 2,
        }}
      >
        CLASSEMENT
      </Typography>
      <Box sx={{ maxHeight: "80vh", overflowY: "auto" }}>
        {(myRank >
          0) && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                bgcolor: "accent.main",
                p: 3,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography>Ton classement</Typography>
              <Typography>{`#${myRank}`}</Typography>
            </Box>
          )}{(myRank <=
            0) && (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  bgcolor: "accent.main",
                  p: 3,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Pour avoir un classement tu dois partager des exercices à la communauté, en allant dans la partie "créer" de l'application.</Typography>
              </Box>
            )}
        <TableContainer>
          <Table sx={{ width: "100%" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "white", fontSize: "24px" }}>
                  #
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: "24px" }}
                  align="left"
                >
                  Pseudo
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: "24px" }}
                  align="right"
                >
                  Score
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Exemple de lignes de table, répétez pour chaque entrée de votre tableau de données */}
              {ranking.map((rank, index) => (
                <TableRow key={"ladder-row-"+index}>
                  <TableCell
                    style={{ color: "white" }}
                    component="th"
                    scope="row"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    style={{ color: "white", fontSize: "18px" }}
                    align="left"
                  >
                    {rank.profileId}
                  </TableCell>
                  <TableCell
                    style={{ color: "white", fontSize: "18px" }}
                    align="right"
                  >
                    {rank.points}
                  </TableCell>
                </TableRow>
              ))}
              {/* Ajoutez d'autres lignes ici */}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </SkinBox>
  );
}

export default Ladder;
