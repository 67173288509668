import APIService from "../../ApiService";

const SharedExerciseService = {
  add: async function (
    params,
    exerciseType,
  ) {
    return await APIService.fetchWithAuth(`/shared/${exerciseType}/add`,
      {
        method: "POST",
        body: JSON.stringify(params),
      }
    );
  },
  getList: async function (filters) {
    return await APIService.fetchWithAuth(`/exercises/search`, {
      method: "POST",
      body: JSON.stringify(filters),
    });
  },
  del: async function (
    sharedExerciseId
  ) {
    return await APIService.fetchWithAuth(`/shared/del/${sharedExerciseId}`,
      {
        method: "POST",
      }
    );
  },
  rate: async function (
    exerciseId,
    rating,
    profileId
  ) {
    return await APIService.fetchWithAuth(`/shared/rate`,
      {
        method: "POST",
        body: JSON.stringify({ exerciseId: exerciseId, rating: rating, profileId: profileId }),
      }
    );
  },
  report: async function (
    exerciseId,
    profileId,
    reason
  ) {
    return await APIService.fetchWithAuth(`/shared/report`,
      {
        method: "POST",
        body: JSON.stringify({ exerciseId: exerciseId, reason: reason, profileId: profileId }),
      }
    );
  },
  listReports: async function (
    exerciseId,
  ) {
    return await APIService.fetchWithAuth(`/shared/reports/${exerciseId}`,
      {
        method: "GET",
      }
    );
  },
  updateExercise: async function (
    exerciseId,
    exercise
  ) {
    return await APIService.fetchWithAuth(`/shared/update/${exerciseId}`, {
      method: "PUT",
      body: JSON.stringify(exercise),
    });
  },
  reviewExercise: async function (exerciseId, isAccepted) {
    return await APIService.fetchWithAuth(`/shared/review/${exerciseId}`, {
      method: "POST",
      body: JSON.stringify({ isAccepted }),
    });
  },
};

export default SharedExerciseService;
