import React, { useState, useEffect } from 'react';
import { Button, FormControl, InputLabel, Select, MenuItem, Avatar, Dialog, Stack, Modal, Box } from '@mui/material';
import { SketchPicker } from 'react-color';


let colorChangeCallback = null;
let previousColor = null;

function ColorPickerDialog({ open, handleClose, handleColorChange, currentColor}) {
  const [color, setColor] = useState(`#${currentColor}`);

  const handleChangeComplete = (color) => {
    setColor(color.hex);
    handleColorChange(color.hex.replace('#',""));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
    </Dialog>
  );
}




const AvatarGenerator = ({defaultImage, onAvatarUrlChange}) => {
  const [isModalEditorOpen, setIsModalEditorOpen] = useState(false);
  function handleOpenModal(){
    setIsModalEditorOpen(!isModalEditorOpen);
  }

  /*const [backgroundColor, setBackgroundColor] = useState('');
  const [accessories, setAccessories] = useState('');
  const [clothesColor, setClothesColor] = useState('');*/
  //const [seed, setSeed] = useState('');
  const [skinColor, setSkinColor] = useState('ffffff');
  const [urlAvatar, setUrlAvatar] = useState('');


  let queryParams = null;
  if(defaultImage){
    const url = new URL(defaultImage || '');
    queryParams = url.searchParams;
  }
  const [backgroundColor, setBackgroundColor] = useState(queryParams?.get('backgroundColor') || 'ffffff');
  const [clothesColor, setClothesColor] = useState(queryParams?.get('clothesColor'), 'ffffff');
  const [eyes, setEyes] = useState(queryParams?.get('eyes') || '');
  const [hairColor, setHairColor] = useState(queryParams?.get('hairColor') || '');
  const [top, setTop] = useState(queryParams?.get('top') || '');
  const [mouth, setMouth] = useState(queryParams?.get('mouth') || '');



  const [open, setOpen] = useState(false);

  const handleOpen = (fctColorChange, color) => {
    colorChangeCallback = fctColorChange;
    previousColor = color
    return setOpen(true);
  }
  const handleClose = () => setOpen(false);



  
  useEffect(() => {
    let url = `https://api.dicebear.com/7.x/avataaars/svg?`;
    //if (seed) url += `&seed=${encodeURIComponent(seed)}`;
    if (skinColor) url += `&skinColor=${encodeURIComponent(skinColor)}`;
    if (backgroundColor) url += `&backgroundColor=${encodeURIComponent(backgroundColor)}`;
    if (clothesColor) url += `&clothesColor=${encodeURIComponent(clothesColor)}`;
    if (eyes) url += `&eyes=${encodeURIComponent(eyes)}`;
    if (hairColor) url += `&hairColor=${encodeURIComponent(hairColor)}`;
    if (top) url += `&top=${encodeURIComponent(top)}`;
    if (mouth) url += `&mouth=${encodeURIComponent(mouth)}`;

    /*if (backgroundColor) url += `&backgroundColor=${encodeURIComponent(backgroundColor)}`;
    if (accessories) url += `&accessories=${encodeURIComponent(accessories)}`;
    if (clothesColor) url += `&clothesColor=${encodeURIComponent(clothesColor)}`;*/
    setUrlAvatar(url);
    onAvatarUrlChange(url);

  }, [onAvatarUrlChange, backgroundColor, skinColor,clothesColor,eyes, hairColor, top, mouth ]);

  return (
    <div>
      <Stack direction={"row"} spacing={2}>
        <Avatar sx={{width: '48px', height: '48px'}} src={urlAvatar} alt="React Logo"/>
        <Button variant="contained" color="primary" onClick={()=>{handleOpenModal()}}>Editer</Button>
      </Stack>
      <Modal
          open = {isModalEditorOpen}
          onClose={()=>{}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box 
            width={{
              sm: "80vw",
              xs:"100vw",
            }} 
            height={{
              sm: "auto",
              xs:"100vh",
            }} 
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              border: '2px solid #000',
              boxShadow: 24,
              padding: "20px",
              overflow: "auto"
            }}>
            <Stack spacing={2}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar sx={{width: '64px', height: '64px'}} src={urlAvatar} alt="React Logo"/>
              </Box>
              <Button variant="outlined"onClick={() =>handleOpen(setSkinColor, skinColor)}>Choisir la couleur de peau</Button>
              <FormControl fullWidth margin="normal">
                <InputLabel>Choix des yeux</InputLabel>
                <Select
                  name="eyes"
                  label="Choix des yeux"
                  value={eyes}
                  onChange={(e)=>setEyes(e.target.value)}
                >
                  <MenuItem value="closed">Fermés</MenuItem>
                  <MenuItem value="cry">Pleur</MenuItem>
                  <MenuItem value="default">Normaux</MenuItem>
                  <MenuItem value="hearts">Coeurs</MenuItem>
                  <MenuItem value="happy">Content</MenuItem>
                  <MenuItem value="surprised">Surpris</MenuItem>
                  <MenuItem value="wink">Clin d'oeil</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <InputLabel>Choix des cheveux</InputLabel>
                <Select
                  name="top"
                  label="Choix des cheveux"
                  value={top}
                  onChange={(e)=>setTop(e.target.value)}
                >
                  <MenuItem value="bigHair">Long cheveux</MenuItem>
                  <MenuItem value="bob">Carré</MenuItem>
                  <MenuItem value="bun">Attachés</MenuItem>
                  <MenuItem value="dreads">Dreads</MenuItem>
                  <MenuItem value="frizzle">Mèche</MenuItem>
                  <MenuItem value="hat">Chapeau</MenuItem>
                  <MenuItem value="shortFlat">Courts</MenuItem>
                  <MenuItem value="sides">Dégarnis</MenuItem>
                </Select>
              </FormControl>
              <Button variant="outlined" onClick={() =>handleOpen(setHairColor, hairColor)}>Choisir la couleur des cheveux</Button>

              <FormControl fullWidth margin="normal">
                <InputLabel>Choix de la bouche</InputLabel>
                <Select
                  name="top"
                  label="Choix des cheveux"
                  value={mouth}
                  onChange={(e)=>setMouth(e.target.value)}
                >
                  <MenuItem value="default">Normal</MenuItem>
                  <MenuItem value="sad">Triste</MenuItem>
                  <MenuItem value="screamOpen">Effrayé</MenuItem>
                  <MenuItem value="serious">Sérieux</MenuItem>
                  <MenuItem value="tongue">Langue</MenuItem>
                </Select>
              </FormControl>

              
              <Button variant="outlined" onClick={() =>handleOpen(setBackgroundColor, backgroundColor)}>Choisir la couleur de fond</Button>
              <Button variant="outlined" onClick={() =>handleOpen(setClothesColor, clothesColor)}>Choisir la couleur des vêtements</Button>
              
              <ColorPickerDialog open={open} handleClose={handleClose} handleColorChange={(color)=> colorChangeCallback(color)} currentColor={previousColor}/>
              <Button variant="contained" color="success"onClick={() =>handleOpenModal()}>Valider</Button>
            </Stack>
          </Box>
        </Modal>
    </div>
  );
};

export default AvatarGenerator;