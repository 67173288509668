import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import AlbumCard from "./AlbumCard";
import LockedCard from "./LockedCard";
import { useLocation } from "react-router-dom";
import { useSelector,shallowEqual } from "react-redux";

const Album = () => {
  const location = useLocation();
  const album = location.state.album;
  const [cards, setCards] = useState([]);
  const currentCards = useSelector((state) => state?.userInfos?.activeProfile?.cards || [], shallowEqual);

  useEffect(()=>{
    if(currentCards && currentCards.length){
      setCards(album.cards);
    }
  },[currentCards, album.cards]);

  return (
    <>
      <Grid container spacing={2} justifyContent={{xs:"center", md:"left"}}>
        {cards.map((card) => (
          <Grid item key={card.name}>
            {currentCards.find((myCard) => myCard.id === card.id) ? (
              <AlbumCard card={card} album={album} count={currentCards.find((myCard) => myCard.id === card.id).count} />
            ) : (
              //<AlbumCard card={card} album={album} />
              <LockedCard album={album} cardNumber={card.number}/>
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Album;
