import pyramidImg from "./img/gizeh.png";
import petraImg from "./img/petra.png";
import hangingGardenImg from "./img/hangingGarden.png";
import chichenItzaImg from "./img/chichenItza.png";
import zeusStatueImg from "./img/zeusStatue.png";
import artemisTempleImg from "./img/artemisTemple.png";
import mausoleumImg from "./img/mausoleum.png";
import colossusImg from "./img/colossus.png";
import lighthouseImg from "./img/lighthouse.png";
import greatWallImg from "./img/greatWall.png";
import christRedeemerImg from "./img/christRedeemer.png";
import machuPicchuImg from "./img/machuPicchu.png";
import colosseumImg from "./img/colosseum.png";
import tajMahalImg from "./img/tajMahal.png";

const WonderData = [
  {
    id: "album-wonder-pyramid",
    number: 1,
    name: "La Grande Pyramide de Gizeh",
    status: "Monde antique",
    area: "Égypte",
    description:
      "La seule des sept merveilles antiques encore existante, construite comme tombeau pour le pharaon Khufu.",
    imageUrl: pyramidImg,
  },
  {
    id: "album-wonder-hangingGarden",
    number: 2,
    name: "Les Jardins suspendus de Babylone",
    status: "Monde antique",
    area: "Irak",
    description:
      "Série de jardins en terrasses célèbres pour leur beauté exotique, probablement une œuvre architecturale de Nabuchodonosor II.",
    imageUrl: hangingGardenImg,
  },
  {
    id: "album-wonder-zeusStatue",
    number: 3,
    name: "La Statue de Zeus à Olympie",
    status: "Monde antique",
    area: "Grèce",
    description:
      "Immense statue du dieu Zeus, chef-d'œuvre du sculpteur Phidias.",
    imageUrl: zeusStatueImg,
  },
  {
    id: "album-wonder-artemisTemple",
    number: 4,
    name: "Le Temple d'Artémis à Éphèse",
    status: "Monde antique",
    area: "Turquie",
    description:
      "Grand temple dédié à la déesse Artémis, considéré comme l'un des plus grands édifices de l'Antiquité grecque.",
    imageUrl: artemisTempleImg,
  },
  {
    id: "album-wonder-mausoleum",
    number: 5,
    name: "Le Mausolée d'Halicarnasse",
    status: "Monde antique",
    area: "Turquie",
    description:
      "Tombeau monumental construit pour Mausole, un satrape de l'Empire perse.",
    imageUrl: mausoleumImg,
  },
  {
    id: "album-wonder-colossus",
    number: 6,
    name: "Le Colosse de Rhodes",
    status: "Monde antique",
    area: "Grèce",
    description:
      "Grande statue en bronze du dieu du soleil Hélios, érigée à l'entrée du port de Rhodes.",
    imageUrl: colossusImg,
  },
  {
    id: "album-wonder-lighthouse",
    number: 7,
    name: "Le Phare d'Alexandrie",
    status: "Monde antique",
    area: "Égypte",
    description:
      "Phare monumental servant de guide aux navires approchant le port d'Alexandrie.",
    imageUrl: lighthouseImg,
  },
  {
    id: "album-wonder-greatWall",
    number: 8,
    name: "La Grande Muraille de Chine",
    status: "Monde moderne",
    area: "Chine",
    description:
      "Série de fortifications massives construites pour protéger les frontières de l'empire chinois.",
    imageUrl: greatWallImg,
  },
  {
    id: "album-wonder-petra",
    number: 9,
    name: "Pétra",
    status: "Monde moderne",
    area: "Jordanie",
    description:
      "Ville historique célèbre pour ses structures taillées directement dans la roche rose.",
    imageUrl: petraImg,
  },
  {
    id: "album-wonder-christRedeemer",
    number: 10,
    name: "La Statue du Christ Rédempteur",
    status: "Monde moderne",
    area: "Brésil",
    description:
      "Grande statue de Jésus Christ surplombant la ville de Rio de Janeiro.",
    imageUrl: christRedeemerImg,
  },
  {
    id: "album-wonder-machuPicchu",
    number: 11,
    name: "Le Machu Picchu",
    status: "Monde moderne",
    area: "Pérou",
    description:
      "Site inca antique situé sur une montagne des Andes, célèbre pour ses ruines bien préservées.",
    imageUrl: machuPicchuImg,
  },
  {
    id: "album-wonder-chichenItza",
    number: 12,
    name: "Le Chichén Itzá",
    status: "Monde moderne",
    area: "Mexique",
    description:
      "Complexe de ruines mayas, célèbre pour sa pyramide de Kukulcán.",
    imageUrl: chichenItzaImg,
  },
  {
    id: "album-wonder-colosseum",
    number: 13,
    name: "Le Colisée",
    status: "Monde moderne",
    area: "Italie",
    description:
      "Grand amphithéâtre antique de Rome, connu pour avoir été le lieu de nombreux combats de gladiateurs.",
    imageUrl: colosseumImg,
  },
  {
    id: "album-wonder-tajMahal",
    number: 14,
    name: "Le Taj Mahal",
    status: "Monde moderne",
    area: "Inde",
    description:
      "Mausolée de marbre blanc construit par l'empereur moghol Shah Jahan en mémoire de son épouse favorite.",
    imageUrl: tajMahalImg,
  },
];

export default WonderData;
