import React, { useState, useEffect } from 'react';
import SpriteImg from "../img/sprite.png"
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const frameCount = 4; // The number of frames your sprite animation has
const frameWidth = 152; // The width of each frame in your sprite sheet
const frameHeight = 220; // The height of each frame

const Sprite = () => {
  const [frame, setFrame] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Update frame here
      setFrame((prevFrame) => (prevFrame + 1) % frameCount);
    }, 250); // 250ms for each frame change, adjust this for your animation speed

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, []);

  const style = {
    width: `${frameWidth}px`,
    height: `${frameHeight}px`,
    backgroundImage: `url(${SpriteImg})`,
    backgroundPosition: `-${frame * frameWidth}px 0px`, // Shift background position for each frame
    position:"absolute",
    borderRadius:4,
    boxShadow: '4px 4px 4px 4px rgba(0, 0, 0, .9)',
  };

  return  <Box
    sx={{
        zIndex: 1300, // plus haut que le Drawer, App Bar, et Modal dans MUI
        background: 'rgba(0, 0, 0, 0.9)', // fond semi-transparent
        position: 'fixed',
        top: '50%', // centré verticalement
        left: '50%', // centré horizontalement
        transform: 'translate(-50%, -50%)', // ajuste la position précise du sprite
        width:'100vw',
        height:'100vh',
        overflow:"hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }}
    >
        <Box sx={style}><Typography sx={{
                display:"absolute",
                top:`${frameHeight + 10}px`,
                position:"absolute",
                color:"#C2C2C2",
        }}>Patientez, construction en cours...</Typography></Box>
        </Box>
};

export default Sprite;