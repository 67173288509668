import imgLevel from '../img/level.png';
import imgTheme from '../img/theme.png';
import imgPersonnages from '../img/personnages.png';
import imgTopic from '../img/topic.png';
import imgArea from '../img/area.png';
import imgNbQuestions from '../img/nbQuestions.png';
import imgRule from '../img/rule.png';
import imgTense from '../img/tense.jpg';
import imgVerb from '../img/verb.jpg';
import theme from './ThemeList';
import gradeLevel from './GradeLevel';


const paramsExercice = [
    {
        "id":"level",
        "name":"La classe de l'enfant pour la difficulté",
        "description": "La classe de l'enfant",
        "example": "6ème",
        "values": gradeLevel,
        "image": imgLevel
    },
    {
        "id":"theme",
        "name":"Un thème ou passion qu'aime l'enfant",
        "description": "La thème pour l'exercice",
        "example": "Le football",
        "values": theme,
        "image": imgTheme
    },
    {
        "id":"topic",
        "name":"But de l'exercice",
        "example": "apprendre la table de multiplication de 5",
        "image": imgTopic
    },
    {
        "id":"personnages",
        "name":"Les personnages principaux de l'histoire",
        "example": "Un chien et un chat",
        "image": imgPersonnages
    },
    {
        "id":"area",
        "name":"Le lieux de l'histoire",
        "example": "la montagne",
        "image": imgArea
    },
    {
        "id":"nbQuestions",
        "name":"Le nombre de questions souhaitées, il peut y en avoir moins de retourné.",
        "values": ["1","3","5"],
        "example": "3",
        "image": imgNbQuestions
    },
    {
        "id":"rule",
        "name":"Règle à expliquer",
        "example": "Conjugaison des verbes du 1er groupe au présent de l'indicatif",
        "image": imgRule
    },
    {
        "id":"tenses",
        "name":"Temps de la conjugaison",
        "example": "Imparfait",
        "image": imgTense
    }
    ,
    {
        "id":"verb",
        "name":"Choix du verbe",
        "example": "manger",
        "image": imgVerb
    },
    {
        "id":"words",
        "name":"Liste des mots à tavailler pour votre dictée",
        "example": "maison, voiture, camion, maman",
        "image": imgPersonnages
    },
    {
        "id":"sheetType",
        "name":"Le type de calcul.",
        "values": ["addition","soustraction"],
    },
    {
        "id":"maxNumber",
        "name":"Le chiffre à ne pas dépasser pour les opérations",
        "values": ["99","999","9999"],
        "example": "999",
    },
    {
        "id":"calculCount",
        "name":"Le nombre d'opérations",
        "example": "5",
    }
    ,
    {
        "id":"homophoneList",
        "name":"Choix des homophones",
        "values": ["on,ont","a,à","son,sont","et, est"],
        "example": "on,ont",
    }
];
export default paramsExercice;