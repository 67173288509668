import imgCP from '../img/CP.png';
import imgCE1 from '../img/CE1.png';
import imgCE2 from '../img/CE2.png';
import imgCM1 from '../img/CM1.png';
import imgCM2 from '../img/CM2.png';

import schoolSubject from "./SchoolSubject"
 


const structure = [
    {
        "schoolLevel":"CP",
        "levelName":"Cours Préparatoire",
        "image":imgCP,
        "schoolSubjects": schoolSubject,
    },
    {
        "schoolLevel":"CE1",
        "levelName":"Cours Élémentaire 1",
        "image":imgCE1,
        "schoolSubjects": schoolSubject,
    },
    {
        "schoolLevel":"CE2",
        "levelName":"Cours Élémentaire 2",
        "image":imgCE2,
        "schoolSubjects": schoolSubject,
    },
    {
        "schoolLevel":"CM1",
        "levelName":"Cours Moyen 1",
        "image":imgCM1,
        "schoolSubjects": schoolSubject,
    },
    {
        "schoolLevel":"CM2",
        "levelName":"Cours Moyen 2",
        "image":imgCM2,
        "schoolSubjects": schoolSubject,
    },
    {
        "schoolLevel":"Tous",
        "levelName":"Pour les plus grands",
        "image":imgCM2,
        "schoolSubjects": schoolSubject,
    }
]
export default structure;