import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

export default function AdminRoute ({ redirectPath = '/error' }) {

  let isLoaded;
  const isAdmin = useSelector((state) => {
    isLoaded = state.userInfos.loading;
    return state.userInfos.group === "admin"
  }, shallowEqual);

  if (!isAdmin && isLoaded === "done") {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};