import React, { useState } from "react";
import { Grid, Card, CardContent, CardMedia, Typography, Box, Modal, Button, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Avatar, Divider } from "@mui/material";
import AlbumData from "./AlbumData";
import ApiService from "../../ApiService";
import { setActiveProfile } from "../authentication/userInfos";
import { useDispatch } from "react-redux";

const DoubleCards = ({ activeProfile, doubleCards, friends }) => {
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedFriend, setSelectedFriend] = useState(null);

    const getAlbumByCardId = (cardId) => {
        return AlbumData.find((album) => album.cards.some((card) => card.id === cardId));
    };

    const handleCardClick = (card) => {
        setSelectedCard(card);
        setOpenModal(true);
    };

    const handleFriendClick = (friend) => {
        setSelectedFriend(friend);
        setOpenDialog(true);
    };

    const handleConfirmShare = async () => {
        setOpenDialog(false);
        setOpenModal(false);
        if (selectedCard && selectedFriend && activeProfile) {
            // Envoyer la requête de partage au backend
            const response = await ApiService.fetchWithAuth(`/cards/share`, {
                method: "POST",
                body: JSON.stringify({
                    myPseudo: activeProfile.profileId,
                    friendPseudo: selectedFriend.profileId,
                    cardId: selectedCard.id,
                }),
            });

            if (response.ok) {
                const updatedProfile = await response.json();
                dispatch(setActiveProfile(updatedProfile));
            } else {
                console.error("Erreur lors du partage de la carte");
            }
        }
    };

    return (
        <>
            <Grid container spacing={2} justifyContent={{ xs: "center", md: "left" }}>
                {doubleCards.map((card) => {
                    const album = getAlbumByCardId(card.id);
                    if (!album) return null;

                    const albumCard = album.cards.find((albumCard) => albumCard.id === card.id);
                    const properties = album.properties;

                    return (
                        <Grid item key={card.id} onClick={() => handleCardClick(card)}>
                            <Card
                                sx={{
                                    width: { xs: "227px", sm: "345px" },
                                    height: { xs: "396px", sm: "600px" },
                                    m: 2,
                                    bgcolor: album.cardColor,
                                    p: 2,
                                    borderRadius: "16px",
                                    boxShadow: "2px 01px 2px 2px rgba(0, 0, 0, .9)",
                                    border: "4px solid #D6C3AE",
                                    color: "#D6C3AE",
                                }}
                            >
                                <CardContent
                                    sx={{
                                        bgcolor: "#D6C3AE",
                                        borderRadius: "16px",
                                        height: { xs: "47px", sm: "72px" },
                                        color: album.cardColor,
                                        mb: 1,
                                        boxShadow: "2px 01px 2px 2px rgba(0, 0, 0, .9)",
                                        justifyContent: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            textAlign: "center",
                                            padding: "0px",
                                            fontWeight: "bold",
                                            position: "relative",
                                        }}
                                    >
                                        {albumCard.name.toUpperCase()}
                                    </Box>
                                    {card.count && card.count > 1 && (
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                marginTop: "80px",
                                                zIndex: "100",
                                                bgcolor: album.cardColor,
                                                textAlign: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "0px",
                                                fontWeight: "bold",
                                                color: "#D6C3AE",
                                                borderRadius: "30px",
                                                width: "60px",
                                                height: "60px",
                                                fontSize: "30px",
                                                border: "2px solid #D6C3AE",
                                            }}
                                        >
                                            {`x${card.count-1}`}
                                        </Box>
                                    )}
                                </CardContent>

                                <CardMedia
                                    component="img"
                                    image={albumCard.imageUrl}
                                    alt={albumCard.name}
                                    sx={{
                                        height: { xs: "185px", sm: "305px" },
                                        width: { xs: "185px", sm: "305px" },
                                        objectFit: "contain",
                                        borderRadius: "16px",
                                        border: "4px solid #D6C3AE",
                                        mb: 2,
                                        boxShadow: "2px 01px 2px 2px rgba(0, 0, 0, .9)",
                                    }}
                                />
                                <CardContent
                                    sx={{
                                        bgcolor: "#D6C3AE",
                                        borderRadius: "16px",
                                        color: album.cardColor,
                                        overflowY: "auto",
                                        height: { xs: "100px", sm: "150px" },
                                        boxShadow: "2px 01px 2px 2px rgba(0, 0, 0, .9)",
                                    }}
                                >
                                    {properties.map((property, index) => (
                                        <Box key={albumCard.name + property.key}>
                                            <Box
                                                sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    color={album.cardColor}
                                                    sx={{ fontWeight: "700", mr: 1 }}
                                                >
                                                    {property.value}
                                                </Typography>
                                                <Typography variant="body1" color={album.cardColor}>
                                                    {albumCard[property.key]}
                                                </Typography>
                                            </Box>
                                            {index < properties.length - 1 && (
                                                <Divider size="small" color={album.cardColor}></Divider>
                                            )}
                                        </Box>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>

            <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Sélectionnez un ami pour partager la carte
          </Typography>
          <Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
            <List>
              {friends.map((friend) => (
                <ListItem button key={friend.profileId} onClick={() => handleFriendClick(friend)}>
                  <Avatar sx={{ mr: 2 }} src={friend.image} />
                  <ListItemText primary={friend.pseudo} />
                  <Button variant="contained" color="primary" onClick={() => handleFriendClick(friend)}>Partager</Button>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Modal>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmer le partage de carte"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez-vous vraiment partager cette carte avec {selectedFriend?.pseudo} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmShare} color="primary" autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DoubleCards;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};