import React, { useState, useEffect } from 'react';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import { useTheme} from '@mui/material/styles';

import QcmImg from '../img/question.png';
import ProblemImg from '../img/probleme.png';
import CalculImg from '../img/calcul.png';
import StoryImg from '../img/story.png';
import PoesieImg from '../img/poesie.png';
import RuleImg from '../img/rule.png';
import VerbConjugationImg from '../img/verbConjugation.jpg';
import FindWordImg from '../img/findWord.jpg';
import wordMatchImg from '../img/wordMatch.jpg';
import homophoneImg from '../img/homophone.jpg';
import multiplicationImg from '../img/multiplication.jpg';

import DialogCreatorComponent from "./DialogCreatorComponent";
import { useLocation } from 'react-router-dom';

const ExerciseGrid = () => {
  const theme = useTheme();
  
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  useEffect(() => {
    document.title = 'Créer un exercice';
  }, []);

  const tailConfigPerTemplateId = {
    "qcm": {
      "bgImg": QcmImg,
      "bgColor": "#38D5FF"
    },
    "calculationSheet": {
      "bgImg": CalculImg,
      "bgColor": "#FFBE10"
    },
    "probleme": {
      "bgImg": ProblemImg,
      "bgColor": "#FAFDF5"
    },
    "story": {
      "bgImg": StoryImg,
      "bgColor": "#23D6F7"
    },
    "sing": {
      "bgImg": PoesieImg,
      "bgColor": "#FAFDF5"
    },
    "explanation": {
      "bgImg": RuleImg,
      "bgColor": "#FFFFFF"
    },
    "verbConjugation": {
      "bgImg": VerbConjugationImg,
      "bgColor": "#FFFFFF"
    },  
    "findWord": {
      "bgImg": FindWordImg,
      "bgColor": "#FFFFFF"
    },  
    "wordMatch": {
      "bgImg": wordMatchImg,
      "bgColor": "#FFFFFF"
    },  
    "multiplicationTable": {
      "bgImg": multiplicationImg,
      "bgColor": "#FFFFFF"
    },  
    "homophoneExercise": {
      "bgImg": homophoneImg,
      "bgColor": "#FFFFFF"
    },  
  }
  
  const [exerciseTemplateList, setExerciseTemplateList] = useState([]);

  const handleOpen = (template) => {
    setSelectedTemplate(template);
  };
  const location = useLocation();
  

  useEffect(()=>{
    if(exerciseTemplateList){
      const params = new URLSearchParams(location.search);
      const exerciseTypeFromURL = params.get("type");
      handleOpen(exerciseTemplateList.find((template)=> template.templateName === exerciseTypeFromURL));
    }
  },[exerciseTemplateList, location])

  const getGetStyleByTemplateName = (templateName)=>{
    let css = { padding: 10, width:"200px", minHeight: "200px", textAlign: 'center',backgroundRepeat:"no-repeat",backgroundPosition:"center", backgroundSize: 'contain', marginBottom:"20px", borderRadius:"20px"}
    if(tailConfigPerTemplateId[templateName]){
      css.backgroundImage = `url(${tailConfigPerTemplateId[templateName].bgImg}`;
      css.backgroundColor = tailConfigPerTemplateId[templateName].bgColor;
    }
    return css;
  };

  useEffect(() => {
    async function fetchData() {
      const response = await fetch('https://roin2cakj2.execute-api.eu-west-3.amazonaws.com/backoffice/getExerciseTemplateList',{
        method: "GET"})
      const subjectsData = await response.json();
      //setExerciseTemplateList(subjectsData);
      setExerciseTemplateList(subjectsData.filter((template)=> !template.hideTemplate));
    }
    fetchData() 
  }, []);

  return (
    <div>
      <Stack spacing={2}>
      <Typography variant="h4" color={theme.palette.primary.main} sx={{fontFamily:"Softers"}} textAlign={"center"}>Quel type d'exercice veux tu créer?</Typography>
      <Grid container spacing={2} justifyContent={{xs:"center", md:"left"}}>
        {exerciseTemplateList.map((template, index) => (
          <Grid item key={index} onClick={() => handleOpen(template)}>
            <Paper elevation={10} style={getGetStyleByTemplateName(template.templateName)}></Paper>
            <Typography sx={{fontWeight:'700', width:"200px", color:'grey', fontSize:"24px", textAlign:"center"}}>{template.templateTitle}</Typography>
          </Grid>
        ))}
      </Grid>
      <DialogCreatorComponent selectedTemplate={selectedTemplate} onClose={()=> setSelectedTemplate(null)} />
      </Stack>
    </div>
  );
};

export default ExerciseGrid;