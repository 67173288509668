import * as React from "react";
import {
  Card,
  Box,
  CardContent,
  Typography
} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';


const LockedCard = ({album, cardNumber}) => {

  return (
    <Card
      sx={{
        width: {xs:"227px", sm:"345px"},
        height: {xs:"396px", sm:"600px"},
        m: 2,
        bgcolor: album.cardColor,
        p: 2,
        borderRadius: "16px",
        boxShadow: '2px 01px 2px 2px rgba(0, 0, 0, .9)',
        border: "4px solid #D6C3AE",
        color: "#FCF7DF",
        justifyContent: 'center',
        display: 'flex', // Ajouter flex pour centrer les éléments
        flexDirection: 'column', // Direction de la flexbox,
      }}
    >
      <Box
          sx={{
            bgcolor: "#D6C3AE",
            width:"100%",
            height:"100%",
            borderRadius: "40px",
            mb:2
          }}
        >
           <Box
            sx={{
              backgroundColor: album.cardColor,
              width:"100%",
              height:"100%",
              borderRadius: "40px",
              border: "10px solid #D6C3AE",
              boxShadow: '2px 01px 2px 2px rgba(0, 0, 0, .9)',
              padding:"20px",
              mb:2,
              display:"flex",
              justifyContent:"center",
              flexDirection:"column",
              alignItems:"center"
            }}
          >
            <Box
            sx={{
              bgcolor: "#D6C3AE",
              width: {xs:"130px", sm:"250px"},
              height: {xs:"130px", sm:"250px"},
              borderRadius: "200px",
              border: "4px solid #D6C3AE",
              boxShadow: '2px 01px 2px 2px rgba(0, 0, 0, .9)',
              display:"flex",
              justifyContent:"center",
              flexDirection:"column",
              alignItems:"center"
            }}
          >
            <LockIcon sx={{ color: album.cardColor, fontSize: {xs:"100px", sm:"150px"} }} />{" "}
          </Box>
          </Box>
        </Box>
        <CardContent
          sx={{
            bgcolor: "#D6C3AE",
            borderRadius: "16px",
            boxShadow: '2px 01px 2px 2px rgba(0, 0, 0, .9)',
            color: "#002730",
            maxHeight:"80px",
          }}
        >
                  
          <Box sx={{p: 1, color: "#002730", borderRadius: "4px", height: {xs:"132px", sm:"200px"} }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              {`N°${cardNumber}`}
            </Typography>
          </Box>
        </CardContent>
    </Card>
  );
};

export default LockedCard;
