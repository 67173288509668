
import { Box, Divider } from '@mui/material';
import StoryCard from "./StoryCard";
import Quiz from "./Quiz";
import { useEffect } from 'react';


const StoryQuiz = () => {

  useEffect(() => {
    document.title = "QCM: histoire";
  }, []);

  return (
    <Box style={{marginLeft:"auto",marginRight:"auto"}}>
      <StoryCard hideBackButton={true}/>
      <Divider style={{marginBottom:"20px"}} />
      <Quiz exerciseTypeFromParent={"story"} />
    </Box>
);
};

export default StoryQuiz;