import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from "uuid";
import mainStructure from "../../data/Structure.js";
import APIService from "../../ApiService.js"

export const getStructure = createAsyncThunk(
    'structure/getStructure',
    async () => {
      const response = await APIService.fetchWithAuth('/schoolLevel')
        const format = await response.json();
        return format;
    }
);

export const saveExercice = createAsyncThunk(
  'structure/saveExercice',
  async (exercices) => {
    const response = await APIService.fetchWithAuth('/backoffice/addExercice',{
      method: "POST", 
      body: JSON.stringify(exercices),
    });
    const format = await response.json();
    return format;
  }
);

export const structureSlice = createSlice({
  name: 'structure',
  initialState: {
    value: {},
    loading: 'idle',
  },
  reducers: {
    addExercice: (state, params) => {
      let level = params.payload.level;
      let subject = params.payload.subject;
      let exercice = {
        "exerciceName": params.payload.exerciceName,
        "lessonType": params.payload.lessonType,
        "exerciceType": params.payload.exerciceType,
        "exerciceId": uuid()
      }

      state.value[level][subject].push(exercice);
    }, 

    editExerciseType: (state, params) => {
      const level = params.payload.level;
      const subject = params.payload.subject;
      const exerciseName = params.payload.exerciseName;
      const exerciseType = params.payload.exerciseType;
      state.value[level][subject].find((exercise) => (exerciseName === exercise.exerciceName)).exerciceType = exerciseType
    }, 
    deleteExercice: (state, params) => {
      let level = params.payload.level;
      let subject = params.payload.subject;
      let exerciceName = params.payload.exerciceName;

      const index = state.value[level][subject].findIndex(item => item.exerciceName === exerciceName);
      if (index !== -1) {
        state.value[level][subject].splice(index, 1);
      }
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getStructure.fulfilled, (state, action) => {
      // Add user to the state array
      let structure = {};


      mainStructure.forEach((schoolElement) => {
        if(schoolElement.schoolSubjects && schoolElement.schoolSubjects.length){
          structure[schoolElement.schoolLevel] = {};
          schoolElement.schoolSubjects.forEach((schoolSubject) => {
            const topics = action.payload.find((element)=> {
              return (element.schoolLevel === schoolElement.schoolLevel) && (element.schoolSubject === schoolSubject.name);
            }) || {};
            structure[schoolElement.schoolLevel][schoolSubject.name] = topics.exercices || [];
          });
        }
        
      });

      state.value =  structure;
    })
    
  },
})

// Action creators are generated for each case reducer function
export const { addExercice, deleteExercice, editExerciseType } = structureSlice.actions

export default structureSlice.reducer

