import React, { useState, useEffect } from 'react';
import { Tabs, Tab, TextField, Select, MenuItem, Grid, Box, Typography, InputLabel, FormControl, Button, Card, CardContent, CardActions, IconButton, Drawer, Rating, FormControlLabel, Checkbox, FormGroup, Stack, CardMedia, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SharedExerciseService from '../features/sharedExercise/SharedExerciseService';
import SchoolSubject from '../data/SchoolSubject';
import { useLocation, useNavigate } from 'react-router-dom';
import structure from "../data/Structure";
import SearchIcon from "@mui/icons-material/Search";
import ApiService from '../ApiService';
import { Divider } from '@aws-amplify/ui-react';
import { useTheme } from '@mui/material/styles';
import createImg from '../img/createButton.jpg';
import questImg from '../img/defi.jpg';
import ShareIcon from '@mui/icons-material/Share';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const GradeLevel = structure.map(item => item.schoolLevel);

const getStyles = (name, filter, theme) => {
  return {
    backgroundColor:
      filter.indexOf(name) === -1
        ? ``
        : `${theme.palette.primary.main}`,
    color:
      filter.indexOf(name) === -1
        ? `#000000`
        : `#FFFFFF`,
  };
}

const Home = ({ activeProfile }) => {


  const theme = useTheme();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('community');
  const [filters, setFilters] = useState({
    exerciseName: '',
    gradeLevel: [],
    schoolSubject: params.get('schoolSubject') || '',
    profileId: '',
    exerciseType: '',
    stars: 0,
    isAccepted: ''
  });
  const [exercises, setExercises] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [friends, setFriends] = useState([]);
  const [exerciseTemplateList, setExerciseTemplateList] = useState([]);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState('');


  useEffect(() => {
    fetchExercises();
  }, [filters]);

  const fetchExercises = async () => {
    try {
      const response = await SharedExerciseService.getList(filters);
      const data = await response.json();
      if (Array.isArray(data)) {
        setExercises(data);
      } else {
        console.error('Invalid data for setExercises', data);
      }
    } catch (error) {
      console.error('Failed to fetch exercises:', error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const response = await ApiService.fetchWithAuth(`/backoffice/getExerciseTemplateList`)
      const subjectsData = await response.json();
      const templates = subjectsData.filter((template) => !template.hideTemplate && !template.hookAiAtEnd)
      setExerciseTemplateList(templates);
    }
    fetchData()
  }, []);

  useEffect(() => {
    if (activeProfile) {
      async function fetchFriends() {
        //On récupère d'abord la lsite des amis validés avec les infos publiques.
        if (activeProfile && activeProfile.friendList && activeProfile.friendList.length) {
          const friendsResponse = await ApiService.fetchWithAuth(`/profiles/publicInfos`, {
            method: "POST",
            body: JSON.stringify({
              profileIds: activeProfile.friendList,
            })
          });
          const friendList = await friendsResponse.json();
          setFriends(friendList);
        }
      }
      fetchFriends();
    }
  }, [activeProfile]);



  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    if (newValue === 'mine') {
      setFilters(prevFilters => ({ ...prevFilters, profileId: activeProfile.profileId }));
    } else {
      setFilters(prevFilters => ({ ...prevFilters, profileId: '' }));
    }
  };

  const handleLaunchExercise = (sharedExercise) => {
    const path = `/${sharedExercise.exerciseType}`;
    navigate(path, { state: { exercise: sharedExercise.exerciseJson, exerciseId: sharedExercise.exerciseId, schoolSubject: sharedExercise.schoolSubject } });
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      if (type === 'checkbox') {
        if (checked) {
          newFilters[name] = [...newFilters[name], value];
        } else {
          newFilters[name] = newFilters[name].filter((item) => item !== value);
        }
      } else {
        newFilters[name] = value;
      }
      return newFilters;
    });
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawer = (
    <Box sx={{ width: 250, p: 2, backgroundColor: "#f3f3f3", height: "100%" }}>
      <FormControl fullWidth margin="normal">
        <InputLabel id="gradeLevel-label">Niveau scolaire</InputLabel>
        <Select
          labelId="gradeLevel-label"
          multiple
          name="gradeLevel"
          value={filters.gradeLevel}
          label="Choisissez une classe"
          onChange={handleInputChange}
          sx={{ backgroundColor: "#FFFFFF" }}
        >
          {GradeLevel.map(
            (gradeLevel) => (<MenuItem key={`gradeLevelFilter-${gradeLevel}`} value={gradeLevel} style={getStyles(gradeLevel, filters.gradeLevel, theme)}>{gradeLevel}</MenuItem>))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="schoolSubject-label">Choisissez une matière</InputLabel>
        <Select
          labelId="schoolSubject-label"
          name="schoolSubject"
          label="Choisissez une matière"
          value={filters.schoolSubject}
          onChange={handleInputChange}
          sx={{ backgroundColor: "#FFFFFF" }}
        >
          <MenuItem value="">Toutes</MenuItem>
          {SchoolSubject.map(
            (schoolSubject) => (<MenuItem key={`schoolSubjectFilter-${schoolSubject.name}`} value={schoolSubject.name}>{schoolSubject.name}</MenuItem>))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="exerciseType-label">Choisissez un type d'exercice</InputLabel>
        <Select
          labelId="exerciseType-label"
          name="exerciseType"
          label="Choisissez une type d'exercice"
          value={filters.exerciseType}
          onChange={handleInputChange}
          sx={{ backgroundColor: "#FFFFFF" }}
        >
          <MenuItem value="">Tous</MenuItem>
          {exerciseTemplateList.map(
            (template) => (<MenuItem key={`schoolSubjectFilter-${template.templateName}`} value={template.templateName}>{template.templateTitle}</MenuItem>))}
        </Select>
      </FormControl>
      {activeProfile && (selectedTab === 'community') && (<FormControl fullWidth margin="normal">
        <InputLabel id="schoolSubject-label">Choisissez un ami</InputLabel>
        <Select
          labelId="profileId-label"
          name="profileId"
          label="Choisissez un ami"
          value={filters.profileId}
          onChange={handleInputChange}
          sx={{ backgroundColor: "#FFFFFF" }}
        >
          <MenuItem value="">Tout le monde</MenuItem>
          {friends.map(
            (friend) => (<MenuItem key={`schoolSubjectFilter-${friend.profileId}`} value={friend.profileId}>{friend.pseudo}</MenuItem>))}
        </Select>
      </FormControl>
      )}
      <FormControl fullWidth margin="normal">
        <Typography component="legend">Note Minimum</Typography>
        <Rating
          name="stars"
          value={filters.stars}
          onChange={(event, newValue) => {
            setFilters({ ...filters, stars: newValue });
          }}
        />
      </FormControl>
    </Box>
  );

  const handleShare = (exerciseId) => {
    const url = `${window.location.origin}/exercise/${exerciseId}`;
    setShareUrl(url);
    setShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setShareModalOpen(false);
    setShareUrl('');
  };


  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>

      <Typography variant="h4" color={theme.palette.primary.main} sx={{ fontFamily: "Softers" }} textAlign={"center"}>
        {`Exercices de la communauté`}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Stack sx={{ width: { xs: '90%', md: '80%' } }} direction={"row"}>
          <IconButton>
            <SearchIcon />
          </IconButton>

          <TextField
            label="Recherche d'exercice"
            name="exerciseName"
            fullWidth
            sx={{ backgroundColor: "#FFFFFF" }}
            margin="normal"
            value={filters.exerciseName}
            onChange={handleInputChange}
          />
        </Stack>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' }, mt: 2 }}>
        <IconButton color="primary" onClick={toggleDrawer(true)} sx={{ display: 'flex', alignItems: 'center' }}>
          <FilterListIcon />
          <Typography variant="button" sx={{ ml: 1 }}>Filtres</Typography>
        </IconButton>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawer}
        </Drawer>
      </Box>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={2} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Box sx={{ p: 2 }}>


            <FormControl component="fieldset">
              <Typography>Niveau scolaire</Typography>
              <FormGroup sx={{ ml: 2 }} aria-label="category" onChange={handleInputChange}>
                {GradeLevel.map(
                  (gradeLevel) => (<FormControlLabel key={gradeLevel} control={<Checkbox name="gradeLevel" value={gradeLevel} />} label={gradeLevel} />))}
              </FormGroup>
            </FormControl>

            <Divider sx={{ mt: 2 }}></Divider>



            <FormControl fullWidth margin="normal">
              <InputLabel id="schoolSubject-label">Choisissez une matière</InputLabel>
              <Select
                labelId="schoolSubject-label"
                name="schoolSubject"
                label="Choisissez une matière"
                value={filters.schoolSubject}
                onChange={handleInputChange}
              >
                <MenuItem value="">Toutes</MenuItem>
                {SchoolSubject.map(
                  (schoolSubject) => (<MenuItem key={`schoolSubjectFilter-${schoolSubject.name}`} value={schoolSubject.name}>{schoolSubject.name}</MenuItem>))}
              </Select>
            </FormControl>

            <Divider sx={{ mt: 2 }}></Divider>

            <FormControl fullWidth margin="normal">
              <InputLabel id="exerciseType-label">Choisissez un type d'exercice</InputLabel>
              <Select
                labelId="exerciseType-label"
                name="exerciseType"
                label="Choisissez une type d'exercice"
                value={filters.exerciseType}
                onChange={handleInputChange}
              >
                <MenuItem value="">Tous</MenuItem>
                {exerciseTemplateList.map(
                  (template) => (<MenuItem key={`schoolSubjectFilter-${template.templateName}`} value={template.templateName}>{template.templateTitle}</MenuItem>))}
              </Select>
            </FormControl>

            <Divider sx={{ mt: 2 }}></Divider>

            <FormControl fullWidth margin="normal">
              <Typography component="legend">Note Minimum</Typography>
              <Rating
                name="stars"
                value={filters.stars}
                onChange={(event, newValue) => {
                  setFilters({ ...filters, stars: newValue });
                }}
              />
            </FormControl>

            {activeProfile && (selectedTab === 'community') && (<Divider sx={{ mt: 2 }}></Divider>)}

            {activeProfile && (selectedTab === 'community') && (<FormControl fullWidth margin="normal">
              <InputLabel id="schoolSubject-label">Choisissez un ami</InputLabel>
              <Select
                labelId="profileId-label"
                name="profileId"
                label="Choisissez un ami"
                value={filters.profileId}
                onChange={handleInputChange}
              >
                <MenuItem value="">Tout le monde</MenuItem>
                {friends.map(
                  (friend) => (<MenuItem key={`schoolSubjectFilter-${friend.profileId}`} value={friend.profileId}>{friend.pseudo}</MenuItem>))}
              </Select>
            </FormControl>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="exercise tabs">
            <Tab label="Exercices de la communauté" value="community" />
            {activeProfile && (<Tab label="Mes exercices" value="mine" />)}
          </Tabs>
          <Box sx={{ p: 1, maxHeight: { xs: 'none', md: '90vh' }, overflowY: 'auto' }}>
            {exercises.map(exercise => (

              <Card key={exercise.exerciseId} elevation={3} sx={{ mb: 1, borderRadius: 5 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h7" sx={{ width: '100%' }}>{exercise.exerciseName} - {exercise.gradeLevel}</Typography>
                    <IconButton onClick={() => handleShare(exercise.exerciseId)}>
                      <ShareIcon />
                    </IconButton>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'left', p: 1 }}>
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="body2"> {exerciseTemplateList.length && exerciseTemplateList.find((template) => template.templateName === exercise.exerciseType).templateTitle} : {exercise.schoolSubject}</Typography>
                      {exercise.averageRating !== null && exercise.averageRating !== 0 && exercise.averageRating !== undefined && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Rating value={exercise.averageRating} readOnly precision={0.5}></Rating>
                          <Typography variant="body2" sx={{ ml: 1 }}>
                            ({exercise.ratingCount})
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <CardActions sx={{ mt: { xs: 2, md: 0 } }}>
                      <Button variant="outlined" color="primary" onClick={() => handleLaunchExercise(exercise)}>Lancer</Button>
                    </CardActions>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card elevation={3} sx={{ mt: 7, mb: 1, borderRadius: 5, p: 2 }}>

            <CardMedia
              component="img"
              height="194"
              image={createImg}
              alt="Créer mes exercices"
              sx={{ objectFit: "cover" }}
            />
            <CardContent sx={{ display: "flex", justifyContent: "center" }}>
              Créer tes exercices:
            </CardContent>
            <CardActions sx={{ mt: { xs: 2, md: 0, display: "flex", justifyContent: "center" } }}>
              <Button onClick={() => navigate("/create")} variant="outlined" color="primary">Voir</Button>
            </CardActions>
          </Card>
          <Card elevation={3} sx={{ mb: 1, borderRadius: 5, p: 2 }}>
            <CardMedia
              component="img"
              height="140"
              image={questImg}
              alt="Mes albums"
              sx={{ objectFit: "cover" }}
            />
            <CardContent sx={{ display: "flex", justifyContent: "center" }}>
              Gagne des cartes à collectionner
            </CardContent>

            <CardActions sx={{ mt: { xs: 2, md: 0 }, display: "flex", justifyContent: "center" }}>
              <Button onClick={() => navigate("/quest/list")} variant="outlined" color="primary">Voir</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={shareModalOpen} onClose={handleCloseShareModal}>
        <DialogTitle>Partager cet exercice</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2, mb: 2 }}>
            <Tooltip title="Partager sur WhatsApp">
              <IconButton component="a" href={`https://wa.me/?text=${shareUrl}`} target="_blank" rel="noopener noreferrer">
                <WhatsAppIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Partager sur Facebook">
              <IconButton component="a" href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`} target="_blank" rel="noopener noreferrer">
                <FacebookIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Partager sur X">
              <IconButton component="a" href={`https://twitter.com/intent/tweet?url=${shareUrl}`} target="_blank" rel="noopener noreferrer">
                <XIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Partager sur Linkedin">
              <IconButton component="a" href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`} target="_blank" rel="noopener noreferrer">
                <LinkedInIcon color="primary" />
              </IconButton>
            </Tooltip>
            <CopyToClipboard text={shareUrl}>
                <IconButton>
                  <ShareIcon color="primary" />
                </IconButton>
            </CopyToClipboard>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShareModal} color="primary">Fermer</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Home;
