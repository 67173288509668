import React, { useState, useEffect } from "react";
import { Stack, Typography, Box, Divider } from "@mui/material";
import ButtonGroups from "../ButtonGroups";
import { useParams, useLocation } from "react-router-dom";

import ValidateButton from "../ValidateButton";
import { useTheme } from "@emotion/react";

function shuffleArray(array) {
  let newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

const Quiz = ({ exerciseTypeFromParent }) => {

  useEffect(() => {
    document.title = "QCM";
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const [quiz, setQuiz] = useState([]);
  const [choices, setChoices] = useState([]);
  const [answered, setAnswered] = useState([]);
  const { exerciceId } = useParams();
  const location = useLocation();
  const exerciseParam = location.state?.exercise;
  const exerciseId = location.state?.exerciseId;
  const fromCreate = location.state?.fromCreate;
  const exerciseType = exerciseTypeFromParent || "qcm";
  const schoolSubject = location.state?.schoolSubject;

  const [isCorrect, setIsCorrect] = useState(false); // Nouvel état pour suivre si toutes les réponses sont correctes
  const [validate, setValidate] = useState(false);

  const handleValidate = () => {
    setAnswered(answered.map(() => true));
    // Vérifiez ici si toutes les réponses sont correctes
    const allCorrect = quiz.every(
      (item, index) => item.correct_answer === choices[index]
    );
    setValidate(true);
    setIsCorrect(allCorrect);
  };

  const [shuffledQuestions, setShuffledQuestions] = useState([]);

  useEffect(() => {
    const shuffled = quiz.map((item) => ({
      ...item,
      answers: shuffleArray([item.correct_answer, ...item.incorrect_answers]),
    }));
    setShuffledQuestions(shuffled);
  }, [quiz]);

  useEffect(() => {
    const quizShuffled = shuffleArray(exerciseParam.quiz);
    setQuiz(quizShuffled);
    setChoices(quizShuffled.map(() => ""));
    setAnswered(quizShuffled.map(() => false));
  }, [exerciceId, exerciseParam]);

  return (
    <Box sx={{ paddingBottom: "20px", paddingTop: "20px" }}>
      <Stack spacing={5} sx={{ display: "flex", alignItems: "center" }}>
        {!exerciseTypeFromParent && (<Typography variant="h4" color={theme.palette.primary.main} gutterBottom>
          {`${exerciseParam.exerciseName || "Mon Quiz:"}`}
        </Typography>
        )}
        {shuffledQuestions.map((item, index) => (
          <Stack
            style={{ width: "100%" }}
            key={`question-stack-${index}`}
            spacing={4}
          >
            <Typography key={`question-${index}`} variant="h6" component="div">
              {item.question}
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
            >
              {item.answers.map((answer, answerIndex) => (
                <ButtonGroups
                  key={`buttonGroup-${index}-${answerIndex}`}
                  isSelected={choices[index] === answer}
                  answered={answered[index]}
                  isCorrect={item.correct_answer === answer}
                  fixedAnswers={answer}
                  index={index}
                  id={`${index}-${answerIndex}`}
                  choices={choices}
                  setChoices={setChoices}
                />
              ))}
            </Stack>
            <Stack alignItems="center"></Stack>
          </Stack>
        ))}
        <ValidateButton exerciseType={exerciseType} schoolSubject={schoolSubject} exerciseJson={exerciseParam} validate={validate} isCorrect={isCorrect} fromCreate={fromCreate} exerciseId={exerciseId} handleValidate={handleValidate} />
      </Stack>

    </Box>
  );
};

export default Quiz;
