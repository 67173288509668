import React, { useState, useEffect } from "react";
import { Box, Paper, Typography, TextField, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import ValidateButton from "../ValidateButton";

const MotusGame = () => {

  useEffect(() => {
    document.title = 'Motus';
  }, []);

  const location = useLocation();
  const exerciseParam = location.state?.exercise;
  const exerciseId = location.state?.exerciseId;
  const fromCreate = location.state?.fromCreate;
  const [solution, setSolution] = useState([]);
  const schoolSubject = location.state?.schoolSubject;

  useEffect(() => {
    const randomWords = exerciseParam?.randomWords;
    const randomIndex = Math.floor(Math.random() * randomWords?.length);
    setSolution(
      randomWords[randomIndex]
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toUpperCase()
    );
  }, [exerciseParam]);

  const exerciseType = "findWord";
  const [guesses, setGuesses] = useState([]);
  const [currentGuess, setCurrentGuess] = useState("");
  const [disableGame, setDisableGame] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [validate, setValidate] = useState(false);

  const wordLength = solution.length;
  const maxAttempts = 6; // Définissez le nombre maximal de tentatives ici

  useEffect(() => {
    // Vérifiez si le joueur a perdu après chaque nouvelle tentative
    if (
      guesses.length === maxAttempts &&
      guesses[guesses.length - 1].toLowerCase() !== solution.toLowerCase()
    ) {
      setIsCorrect(false);
      setValidate(true);
    }
  }, [guesses, solution]);

  // Ajoutez ici une fonction pour vérifier si le mot est valide
  const isWordValid = (word) => {
    // La validation peut se faire via une liste de mots ou une API
    // Pour cet exemple, on suppose que tous les mots sont valides
    return word.length === wordLength;
  };

  const handleGuessSubmit = () => {
    if (isWordValid(currentGuess)) {
      if (currentGuess.toLowerCase() === solution.toLowerCase()) {
        // Le joueur a trouvé le mot
        addGuess(currentGuess);
        setCurrentGuess("");
        setIsCorrect(true);
        setValidate(true);
        setDisableGame(true); // Désactiver le jeu après avoir trouvé le mot
      } else if (guesses.length === maxAttempts - 1) {
        // Dernière tentative, ajouter la supposition et ensuite afficher la solution
        addGuess(currentGuess);
        setCurrentGuess("");
        // La gestion de l'affichage de la solution est faite dans useEffect, pas besoin de l'ajouter ici
      } else {
        // Tentatives restantes
        addGuess(currentGuess);
        setCurrentGuess("");
      }
    } else {
      alert("Le mot n'est pas valide ou n'a pas la bonne longueur.");
    }
  };

  // Ajustez l'effet useEffect qui gère la fin du jeu pour désactiver le jeu après le dernier essai
  useEffect(() => {
    // Vérifiez si le joueur a perdu après chaque nouvelle tentative
    if (guesses.length === maxAttempts) {
      setIsCorrect(
        guesses[guesses.length - 1].toLowerCase() === solution.toLowerCase()
      );
      setValidate(true);

      //setOpenDialog(true);
      setDisableGame(true); // Désactiver le jeu après le dernier essai
    }
  }, [guesses, solution]);

  const addGuess = (newGuess) => {
    setGuesses([...guesses, newGuess.toUpperCase()]);
  };

  const getLetterState = (letter, index, attemptIndex) => {
    if (guesses.length > attemptIndex && guesses[attemptIndex]) {
      const currentGuess = guesses[attemptIndex].toUpperCase();
      const solutionUpper = solution.toUpperCase();
  
      // Vérifier si la lettre est bien placée
      if (currentGuess[index] === solutionUpper[index]) {
        return "#377D22"; // Vert pour bien placée
      } else if (solutionUpper.includes(currentGuess[index])) {
        // Compter les occurrences de la lettre dans la solution
        const totalInSolution = solutionUpper.split('').filter(l => l === currentGuess[index]).length;
  
        // Compter combien de fois cette lettre a été bien placée dans toutes les tentatives
        const wellPlacedCount = currentGuess.split('').reduce((count, currentLetter, currentIndex) => {
          return count + (currentLetter === solutionUpper[currentIndex] && currentLetter === currentGuess[index] ? 1 : 0);
        }, 0);
  
        // Compter combien de fois cette lettre apparaît dans le mot deviné jusqu'à cet index inclus
        const guessOccurrencesUpToIndex = currentGuess.slice(0, index + 1).split('').filter(l => l === currentGuess[index]).length;
  
        // Si le nombre d'occurrences de la lettre dans le mot deviné jusqu'à cet index ne dépasse pas le total dans la solution,
        // et que le nombre bien placé est inférieur au total dans la solution, alors la lettre est considérée comme mal placée
        if (guessOccurrencesUpToIndex <= totalInSolution && wellPlacedCount < totalInSolution) {
          return "#F08650"; // Orange si mal placée
        }
      }
    }
    return "lightblue"; // Bleu clair pour non présente ou en excès
  };

  return (
    <Box>
      {/* Le plateau de jeu */}
      <Typography variant="h4">
        {`Trouve le mot en lien avec le thème: "${exerciseParam.theme}".`}
      </Typography>
      <Typography variant="h5">
        {`Vert si la lettre est bien placée et orange si elle n'est pas bien placée. Si la couleur reste bleue, c'est que la lettre n'est pas présente dans le mot.`}
      </Typography>
      {Array.from({ length: 6 }, (_, attemptIndex) => (
        <Box key={attemptIndex} display="flex" justifyContent="center">
          {Array.from({ length: wordLength }, (_, letterIndex) => (
            <Paper
              key={letterIndex}
              elevation={3}
              sx={{
                width: 50,
                height: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: getLetterState(
                  guesses[attemptIndex]?.[letterIndex],
                  letterIndex,
                  attemptIndex
                ),
                color:
                  getLetterState(
                    guesses[attemptIndex]?.[letterIndex],
                    letterIndex,
                    attemptIndex
                  ) === "#377D22"
                    ? "#FFFFFF"
                    : "black",
                textShadow:
                  getLetterState(
                    guesses[attemptIndex]?.[letterIndex],
                    letterIndex,
                    attemptIndex
                  ) === "#377D22"
                    ? "black 1px 1px"
                    : "#FFFFFF 1px 1px",
                margin: 0.5,
                border:
                  getLetterState(
                    guesses[attemptIndex]?.[letterIndex],
                    letterIndex,
                    attemptIndex
                  ) === "yellow"
                    ? "2px solid yellow"
                    : "none",
              }}
            >
              <Typography variant="h5" component="div">
                {guesses.length > attemptIndex
                  ? guesses[attemptIndex][letterIndex]
                  : ""}
              </Typography>
            </Paper>
          ))}
        </Box>
      ))}

      {/* Le champ de saisie pour les propositions */}
      <Stack
        sx={{ mt: "20px", mb: "20px" }}
        spacing={2}
        direction={{ xs: "column", sm: "row" }}
        display="flex"
        justifyContent="center"
      >
        <TextField
          label="Votre proposition"
          variant="outlined"
          value={currentGuess}
          onChange={(e) => setCurrentGuess(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleGuessSubmit();
              e.preventDefault(); // Prévenir le comportement par défaut si nécessaire
            }
          }}
          inputProps={{ maxLength: wordLength }}
          sx={{ marginRight: 1 }}
          disabled={disableGame}
        />
      </Stack>
      {validate && !isCorrect && (
        <Typography variant="h6" color="error.main">
          {`La bonne réponse était: "${solution.toUpperCase()}".`}
        </Typography>
      )}
      <ValidateButton
        exerciseType={exerciseType}
        exerciseJson={{...exerciseParam, randomWords:[solution]}}
        exerciseId = {exerciseId}
        validate={validate}
        schoolSubject={schoolSubject}
        isCorrect={isCorrect}
        fromCreate={fromCreate}
        handleValidate={handleGuessSubmit}
      />
    </Box>
  );
};

export default MotusGame;
