import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  Box,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import ValidateButton from "../ValidateButton"

const StoryCard = ({ hideBackButton }) => {
  const location = useLocation();
  const story = location.state?.exercise;
  const exerciseType = "explanation";
  const fromCreate = location.state?.fromCreate;
  const exerciseId = location.state?.exerciseId;
  const schoolSubject = location.state?.schoolSubject;

  const speak = (text) => {
    const speech = new SpeechSynthesisUtterance(text);
    speech.pitch = 1;
    let voices = speechSynthesis.getVoices();

    let defaultVoice = voices.find((voice) => voice.default === true);
    let frenchVoice = voices.find((voice) => voice.lang === "fr-FR");
    if (!defaultVoice || defaultVoice.lang !== "fr-FR") {
      if (frenchVoice) {
        speech.voice = frenchVoice;
      }
    }
    window.speechSynthesis.speak(speech);
  };

  const stop = () => {
    window.speechSynthesis.cancel();
  };

  return (
    <>
    <Card sx={{ maxWidth: 600, margin: "20px auto" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {story.title}
        </Typography>
        <Typography
          style={{ whiteSpace: "pre-line" }}
          variant="body1"
          color="text.secondary"
        >
          {story.content}
        </Typography>
        <Stack
          spacing={5}
          direction="row"
          style={{ justifyContent: "center", marginTop: 20 }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => speak(story.content)}
          >
            Écouter
          </Button>
          <Button variant="contained" color="error" onClick={() => stop()}>
            Arrêter
          </Button>
        </Stack>
       
      </CardContent>
    </Card>
     {!hideBackButton && (
      <Box
        sx={{
          display: "flex",
          marginTop: "40px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ValidateButton exerciseType={exerciseType} schoolSubject={schoolSubject} exerciseJson={story} fromCreate={fromCreate} exerciseId={exerciseId} />
      </Box>
    )}
  </>);
};

export default StoryCard;
