import React, {useEffect, useState} from 'react';
import { TextField } from "@mui/material";

const JSONField = ({templateJson, onBlur}) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("Saisissez votre JSON ici");
  const [jsonInput, setJsonInput] = useState("");


  useEffect(()=>{
    formatAndValidateJSON(JSON.stringify(templateJson));
  },[templateJson])

  const formatAndValidateJSON = (input) => {
    try {
      // Tentative de parsing du JSON
      const jsonObject = JSON.parse(input);
      // Formatage du JSON pour une meilleure lisibilité
      const formattedJson = JSON.stringify(jsonObject, null, 2);
      setJsonInput(formattedJson);
      setError(false);
      setHelperText("JSON valide et formaté");
    } catch (e) {
      setError(true);
      setHelperText("JSON invalide");
    }
  };

  const handleChange = (event) => {
    setJsonInput(event.target.value);
  };

  const handleBlur = () => {
    formatAndValidateJSON(jsonInput);
    onBlur(jsonInput);
  };

  return (
    <TextField
      label="JSON Input"
      defaultValue={JSON.stringify(templateJson) || ""}
      multiline
      rows={15}
      {...(jsonInput && { value: jsonInput })}
      onChange={handleChange}
      onBlur={handleBlur}
      variant="outlined"
      fullWidth
      error={error}
      helperText={helperText}
    />
  );
};

export default JSONField;