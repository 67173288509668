import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APIService from "../../ApiService";

// Supposons que la fonction getStoredProfileId et storeProfileId existent pour gérer localStorage
function getStoredProfileId() {
  return localStorage.getItem("activeProfileId");
}

function storeProfileId(profileId) {
  localStorage.setItem("activeProfileId", profileId);
}

function deleteProfileId(profileId) {
  localStorage.removeItem("activeProfileId");
}


export const refreshProfile = createAsyncThunk(
  "authentication/refreshProfile",
  async (_, { getState }) => {
    const state = getState();
    const storedProfileId = getStoredProfileId(); // Récupère le profil actif stocké
    try {
      const response = await APIService.fetchWithAuth(`/profiles`);
      const profileList = (await response.json()) || [];
      const activeProfile = profileList.find(
        (profile) =>
          profile.profileId ===
          (state?.userInfos?.activeProfile?.profileId || storedProfileId)
      );
      if (activeProfile) {
        storeProfileId(activeProfile.profileId); // Stocke le nouveau profil actif
      }
      return {
        activeProfile: activeProfile,
      };
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const getUserInfos = createAsyncThunk(
  "authentication/getUserInfos",
  async (session) => {
    try {
      const response = await APIService.fetchWithAuth(`/profiles`);
      let profileList = (await response.json()) || [];
      let activeProfile = profileList.length ? profileList[0] : null;
      // Utiliser le profil stocké si disponible et valide
      const storedProfileId = getStoredProfileId();
      if (storedProfileId) {
        const storedActiveProfile = profileList.find(
          (profile) => profile.profileId === storedProfileId
        );
        if (storedActiveProfile) {
          activeProfile = storedActiveProfile;
        }
      }
      if (activeProfile) {
        storeProfileId(activeProfile.profileId); // Mise à jour du localStorage
      }
      return {
        groups: session.tokens?.accessToken?.payload["cognito:groups"],
        activeProfile: activeProfile,
      };
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  }
);

export const userInfosSlice = createSlice({
  name: "userInfos",
  initialState: {
    group: null,
    loading: "idle",
    activeProfile: null,
  },
  reducers: {
    setActiveProfile: (state, action) => {
      state.activeProfile = action.payload;
      if (action.payload) {
        storeProfileId(action.payload.profileId); // Stocke également dans le localStorage lors de la mise à jour manuelle
      }
    },
    disconnect: (state) =>{
      state.activeProfile = null;
      state.loading = "idle";
      deleteProfileId();
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfos.fulfilled, (state, action) => {
      const groups = action?.payload?.groups;
      state.activeProfile = action?.payload?.activeProfile;
      state.loading = "done";
      if (groups && groups.includes("admin")) {
        state.group = "admin";
      } else {
        state.group = "guest";
      }
    });
    builder.addCase(refreshProfile.fulfilled, (state, action) => {
      state.activeProfile = action?.payload?.activeProfile;
    });
  },
});

export const { setActiveProfile, disconnect } = userInfosSlice.actions;
export default userInfosSlice.reducer;
