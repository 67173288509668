import mercuryImg from "./img/mercury.png";
import venusImg from "./img/venus.png";
import earthImg from "./img/earth.png";
import marsImg from "./img/mars.png";
import neptuneImg from "./img/neptune.png";
import jupiterImg from "./img/jupiter.png";
import saturnImg from "./img/saturn.png";
import uranusImg from "./img/uranus.png";
import sunImg from "./img/sun.png";

const PlanetData = [
  {
    id:"album-planet-sun",
    number: 1,
    name: "Le Soleil",
    status: "Étoile",
    type: "N/A",
    diameter: "1,391,000 km",
    distanceFromSun: "0",
    description: "L'étoile au centre de notre système solaire, une source massive d'énergie, essentielle pour la vie sur Terre.",
    imageUrl: sunImg,
  },
  {
    id:"album-planet-mercury",
    number: 2,
    name: "Mercure",
    status: "Planète",
    type: "Rocheuse",
    diameter: "4,880 km",
    distanceFromSun: "57,9 millions km",
    description: "La plus petite planète et la plus proche du Soleil, connue pour ses températures extrêmes.",
    imageUrl: mercuryImg,
  },
  {
    id:"album-planet-venus",
    number: 3,
    name: "Vénus",
    status: "Planète",
    type: "Rocheuse",
    diameter: "12,104 km",
    distanceFromSun: "108,2 millions km",
    description: "Deuxième planète du système solaire, célèbre pour son atmosphère dense et son effet de serre intense.",
    imageUrl: venusImg,
  },
  {
    id:"album-planet-earth",
    number: 4,
    name: "Terre",
    status: "Planète",
    type: "Rocheuse",
    diameter: "12,742 km",
    distanceFromSun: "149,6 millions km",
    description: "Notre planète, unique pour sa capacité à supporter la vie.",
    imageUrl: earthImg,
  },
  {
    id:"album-planet-mars",
    number: 5,
    name: "Mars",
    status: "Planète",
    type: "Rocheuse",
    diameter: "6,779 km",
    distanceFromSun: "227,9 millions km",
    description: "La planète rouge, connue pour ses tempêtes de poussière et ses volcans comme l'Olympus Mons.",
    imageUrl: marsImg,
  },
  {
    id:"album-planet-jupiter",
    number: 6,
    name: "Jupiter",
    status: "Planète",
    type: "Gazeuse",
    diameter: "139,820 km",
    distanceFromSun: "778,5 millions km",
    description: "La plus grande planète du système solaire, connue pour sa Grande Tache Rouge, un gigantesque anticyclone.",
    imageUrl: jupiterImg,
  },
  {
    id:"album-planet-saturn",
    number: 7,
    name: "Saturne",
    status: "Planète",
    type: "Gazeuse",
    diameter: "116,460 km",
    distanceFromSun: "1,4 milliards km",
    description: "Célèbre pour ses anneaux impressionnants, composés principalement de glace et de roches.",
    imageUrl: saturnImg,
  },
  {
    id:"album-planet-uranus",
    number: 8,
    name: "Uranus",
    status: "Planète",
    type: "Gazeuse",
    diameter: "50,724 km",
    distanceFromSun: "2,9 milliards km",
    description: "Planète connue pour son axe de rotation unique, presque parallèle au plan de son orbite.",
    imageUrl: uranusImg,
  },
  {
    id:"album-planet-neptune",
    number: 9,
    name: "Neptune",
    status: "Planète",
    type: "Gazeuse",
    diameter: "49,244 km",
    distanceFromSun: "4,5 milliards km",
    description: "La dernière planète de notre système solaire, remarquable pour sa belle couleur bleue.",
    imageUrl: neptuneImg,
  },
];
export default PlanetData;
