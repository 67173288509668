import React, { useState, useEffect } from 'react';
import { TextField, Typography, Container, Grid, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ValidateButton from '../ValidateButton';

const VerbConjugation = () => {

  useEffect(() => {
    document.title = 'Conjugaison';
  }, []);

  const location = useLocation();
  const exerciseParam = location.state?.exercise;
  const fromCreate = location.state?.fromCreate;
  const exerciseId = location.state?.exerciseId;
  const exerciseType = "verbConjugation";
  const verbesCorrects = {
    je: exerciseParam.exercise["je"].verb,
    tu: exerciseParam.exercise["tu"].verb,
    il: exerciseParam.exercise["il"].verb,
    nous: exerciseParam.exercise["nous"].verb,
    vous: exerciseParam.exercise["vous"].verb,
    ils: exerciseParam.exercise["ils"].verb
  }
  const [validate, setValidate] = useState(false)
  const [isCorrect, setIsCorrect] = useState(false);
  const schoolSubject = location.state?.schoolSubject;

  const [verbes, setVerbes] = useState({
    je: ``,
    tu: ``,
    il: ``,
    nous: ``,
    vous: ``,
    ils: ``
  });
  const pronounName = {
    je: "je/j'",
    tu: 'tu',
    il: 'il/elle/on',
    nous: 'nous',
    vous: 'vous',
    ils: 'ils/elles'
  };

  const [resultats, setResultats] = useState({});

  const handleChange = (pronoun, value) => {
    setVerbes(prev => ({ ...prev, [pronoun]: value }));
  };

  const verifierReponses = () => {
    setValidate(true);
    let nouveauxResultats = {};
    let allCorrect = true;
    Object.keys(verbes).forEach(pronoun => {
      // Si une correspondance est trouvée, la valeur sera true, sinon false
      nouveauxResultats[pronoun] =  verbes[pronoun].toLowerCase() === verbesCorrects[pronoun].toLowerCase();
      // Si un seul verbe est incorrect, isCorrect devient false
      if (!nouveauxResultats[pronoun]) {
        allCorrect = false;
      }
    });
    setIsCorrect(allCorrect);
    setResultats(nouveauxResultats);
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        {`Conjugue le verbe ${exerciseParam.verb} au ${exerciseParam.conjugationTense}`}
      </Typography>
      <Stack spacing={2}>
        <Grid container spacing={2}>
          {Object.keys(verbes).map(pronoun => (
            <Grid item xs={12} key={pronoun}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="body1">{pronounName[pronoun]}</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={verbes[pronoun]}
                  label={`(${exerciseParam.verb})`}
                  onChange={(e) => handleChange(pronoun, e.target.value)}
                  error={resultats[pronoun] === false}
                  helperText={resultats[pronoun] === false ? `La bonne réponse est : "${verbesCorrects[pronoun]}"` : ''}
                />
              </Stack>
            </Grid>
          ))}
        </Grid>
        <ValidateButton exerciseType={exerciseType} exerciseJson={exerciseParam} schoolSubject={schoolSubject} validate={validate} isCorrect={isCorrect} fromCreate={fromCreate} handleValidate={verifierReponses} exerciseId={exerciseId}/>
      </Stack>
    </Container>
  );
};

export default VerbConjugation;
