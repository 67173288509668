import React, { useState } from 'react';
import { signIn, signUp, resetPassword, confirmResetPassword, confirmSignUp } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, Box, TextField, Button, Typography, Container, IconButton, InputAdornment } from '@mui/material';
import ReactGA from 'react-ga4';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [forgotPasswordUsername, setForgotPasswordUsername] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isCodeNeeded, setIsCodeNeeded] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await signIn({ username, password });
      navigate(-1);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleForgotPasswordRequest = async (event) => {
    event.preventDefault();
    try {
      await resetPassword({ username: forgotPasswordUsername });
      setIsCodeNeeded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleForgotPasswordSubmit = async (event) => {
    event.preventDefault();
    try {
      await confirmResetPassword({
        username: forgotPasswordUsername,
        confirmationCode: verificationCode,
        newPassword: newPassword
      });
      setIsForgotPassword(false);
      setIsCodeNeeded(false);
      setError('Mot de passe réinitialisé avec succès. Vous pouvez maintenant vous connecter.');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 8
        }}
      >
        <Typography component="h1" variant="h5">
          Connexion
        </Typography>
        {isCodeNeeded ? (
          <Box component="form" onSubmit={handleForgotPasswordSubmit} sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="verificationCode"
              label="Code de vérification"
              name="verificationCode"
              autoFocus
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="Nouveau mot de passe"
              type="password"
              id="newPassword"
              autoComplete="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Réinitialiser le mot de passe
            </Button>
          </Box>
        ) : (
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Nom d'utilisateur"
              name="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Connexion
            </Button>
            <Button
              fullWidth
              variant="text"
              color="primary"
              onClick={() => setIsForgotPassword(true)}
            >
              Mot de passe oublié ?
            </Button>
          </Box>
        )}
        {isForgotPassword && (
          <Box component="form" onSubmit={handleForgotPasswordRequest} sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="forgotPasswordUsername"
              label="Nom d'utilisateur ou Email"
              name="forgotPasswordUsername"
              autoFocus
              value={forgotPasswordUsername}
              onChange={(e) => setForgotPasswordUsername(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Demander la réinitialisation du mot de passe
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

const SignUp = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [isCodeNeeded, setIsCodeNeeded] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas');
      return;
    }
    try {
      await signUp({
        username,
        password,
        options: {
          userAttributes: {
            email,
          },
          autoSignIn: true
        }
      });
      setError('');
      ReactGA.event("account_created", {
        label: window.location.pathname,
        category: `account`
      });
      setIsCodeNeeded(true);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignUpConfirmation = async (event) => {
    event.preventDefault();
    try {
      await confirmSignUp({
        username,
        confirmationCode
      });
      navigate(-1);
    } catch (error) {
      setErrorCode("Le code n'est pas valide");
    }
  };


  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 8
        }}
      >
        <Typography component="h1" variant="h5">
          Créer un compte
        </Typography>
        {isCodeNeeded ? (
          <Box component="form" onSubmit={handleSignUpConfirmation} sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="verificationCode"
              label="Code de vérification"
              name="verificationCode"
              autoFocus
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
            {errorCode && (
              <Typography color="errorCode" variant="body2">
                {errorCode}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Valider
            </Button>
          </Box>
        ) : (
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Nom d'utilisateur"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirmez le mot de passe"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="email"
              label="Email"
              type="email"
              id="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Créer un compte
            </Button>
          </Box>)}
      </Box>
    </Container>
  );
};

const Auth = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <Container component="main" maxWidth="md">
      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="Se connecter" />
        <Tab label="Créer un compte" />
      </Tabs>
      <Typography textAlign="center" component="h1" variant="h6">
        Il est nécessaire d'avoir un compte pour accéder à certaines fonctionalités.
      </Typography>
      {tabIndex === 0 && <Login />}
      {tabIndex === 1 && <SignUp />}
    </Container>
  );
};

export default Auth;
