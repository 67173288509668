import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  Rating,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  TextField
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SharedExerciseService from "../sharedExercise/SharedExerciseService";
import { useSelector, shallowEqual } from "react-redux";
import JSONField from "../../components/JSONField";

function AdminSharedExerciseList() {
  const navigate = useNavigate();
  const [sharedExerciseList, setSharedExerciseList] = useState([]);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [reports, setReports] = useState([]);
  const [editExercise, setEditExercise] = useState(null);

  const activeProfile = useSelector(
    (state) => state?.userInfos?.activeProfile,
    shallowEqual
  );

  useEffect(() => {
    if (activeProfile) {
      const fetchExercises = async () => {
        try {
          const response = await SharedExerciseService.getList({
            exerciseName: '',
            gradeLevel: '',
            schoolSubject: '',
            profileId: '',
            exerciseType: '',
            stars: 0,
            isAccepted: false
          });
          const data = await response.json();
          setSharedExerciseList(data);
        } catch (error) {
          console.error('Failed to fetch exercises:', error);
        }
      };
      fetchExercises();
    }
  }, [activeProfile]);

  const handleCheckExercise = (sharedExercise) => {
    const path = `/${sharedExercise.exerciseType}`;
    navigate(path, { state: { exercise: sharedExercise.exerciseJson } });
  };

  const handleCheckReports = async (exerciseId) => {
    try {
      const response = await SharedExerciseService.listReports(exerciseId);
      const data = await response.json();
      setReports(data);
      setOpenReportDialog(true);
    } catch (error) {
      console.error('Failed to fetch reports:', error);
    }
  };

  const handleCloseDialog = () => {
    setOpenReportDialog(false);
    setReports([]);
  };

  const handleEditExercise = (exercise) => {
    setEditExercise(exercise);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setEditExercise(null);
  };

  const handleSaveEditExercise = async () => {
    try {
      const response = await SharedExerciseService.updateExercise(editExercise.exerciseId, editExercise);
      if (response.ok) {
        setSharedExerciseList((prevList) =>
          prevList.map((ex) => (ex.exerciseId === editExercise.exerciseId ? editExercise : ex))
        );
        handleCloseEditDialog();
      } else {
        console.error('Failed to update exercise:', await response.text());
      }
    } catch (error) {
      console.error('Failed to update exercise:', error);
    }
  };

  const handleAcceptExercise = async () => {
    try {
      await SharedExerciseService.reviewExercise(editExercise.exerciseId, true);
      setSharedExerciseList((prevList) =>
        prevList.map((ex) => (ex.exerciseId === editExercise.exerciseId ? { ...ex, isAccepted: true } : ex))
      );
      handleCloseEditDialog();
    } catch (error) {
      console.error('Failed to accept exercise:', error);
    }
  };

  const handleRejectExercise = async () => {
    try {
      await SharedExerciseService.reviewExercise(editExercise.exerciseId, false);
      setSharedExerciseList((prevList) =>
        prevList.filter((ex) => ex.exerciseId !== editExercise.exerciseId)
      );
      handleCloseEditDialog();
    } catch (error) {
      console.error('Failed to reject exercise:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditExercise((prevExercise) => ({
      ...prevExercise,
      [name]: value,
    }));
  };

  const handleJsonBlur = (jsonInput) => {
    setEditExercise((prevExercise) => ({
      ...prevExercise,
      exerciseJson: JSON.parse(jsonInput),
    }));
  };


  return (
    <Grid item xs={12} md={6}>
      <Box sx={{ p: 1, border: '1px solid grey', borderRadius: '4px', maxHeight: { xs: 'none', md: '60vh' }, overflowY: 'auto' }}>
        <Typography variant="h6">Liste des exercices</Typography>
        {sharedExerciseList.map(exercise => (

          <Card variant="outlined" key={exercise.exerciseId} sx={{ mb: 1, boxShadow: "1px 1px 2px 2px rgba(0, 0, 0, .9)" }}>
            <CardContent>
              <Typography variant="h7" sx={{ width: '100%' }}>{exercise.exerciseName} - {exercise.gradeLevel}</Typography>

              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'left', p: 1 }}>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body2"> {exercise.exerciseType} : {exercise.schoolSubject}</Typography>
                  {exercise.averageRating !== null && exercise.averageRating !== 0 && exercise.averageRating !== undefined && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Rating value={exercise.averageRating} readOnly precision={0.5}></Rating>
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        ({exercise.ratingCount})
                      </Typography>
                    </Box>)}
                </Box>
                <CardActions sx={{ mt: { xs: 2, md: 0 } }}>
                  <Button variant="contained" color="secondary" onClick={() => handleCheckReports(exercise.exerciseId)}>Signalements</Button>
                  <Button variant="contained" color="primary" onClick={() => handleEditExercise(exercise)}>Editer</Button>
                  <Button variant="contained" color="success" onClick={() => handleCheckExercise(exercise)}>Tester</Button>
                </CardActions>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Dialog open={openReportDialog} onClose={handleCloseDialog}>
        <DialogTitle>Liste des signalements</DialogTitle>
        <DialogContent>
          <List>
            {reports.map((report, index) => (
              <ListItem key={index}>
                <ListItemText primary={`${report.profileId} : ${report.reason}`} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Fermer</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Editer l'exercice</DialogTitle>
        <DialogContent>
          <TextField
            label="Nom de l'exercice"
            name="exerciseName"
            value={editExercise?.exerciseName || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Niveau scolaire"
            name="gradeLevel"
            value={editExercise?.gradeLevel || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Sujet scolaire"
            name="schoolSubject"
            value={editExercise?.schoolSubject || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Type d'exercice"
            name="exerciseType"
            value={editExercise?.exerciseType || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <JSONField templateJson={editExercise?.exerciseJson || {}} onBlur={handleJsonBlur} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} variant="contained" color="secondary">Annuler</Button>
          <Button onClick={handleSaveEditExercise} variant="contained" color="primary">Enregistrer</Button>
          <Button onClick={handleAcceptExercise} variant="contained" color="success">Accepter</Button>
          <Button onClick={handleRejectExercise} variant="contained" color="error">Supprimer</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default AdminSharedExerciseList;
