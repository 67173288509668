import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';

const PrivateRoute = ({ redirectPath = '/login' }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const session = await fetchAuthSession();
        if (session && session.userSub) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  if (isAuthenticated === null) {
    // You can return a loading spinner or null while the authentication check is in progress
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
