import React, { useState, useEffect } from "react";
import { Card, Button, Typography, Grid, Box, Stack, Dialog, DialogContent, DialogActions } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import QuestData from "./QuestData";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"; // Importer l'icône de validation
import topLeft from "../../img/topLeft.png";
import topCenter from "../../img/topCenter.png";
import topRight from "../../img/topRight.png";
import centerLeft from "../../img/centerLeft.png";
import center from "../../img/center.png";
import centerRight from "../../img/centerRight.png";
import bottomLeft from "../../img/bottomLeft.png";
import bottomCenter from "../../img/bottomCenter.png";
import bottomRight from "../../img/bottomRight.png";
import { useNavigate } from "react-router-dom";

const QuestList = ({ activeProfile, handleWeelkyRewardQuest }) => {
  const theme = useTheme();
  const weeklyQuests = activeProfile?.weeklyQuests || [];
  const quests = QuestData;
  const cooldownDuration = 72 * 3600; // Cooldown en secondes (72 heures)
  const [open, setOpen] = useState(false);
  const [selectedQuest, setSelectedQuest] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Mes quêtes';
  }, []);

  const handleOpen = (quest) => {
    setSelectedQuest(quest);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const calculateInitialTimer = (questId) => {
    const quest = weeklyQuests.find((q) => q && q.id === questId);
    if (quest) {
      const lastTimeDone = new Date(quest.date).getTime();
      const now = Date.now();
      const timePassed = Math.floor((now - lastTimeDone) / 1000); // Convertir en secondes
      return {
        timer:
          timePassed >= cooldownDuration ? 0 : cooldownDuration - timePassed,
        status:
          timePassed >= cooldownDuration && quest.status === "done"
            ? ""
            : quest.status,
      };
    }
    return { timer: 0, status: "" };
  };

  const [questInformations, setQuestInformations] = useState({});

  useEffect(() => {
    if (activeProfile) {
      const initialTimers = {};
      quests.forEach((quest) => {
        initialTimers[quest.id] = calculateInitialTimer(quest.id);
      });
      setQuestInformations(initialTimers);
    }
    // eslint-disable-next-line
  }, [activeProfile]);

  useEffect(() => {
    const interval = setInterval(() => {
      setQuestInformations((currentInformations) => {
        const updatedInformation = {};
        for (const id in currentInformations) {
          updatedInformation[id] = {
            timer:
              currentInformations[id].timer > 0
                ? currentInformations[id].timer - 1
                : 0,
            status: questInformations[id].status,
          };
        }
        return updatedInformation;
      });
    }, 60 * 1000); // Mise à jour toutes les secondes
    return () => clearInterval(interval);
  }, [questInformations]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    return `${hours} heure(s)`;
  };

  const BackgroundSegments = styled("div")({
    display: "grid",
    gridTemplateColumns: "34px auto 34px",
    gridTemplateRows: "34px auto 34px",
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  });

  const BackgroundSegment = styled("div")(({ imageUrl }) => ({
    backgroundImage: imageUrl,
    backgroundSize: "contain",
    backgroundRepeat: "repeat",
  }));

  const backgroundImageUrls = {
    segmentOne: "url(" + topLeft + ")",
    segmentTwo: "url(" + topCenter + ")",
    segmentThree: "url(" + topRight + ")",
    segmentFour: "url(" + centerLeft + ")",
    segmentFive: "url(" + center + ")",
    segmentSix: "url(" + centerRight + ")",
    segmentSeven: "url(" + bottomLeft + ")",
    segmentEight: "url(" + bottomCenter + ")",
    segmentNine: "url(" + bottomRight + ")",
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h4" color={theme.palette.primary.main} sx={{ fontFamily: "Softers" }} textAlign={"center"}>
        {`Mes objectifs`}
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {quests.map((quest) => (
          <Grid item key={quest.id}>
            <Card
              variant="outlined"
              sx={{
                textAlign: "center",
                justifyContent: "center",
                padding: 2,
                position: "relative",
                height: "300px",
                width: "273px",
                bgcolor: "rgba(0,0,0, 1)",
                color: "#FFFFFF",
                borderRadius: 4,
              }}
              className="card-quest"
            >
              <BackgroundSegments>
                <BackgroundSegment imageUrl={backgroundImageUrls.segmentOne} />
                <BackgroundSegment
                  imageUrl={backgroundImageUrls.segmentTwo}
                  style={{ gridColumn: "2", gridRow: "1" }}
                />
                <BackgroundSegment
                  imageUrl={backgroundImageUrls.segmentThree}
                  style={{ gridColumn: "3", gridRow: "1" }}
                />
                <BackgroundSegment
                  imageUrl={backgroundImageUrls.segmentFour}
                  style={{ gridColumn: "1", gridRow: "2" }}
                />
                <BackgroundSegment
                  imageUrl={backgroundImageUrls.segmentFive}
                  style={{ gridColumn: "2", gridRow: "2" }}
                />
                <BackgroundSegment
                  imageUrl={backgroundImageUrls.segmentSix}
                  style={{ gridColumn: "3", gridRow: "2" }}
                />
                <BackgroundSegment
                  imageUrl={backgroundImageUrls.segmentSeven}
                  style={{ gridColumn: "1", gridRow: "3" }}
                />
                <BackgroundSegment
                  imageUrl={backgroundImageUrls.segmentEight}
                  style={{ gridColumn: "2", gridRow: "3" }}
                />
                <BackgroundSegment
                  imageUrl={backgroundImageUrls.segmentNine}
                  style={{ gridColumn: "3", gridRow: "3" }}
                />
              </BackgroundSegments>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  position: "relative",
                  padding: 2,
                }}
              >
                <Typography variant="h5" component="div">
                  {quest.title}
                </Typography>
                {questInformations[quest.id]?.status === "complete" && (
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1.5 }}
                    onClick={() => handleWeelkyRewardQuest(quest.id)}
                  >
                    Récupérer une carte
                  </Button>
                )}
                {questInformations[quest.id]?.status !== "complete" &&
                  (questInformations[quest.id]?.status !== "done" ||
                    formatTime(questInformations[quest.id]?.timer === 0)) && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 1.5 }}
                      onClick={() => handleOpen(quest)}
                    >
                      Disponible
                    </Button>
                  )}
              </Box>
              {questInformations[quest.id]?.status === "done" && formatTime(questInformations[quest.id]?.timer !== 0) && (
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: 0,
                    backgroundColor: "rgba(0,0,0,0.9)",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CheckCircleOutlineIcon sx={{ color: "white", fontSize: 60 }} />{" "}
                  {/* Icône de validation */}
                  <Typography variant="h5">
                    Disponible dans:{" "}
                    {formatTime(questInformations[quest.id]?.timer)}
                  </Typography>
                </Box>
              )}
            </Card>
            {selectedQuest && (<Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <DialogContent>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Informations sur la quête
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {selectedQuest.tooltip}
                </Typography>
              </DialogContent>
              {selectedQuest.route && (
                <DialogActions>
                  <Button variant="contained" onClick={()=> navigate(selectedQuest.route)}>
                    J'y vais
                  </Button>
                </DialogActions>
              )}
            </Dialog>)
            }
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default QuestList;
