import React, { useState, useEffect } from "react";
import {
  Stack,
  TextField,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import paramsExercise from "../data/paramsExercise";
import APIService from "../ApiService.js";
import JSONField from "./JSONField.js";

const iaEngineList = [
  "claude",
  "claude-sonnet",
  "claude-opus",
  "mistral",
  "gemini",
  "chatGpt",
  "chatGpt4",
];

const BuilderExercice = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const [jsonValueForInput, setJsonValueForInput] = useState({});
  const [jsonValueForInputAtEnd, setJsonValueForInputAtEnd] = useState({});

  let selectedTemplate = null;
  if (state && state.template) {
    selectedTemplate = state.template;
  }

  useEffect(() => {
    if (selectedTemplate) {
      setJsonValueForInput(selectedTemplate.templateJson);
      setJsonValueForInputAtEnd(selectedTemplate.templateJsonForValidation);
    }
  }, [selectedTemplate]);

  const [templateDescription, setTemplateDescription] = useState(
    selectedTemplate ? selectedTemplate.templateDescription : ""
  );

  const [templateDescriptionAtEnd, setTemplateDescriptionAtEnd] = useState(
    selectedTemplate ? selectedTemplate.templateDescriptionAtEnd : ""
  );

  const [templateName, setTemplateName] = useState(
    selectedTemplate ? selectedTemplate.templateName : ""
  );
  const [templateTitle, setTemplateTitle] = useState(
    selectedTemplate ? selectedTemplate.templateTitle : ""
  );
  const [hideTemplate, setHideTemplate] = useState(
    selectedTemplate ? selectedTemplate.hideTemplate : false
  );

  const [iaEngine, setIaEngine] = useState(
    selectedTemplate?.iaEngine || "claude"
  );

  const [hookAiAtStart, setHookAiAtStart] = useState(
    !!selectedTemplate?.hookAiAtStart
  );
  const [hookAiAtEnd, setHookAiAtEnd] = useState(
    !!selectedTemplate?.hookAiAtEnd
  );

  const [paramsSelected, setParamsSelected] = useState(
    selectedTemplate ? selectedTemplate.templateParams || [] : []
  );
  const [paramsState, setParamsState] = useState({});
  useEffect(() => {
    if (selectedTemplate) {
      // Créer un état initial à partir de selectedTemplate
      const initialState = {};
      if (selectedTemplate && selectedTemplate.templateParams) {
        selectedTemplate.templateParams.forEach((id) => {
          initialState[id] = ""; // Valeur initiale pour chaque paramètre
        });
      }
      setParamsState(initialState);
    }
  }, [selectedTemplate]);

  const setParamValue = (paramId, value) => {
    setParamsState((prevState) => ({
      ...prevState,
      [paramId]: value,
    }));
  };
  const handleChangeParams = (event) => {
    const {
      target: { value },
    } = event;
    setParamsSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [jsonOutput, setJsonOutput] = useState("");

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function JsonOutput() {
    return (
      <TextField
        label="Exemple d'exercice"
        multiline
        rows={15}
        value={jsonOutput}
        variant="outlined"
        fullWidth
      />
    );
  }

  const createTemplate = function () {
    const template = {
      templateDescription: templateDescription,
      templateDescriptionAtEnd: templateDescriptionAtEnd,
      templateName: templateName,
      templateTitle: templateTitle,
      templateParams: paramsSelected,
      hideTemplate: hideTemplate,
      hookAiAtStart,
      hookAiAtEnd,
      iaEngine,
      templateJson: jsonValueForInput ? jsonValueForInput : null,
      templateJsonForValidation: jsonValueForInputAtEnd ? jsonValueForInputAtEnd : null,
    };
    const callApiSaveTemplate = async () => {
      await fetch(
        "https://roin2cakj2.execute-api.eu-west-3.amazonaws.com/backoffice/saveTemplate",
        {
          method: "POST",
          body: JSON.stringify(template),
        }
      );
      navigate(-1);
    };
    callApiSaveTemplate();
  };

  const testTemplate = function () {
    let prompt = templateDescription;
    paramsSelected.forEach((id) => {
      prompt = prompt.replace(`{${id}}`, paramsState[id]);
    });
    //Specific replace if the AI is call to validate a result
    prompt = prompt.replace(
      `{userResponseJson}`,
      paramsState["userResponseJson"]
    );

    const template = {
      prompt: prompt,
      json: jsonValueForInput,
      iaEngine,
    };

    const callApiTest = async () => {
      const response = await APIService.fetchWithAuth("/ai/ask", {
        method: "POST",
        body: JSON.stringify(template),
      });
      const format = await response.json();
      // Formatage du JSON pour une meilleure lisibilité
      const formattedJson = JSON.stringify(format, null, 2);
      setJsonOutput(formattedJson);
    };
    callApiTest();
  };

  return (
    <React.Fragment>
      <form>
        <Stack spacing={4}>
          <h2>
            {selectedTemplate
              ? "Mettre à jour un template d'exercice"
              : "Créer un nouveau type d'exercice"}
          </h2>
          <TextField
            id="outlined-basic"
            onChange={(e) => setTemplateName(e.target.value)}
            label="ID du template"
            variant="outlined"
            defaultValue={selectedTemplate ? selectedTemplate.templateName : ""}
          />
          <TextField
            id="outlined-basic"
            onChange={(e) => setTemplateTitle(e.target.value)}
            label="Nom affiché pour le template"
            defaultValue={
              selectedTemplate ? selectedTemplate.templateTitle || "" : ""
            }
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={!hideTemplate}
                  onChange={(e) => setHideTemplate(!e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Activer"
            />
          </FormGroup>
          <FormControl fullWidth margin="normal">
            <InputLabel id="ia-selector-label">Choix de l'IA</InputLabel>
            <Select
              labelId="ia-selector-label"
              id="ia-selector"
              label="Choix de l'IA"
              value={iaEngine}
              onChange={(e) => setIaEngine(e.target.value)}
            >
              {iaEngineList.map((engineName) => (
                <MenuItem key={`key-ai-${engineName}`} value={engineName}>
                  {engineName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="multiple-params-selector-label">
              Les paramètres
            </InputLabel>
            <Select
              labelId="multiple-params-selector-label"
              id="multiple-params-selector"
              multiple
              value={paramsSelected}
              onChange={handleChangeParams}
              input={<OutlinedInput label="Les paramètres" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {paramsExercise.map((param) => (
                <MenuItem key={param.id} value={param.id}>
                  <Checkbox checked={paramsSelected.indexOf(param.id) > -1} />
                  <ListItemText primary={param.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={hookAiAtStart}
                  onChange={(e) => setHookAiAtStart(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="IA au début de l'exercice"
            />
          </FormGroup>

          {hookAiAtStart && (
            <TextField
              id="outlined-basic"
              onChange={(e) => setTemplateDescription(e.target.value)}
              label="Le texte décrivant ce qu'on demande à l'IA"
              defaultValue={
                selectedTemplate ? selectedTemplate.templateDescription : ""
              }
            />
          )}

          {hookAiAtStart && (
            <JSONField
              templateJson={selectedTemplate ? selectedTemplate.templateJson : jsonValueForInput}
              onBlur={(value)=>{
                try{
                  setJsonValueForInput(JSON.parse(value))
                }catch(e){
                  setJsonValueForInput(JSON.parse(value))
                }
              }}
                
            />
          )}

          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={hookAiAtEnd}
                  onChange={(e) => setHookAiAtEnd(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="IA à la fin de l'exercice"
            />
          </FormGroup>

          {hookAiAtEnd && (
            <TextField
              id="outlined-basic"
              onChange={(e) => setTemplateDescriptionAtEnd(e.target.value)}
              label="Le texte décrivant ce qu'on demande à l'IA"
              defaultValue={
                selectedTemplate
                  ? selectedTemplate.templateDescriptionAtEnd
                  : ""
              }
            />
          )}
          {hookAiAtEnd && (<JSONField
            templateJson={selectedTemplate ? selectedTemplate.templateJsonForValidation : setJsonValueForInputAtEnd}
            onBlur={(value)=>{
              try{
                setJsonValueForInputAtEnd(JSON.parse(value))
              }catch(e){
                setJsonValueForInputAtEnd(JSON.parse(value))
              }
            }}
          />)}

          <Divider flexItem size="big" />

          {(hookAiAtStart || hookAiAtEnd) && (<Stack spacing={2}><Stack direction="row" alignItems={"center"} spacing={15}>
            <Stack spacing={6} style={{ width: "50%" }}>
              {paramsSelected.map((id) => (
                <TextField
                  id="outlined-basic"
                  key={`param-${id}`}
                  label={`({ ${id} }) de l'enfant pour le test`}
                  variant="outlined"
                  onChange={(e) => setParamValue(id, e.target.value)}
                />
              ))}
              {hookAiAtEnd && (
                <TextField
                  id="outlined-basic"
                  key={`param-userResponseJson`}
                  label={`({ userResponseJson }) de l'enfant pour le test`}
                  variant="outlined"
                  onChange={(e) =>
                    setParamValue("userResponseJson", e.target.value)
                  }
                />
              )}
            </Stack>
            <Stack spacing={6} alignItems={"center"}>
              <Button variant="contained" onClick={testTemplate}>
                Tester
              </Button>
            </Stack>
            
          </Stack>
          <JsonOutput />
          </Stack>
          )}
          <Stack spacing={6} alignItems={"center"}>
            <Button
              variant="contained"
              onClick={createTemplate}
              color="success"
            >
              Valider
            </Button>
          </Stack>
        </Stack>
      </form>
    </React.Fragment>
  );
};

export default BuilderExercice;
