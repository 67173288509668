import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import { Link, useNavigate } from "react-router-dom";
import { signOut } from "@aws-amplify/auth";
import { useSelector, shallowEqual } from "react-redux";

import MenuBg from "../img/menu.jpg";

import logoImg from "../img/exercilio.png";

const appMenuListItem = [
  {
    key: "homePage",
    label: "Accueil",
    route: "/",
  },
  {
    key: "createPage",
    label: "Créer",
    route: "/create",
  },
  
  /*{
    key: "findPage",
    label: "Découvrir",
    route: "/find",
  },*/
  {
    key: "friendListPage",
    label: "Mes amis",
    route: "/friends/list",
  },
  /*{
    key: "challengePage",
    label: "Défi",
    route: "/challenge",
  },*/
  {
    key: "albumPage",
    label: "Mes cartes",
    route: "/album",
  },
  {
    key: "questListPage",
    label: "Mes quêtes",
    route: "/quest/list",
  },
  {
    key: "ladderPage",
    label: "Classement",
    route: "/ladder",
  },
];


const profileMenuListItem = [
  {
    key: "profileListPage",
    label: "Choisir mon profil",
    route: "/profile/list",
  },
  {
    key: "ExerciseListAdminPage",
    label: "Admin: Exercices signalés",
    route: "/admin/shared/list",
    isAdmin: true
  },
  {
    key: "lessonValidatorAdminPage",
    label: "Admin: Editeur des leçons",
    route: "/admin/structure",
    isAdmin: true
  },
  {
    key: "templateListAdminPage",
    label: "Admin: Template management",
    route: "/admin/exerciceTemplateList",
    isAdmin: true
  },
];

export default function MenuAppBar({activeProfile}) {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const isAdmin = useSelector((state) => {
    return state?.userInfos?.group === "admin";
  }, shallowEqual);


  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/");
      // Gérer la déconnexion ici (par exemple, redirection ou mise à jour de l'état)
    } catch (error) {
      console.log("Erreur lors de la déconnexion:", error);
    }
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundImage: `url(${MenuBg})`,
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{ backgroundColor: "black", minWidth: "100%" }}
      >
        <Toolbar disableGutters>
          <IconButton
            component={Link}
            to="/"
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
          >
            <Avatar alt="Exercilio Logo" src={`${logoImg}`} />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "#FFFFFF",
              textDecoration: "none",
            }}
          >
            EXERCILIO
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {appMenuListItem &&
                appMenuListItem.map((appMenuItem) => (
                  <MenuItem
                    key={appMenuItem.key}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={appMenuItem.route}
                  >
                    <Typography textAlign="center">
                      {appMenuItem.label}
                    </Typography>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
          <IconButton
            component={Link}
            to="/"
            sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
          >
            <Avatar alt="Exercilio Logo" src={`${logoImg}`} />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              color: "#FFFFFF",
              textDecoration: "none",
            }}
          >
            EXERCILIO
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {appMenuListItem &&
                appMenuListItem.map((appMenuItem) => (
                  <Button
                  key={appMenuItem.key}
                  component={Link}
                  to={appMenuItem.route}
                  sx={{ my: 2, minWidth: "auto", color: "white", display: "block" }}
                >
                  {appMenuItem.label}
                </Button>
                ))}
          </Box>

          {activeProfile && (<Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <Stack direction={"row"} sx={{alignItems:"center"}} spacing={2}><Typography sx={{color: "#FFFFFF"}}>{activeProfile?.pseudo || ""}</Typography>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Exercilio Logo" src={`${activeProfile?.image}`} />
              </IconButton>
              </Stack>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {
                profileMenuListItem && profileMenuListItem
                .filter((profileMenuItem)=>(!profileMenuItem.isAdmin || (profileMenuItem.isAdmin && isAdmin)))
                .map(profileMenuItem=>(<MenuItem
                  onClick={handleCloseUserMenu}
                  component={Link}
                  key={profileMenuItem.key}
                  to={profileMenuItem.route}
                >
                  {profileMenuItem.label}
                </MenuItem>))
              }
  
              <MenuItem onClick={handleSignOut}>Déconnexion</MenuItem>
            </Menu>
          </Box>)}

          {!activeProfile && (<Box >
            <Tooltip title="connection">
              <ManageAccountsIcon onClick={()=> {handleCloseUserMenu(); navigate("/login")}} />
            </Tooltip>
            </Box>)
          }


        </Toolbar>
      </Container>
    </AppBar>
  );
}
