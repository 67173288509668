import React, { useEffect, useState } from 'react';
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

import StructureAdmin from './components/StructureAdmin';
import BuilderExercice from './components/BuilderExerciceAdmin';
import ExerciseTemplateListAdmin from './components/ExerciseTemplateListAdmin';



import MenuAppBar from './components/MenuAppBar';
import Home from './components/Home';
import ExerciceGrid from './components/ExerciceGrid';
import Quiz from './components/exercises/Quiz';
import Calculator from './components/Calculator';
import Problem from './components/exercises/Problem';
import StoryQuiz from './components/exercises/StoryQuiz';
import StoryCard from './components/exercises/StoryCard';

//Profile feature
import ProfileList from './features/profile/ProfileList';
import ProfileEditor from './features/profile/ProfileEditor';

//Friend feature
import FriendList from './features/friend/FriendList';


//Quest feature
import QuestList from './features/quest/QuestList';
import Album from './features/collections/Album';
import AlbumList from './features/collections/AlbumList';
import MascotModal from './features/quest/MascotModal';

//Sharing feature
import AdminSharedExerciseList from './features/admin/AdminSharedExerciseList';
import Ladder from './features/ladder/Ladder';

import DialogCreatorComponent from './components/DialogCreatorComponent';

import '@aws-amplify/ui-react/styles.css';
import { disconnect, getUserInfos } from './features/authentication/userInfos';
import { useDispatch } from 'react-redux';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { useSelector, shallowEqual } from 'react-redux';

import ReactGA from 'react-ga4';
import VerbConjugation from './components/exercises/VerbConjugation';
import { SocialIcon } from 'react-social-icons'
import MotusGame from './components/exercises/MotusGame';
import WordMatch from './components/exercises/WordMatch';
import BacGame from './components/exercises/BacGame';
import Dictee from './components/exercises/Dictee';
import { Paper } from '@mui/material';
import CalculPose from './components/exercises/Calcul';
import MultiplicationTable from './components/exercises/MultiplicationTable';
import HomophoneExercises from './components/exercises/HomophoneExercises';
import Login from './components/Login';
import AdminRoute from './AdminRoute';
import ExerciseRoute from './ExerciseRoute';

ReactGA.initialize('G-MW34H1XLGM');

I18n.putVocabularies(translations);
I18n.setLanguage('fr');

I18n.putVocabularies({
  fr: {
    "Reset Password": "Réinitialisation du mot de passe",
    "username is required to signIn": "Un adresse email est nécessaire pour se connecter"
  }
});


function Copyright(props) {
  return (
    <Typography variant="body2" style={{ paddingTop: "10px" }} color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="">
        Exercilio
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}




// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  typography: {
    fontFamily: 'Josefin Sans, sans-serif',
    fontWeight: '700'
  },
  palette: {
    primary: {
      main: "#304CBF",
      contrastText: '#fff',
    },
    background: {
      default: "#f3f3f3"
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: "20vh",
          minHeight: "20vh"
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          minWidth: "90%"
        }
      }
    }
  },
});


export default function App() {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const myAccount = useSelector((state) => state?.userInfos);
  const activeProfile = useSelector((state) => state?.userInfos?.activeProfile, shallowEqual);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticatedReady, setIsAuthenticatedReady] = useState(false);

  useEffect(() => {
    if(isAuthenticated && myAccount.loading==="done"){
      setIsAuthenticatedReady(true);
    }else{
      setIsAuthenticatedReady(false);
    }
  },[isAuthenticated, myAccount]);


  const lastQuest = useSelector((state) => {
    let lastQuest = state?.userInfos?.activeProfile?.lastQuest;
    const currentProfile = state?.userInfos?.activeProfile;

    if (!lastQuest && currentProfile) {
      lastQuest = {
        status: "completed",
        questId: "tip-welcome",
      }
    }
    return lastQuest;
  }, shallowEqual);
  
  const dispatch = useDispatch();

  async function fetchSession() {
    try {
      const session = await fetchAuthSession();
      if (session && session.userSub) {
        setIsAuthenticated(true);
        dispatch(getUserInfos(session));
      } else {
        setIsAuthenticated(false);
      }
    } catch (e) { }
  }

  const [weeklyQuestRewared, setWeeklyQuestRewared] = useState(null);
  const handleWeelkyRewardQuest = (questId) => {
    setWeeklyQuestRewared(questId);
  }

  useEffect(() => {
    fetchSession();
    const listener = (data) => {
      switch (data.payload.event) {
        case 'signedIn':
          fetchSession();
          break;
        case 'signedOut':
          setIsAuthenticated(false);
          setIsAuthenticatedReady(false);
          dispatch(disconnect());
          break;
        default:
          break;
      }
    };
    Hub.listen('auth', listener);
  }, []);

  return (
    <Router>
      <ThemeProvider theme={defaultTheme}>
        <Box>
          <CssBaseline />
          <MenuAppBar activeProfile={activeProfile} />
          <Box
            component="main"
            sx={{
              backgroundImage: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              overflow: 'auto',
              minHeight: "60vh"
            }}
          >
            <Container maxWidth="sm" sx={{
              paddingTop: "5vh", paddingBottom: "5vh", height: "100%", display: "flex",
              flexDirection: "column"
            }} >
              <div>
                <MascotModal
                  activeProfile={activeProfile}
                  lastQuest={lastQuest}
                  weeklyQuestRewared={weeklyQuestRewared}
                  isAuthenticated = {isAuthenticatedReady}
                />
              </div>
              <Routes>
                <Route path="/index.html" element={<Home />} />
                <Route path="/" element={<Home activeProfile={activeProfile} />} />

                <Route path="/qcm" element={<Quiz />} />
                <Route path="/calcul" element={<Calculator />} />
                <Route path="/probleme" element={<Problem />} />
                <Route path="/story" element={<StoryQuiz />} />
                <Route path="/sing" element={<StoryCard />} />
                <Route path="/explanation" element={<StoryCard />} />
                <Route path="/verbConjugation" element={<VerbConjugation />} />
                <Route path="/findWord" element={<MotusGame />} />
                <Route path="/wordMatch" element={<WordMatch />} />
                <Route path="/scattergories" element={<BacGame />} />
                <Route path="/dictee" element={<Dictee />} />
                <Route path="/calculationSheet" element={<CalculPose />} />
                <Route path="/multiplicationTable" element={<MultiplicationTable />} />
                <Route path="/homophoneExercise" element={<HomophoneExercises />} />
                <Route path="/exercise/:exerciseId" element={<ExerciseRoute />} />

                <Route path="/login" element={<Login />} />


                <Route element={<PrivateRoute />}>
                  <Route path="/profile/list" element={<ProfileList />} />
                  <Route path="/profile/edit" element={<ProfileEditor />} />

                  <Route path="/create" element={<ExerciceGrid />} />
                  <Route path="/create/:templateId" element={<DialogCreatorComponent />} />

                  <Route path="/friends/list" element={<FriendList />} />

                  <Route path="/album" element={<AlbumList />} />
                  <Route path="/album/:id" element={<Album />} />

                  <Route path="/quest/list" element={<QuestList activeProfile={activeProfile} handleWeelkyRewardQuest={handleWeelkyRewardQuest} />} />
                  <Route path="/ladder" element={<Ladder />} />

                </Route>


                <Route element={<AdminRoute />}>
                  <Route path="/admin/shared/list" element={<AdminSharedExerciseList />} />
                  <Route path="/admin/structure" element={<StructureAdmin />} />
                  <Route path="/admin/exercices" element={<BuilderExercice />} />
                  <Route path="/admin/exerciceTemplateList" element={<ExerciseTemplateListAdmin />} />

                </Route>

              </Routes>
            </Container>
          </Box>
          <Paper sx={{
            width: '100%',
            bottom: 0,
            background: "#000000",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }} component="footer" square variant="outlined">
            <Container sx={{ textAlign: "center", minHeight: "10vh" }} maxWidth="lg">
              <Box textAlign="center" sx={{ pt: 6, color: "white" }}>
                Actuellement en Beta ouverte. Pour les questions et bugs, utilisez le Discord suivant:
              </Box>
              <SocialIcon url="https://discord.gg/czGrHFQVwj" />
              <Copyright sx={{ pb: 6, color: "white" }} />
            </Container>
          </Paper>
        </Box>
      </ThemeProvider>
    </Router>
  );
}