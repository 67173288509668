const QuestData = [
  /*{ id: "weekly-challenge", title: "Faire mon défi de multiplication", cooldown: 72 * 3600, tooltip:"Pour faire ton défi, tu dois aller à la page d'accueil, cliquer sur 'DEFI', et réussir un challenge de multiplication"
 }, */
  {
    id: "weekly-exercise-success-Mathématiques",
    title: "Exercice de mathématiques",
    cooldown: 72 * 3600,
    tooltip: "Réussir un des exercices de Mathématiques de cette liste:",
    route: "/?schoolSubject=Mathématiques"
  },
  {
    id: "weekly-exercise-success-Français",
    title: "Exercice de Français",
    cooldown: 72 * 3600,
    tooltip: "Réussir un des exercices de Français de cette liste:",
    route: "/?schoolSubject=Français"
  },
  {
    id: "weekly-create-qcm",
    title: "Partager un quiz",
    cooldown: 72 * 3600,
    tooltip: "Créer un exercice de type Quiz que tu dois partager si tu le trouves bien",
    route: "/create?type=qcm"
  },
  {
    id: "weekly-create-verbConjugation",
    title: "Partager un exercice de conjugaison",
    cooldown: 72 * 3600,
    tooltip: "Créer un exercice de type conjugaison que tu dois partager si tu le trouves bien.",
    route: "/create?type=verbConjugation"
  },
  {
    id: "weekly-rate",
    title: "Noter un exercice",
    cooldown: 72 * 3600,
    tooltip: "Testez l'un des exercices disponibles sur la page principale et pensez à le noter à la fin.",
    route: "/"
  }
];

export default QuestData;
